import {useLocation} from 'react-router-dom';

/**
 * Parse URL address and get query param by passing params name to it
 * @param queryParam {string} - specify query parameter that looking for
 * @return {string | null} - return parameter result, if query params not in URL, returns null
 */
function useQueryParam(queryParam: string) {
  const location = useLocation();
  return new URLSearchParams(location.search)?.get(queryParam);
}

export default useQueryParam;
