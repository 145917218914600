import {copySVG} from 'assets/svg';
import React, {FC} from 'react';
import Player from 'react-player';
import {getDownloadAddress} from 'utils';

interface PropsTypes {
  url: string;
  filename: string;
}

const MediaWidget: FC<PropsTypes> = ({url, filename}) => {
  const _url = url ? url : filename;
  return (
    <div className="mt-10">
      <Player
        url={getDownloadAddress(_url)}
        controls
        width="100%"
        config={{
          youtube: {
            playerVars: {modestbranding: 1},
          },
        }}
      />
    </div>
  );
};

// return (
//   <div className="flex justify-center items-center text-lg pt-10">
//     Select the Media file
//   </div>
// );

export default MediaWidget;
