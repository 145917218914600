import React from 'react';
import {Switch, Route, Redirect, Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';

import routes from './routes';

interface RoutesProviderProps {
  children?: React.ReactNode;
}
export const history = createBrowserHistory();

const RoutesProvider: React.FC<RoutesProviderProps> = ({children}) => {
  return (
    <Router history={history}>
      <Switch>
        {routes.map((route) => (
          <Route
            path={route.path}
            exact={route.exact}
            component={route.component}
            key={route.path as string}
          />
        ))}
        <Redirect from="/" to="/general" />
      </Switch>
      {children}
    </Router>
  );
};

export default RoutesProvider;
