import React from 'react';
import {ReactComponent as Tick} from 'assets/svg/tick.svg';
interface TodoItemProps {
  handleClick: Function;
  done: boolean | number;
  task: string;
  index: number;
  id: number;
}

const TodoItem: React.FC<TodoItemProps> = ({
  done,
  task,
  handleClick,
  index,
  id,
}) => {
  return (
    <div className="flex my-2  w-full">
      <div className=" flex justify-center items-center  w-2/12">
        <button className=" flex justify-center bg-black border border-black transition-all duration-100 items-center rounded-full w-10 h-10">
          <Tick />
        </button>
      </div>
      <div
        className={`bg-black text-white rounded-lg  transition-all  duration-100 text-lg px-5 py-3  w-10/12`}>
        {task}
      </div>
    </div>
  );
};

export default TodoItem;
