import capitalize from 'lodash/capitalize';
import React, {Fragment} from 'react';

import {Spinner} from 'components';

// import classes from '../preview.module.css';

import Footer from './Footer';
import Progress from './Progress';
import WidgetItem from './WidgetItem';
import HorizontalList from './HorizontalList';
import {getDownloadAddress} from 'utils';

interface Props {
  name?: string;
  mainColor?: string;
  backgroundColor?: string;
  categories?: any;
  categoriesIsLoading?: boolean;
  header?: string;
  widgetType?: any;
  widgetData?: any;
  accentColor?: string;
}

const Preview = ({
  mainColor = 'white',
  backgroundColor = 'white',
  categories = [],
  categoriesIsLoading,
  header,
  name,
  accentColor,
}: Props) => {
  if (categoriesIsLoading) return <Spinner />;
  return (
    <div
      className="w-full animate-fadeRight overflow-y-scroll  flex flex-col "
      style={{height: 700}}>
      <div
        className="w-full justify-center items-center relative"
        style={{
          minHeight: 220,
        }}>
        {header && (
          <img
            src={getDownloadAddress(header)}
            className="w-full h-64  md:h-350 lg:h-384 object-cover absolute  z-0 top-0"
            alt="..."
          />
        )}
        <div
          style={{
            backgroundImage: ` linear-gradient(to bottom, rgba(255,0,0,0), ${backgroundColor})`,
          }}
          className=" h-64   absolute bottom-0 lg:mb-10 xl:mb-4  z-20  w-full "></div>
      </div>
      <div
        style={{backgroundColor, color: mainColor}}
        className="w-full py-2 text-xl text-center z-30   -mt-4    font-bold text-36 ">
        Welkom {capitalize(name)}
      </div>

      <div className="flex flex-col  pb-20">
        {categories?.map(({categoryWidgets, name, id}: any) => {
          return (
            <Fragment key={id}>
              <div className="w-full px-2 flex flex-col items-center">
                <div className="w-full whitespace-no-wrap flex justify-between items-center">
                  <div
                    className="  text-base sm:text-21 md:text-36  font-bold"
                    style={{color: mainColor}}>
                    {name}
                  </div>
                  <div className=" text-12  sm:text-14 md:text-base">
                    {/* <Progress borderColor={mainColor} percet={80} /> */}
                  </div>
                </div>
              </div>
              <div className="w-full  mt-2">
                <HorizontalList
                  className="py-2"
                  data={categoryWidgets}
                  renderItem={(
                    {completed, thumbnail, type, id, title, text},
                    index,
                  ) => {
                    return (
                      // <Link to={`/widget/${id}?footer=false`}>
                      <div className="w-full  p-2">
                        <WidgetItem
                          lastItemColor={index % 2 === 0 ? 'black' : 'white'}
                          tick={false}
                          title={title}
                          text={text}
                          imgUrl={thumbnail}
                          mainColor={mainColor}
                          backgroundColor={backgroundColor}
                        />
                      </div>
                      // </Link>
                    );
                  }}
                />
              </div>
            </Fragment>
          );
        })}
      </div>
      <Footer mainColor={mainColor} accentColor={accentColor} />
    </div>
  );
};

export default Preview;
