import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  page: 0,
};

const TodoWidgetReducer = createSlice({
  name: 'quizWidget',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const {setPage} = TodoWidgetReducer.actions;

export default TodoWidgetReducer.reducer;
