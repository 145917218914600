import React, {useEffect, useState} from 'react';

import {QuizItem, TodoContent} from 'components';

import WidgetWrapper, {QuizWidgetType} from './withWidget';
import {useQuery} from 'react-query';
import gate from 'gate';
import {useParams} from 'react-router-dom';
export type taskType = {
  task: string;
  id?: number;
};
const PdfWidget = () => {
  const {action, id} = useParams<{action: string; id: string}>();
  const [data, setData] = useState<taskType[]>([{task: ''}]);
  return (
    <WidgetWrapper widgetData={data} widgetType={'ToDo'}>
      <div className=" animate-opacity w-full flex flex-col justify-center ">
        <TodoContent data={data} setData={setData} />
      </div>
    </WidgetWrapper>
  );
};
export default PdfWidget;
