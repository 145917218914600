import {Spinner} from 'components';
import React, {useRef} from 'react';

import BaseModal from './BaseModal';

interface CreateWidgetModalProps {
  onClose: VoidFunction;
  onSelected?: (e: string) => void;
  active: boolean;
  categories: {name: string; id: string | number}[];
  isLoading: boolean;
}
const hanldePreventDefault = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e.preventDefault();
  e.stopPropagation();
};
const CreateWidgetModal: React.FC<CreateWidgetModalProps> = ({
  onClose,
  active,
  onSelected,
  categories,
  isLoading,
}) => {
  const selectRef = useRef<HTMLSelectElement>(null);

  const handleOnSubmit = () => {
    if (
      selectRef?.current?.value &&
      selectRef?.current?.value !== 'Select one Category'
    ) {
      onSelected && onSelected(selectRef.current?.value as string);
    }
  };

  return (
    <BaseModal active={active} onClose={onClose}>
      <div
        onClick={hanldePreventDefault}
        className="flex flex-col justify-center items-center text-center
          font-semibold overflow-hidden w-136 bg-white
          rounded-2xl shadow-modal border-1 pt-12 pb-6 px-16">
        {!isLoading && categories ? (
          <>
            <p className="text-lg place-self-start">Create Widget</p>
            <div className="pt-8 w-full pb-16">
              <select ref={selectRef} onChange={() => {}}>
                <option selected disabled>
                  Select one Category
                </option>
                {categories.map((eachCategory) => (
                  <option
                    key={eachCategory.id}
                    value={eachCategory.id as string}>
                    {eachCategory.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-around items-center w-full h-12">
              <button
                type="submit"
                onClick={handleOnSubmit}
                className="text-lg font-semibold p-4 cursor-pointer">
                OK
              </button>
              <button
                onClick={onClose}
                className="text-lg font-semibold p-4 text-red-600 cursor-pointer">
                CANCEL
              </button>
            </div>
          </>
        ) : (
          <div>
            <Spinner />
            Loading...
          </div>
        )}
      </div>
    </BaseModal>
  );
};

export default CreateWidgetModal;
