import React, {useState} from 'react';
import {useMutation, useQueryClient} from 'react-query';
import {useForm, Controller, useFieldArray} from 'react-hook-form';
import * as yup from 'yup';
import {toast} from 'react-toastify';
import classNames from 'classnames';

import Spinner from 'components/spinner';
import {yupResolver} from '@hookform/resolvers/yup';

import {Cropper, Input} from 'components';
import {trashSVG, edit} from 'assets/svg';

import gate from 'gate';
import {getDownloadAddress} from 'utils';
import useImage from 'hooks/useImage';

//TODO refactor

const NOT_FOUND =
  'https://safetyaustraliagroup.com.au/wp-content/uploads/2019/05/image-not-found.png';
export interface AddUserProps {
  colleagues: [];
  companyId: number;
}

interface IFormInputs {
  name?: string;
  lastName?: string;
  email?: string;
  title?: string;
  linkedIn?: string;
  avatar?: string;
}
const schema = yup.object().shape({
  name: yup.string().required(),
  // lastName: yup.string().required(),
  // email: yup.string().required(),
  // // title: yup.string().required(),
  // linkedIn: yup.string().required(),
});
const ColleagueDropDown: React.FC<AddUserProps> = ({
  colleagues = [],
  companyId,
}) => {
  // const {control, handleSubmit, register, errors} = useForm<IFormInputs>({
  // resolver: yupResolver(schema),
  //   defaultValues: {colleagues: colleagues},
  // });

  const {mutate: deleteUser, isLoading} = useMutation(
    async (id) => {
      try {
        await gate.deleteCompanyUserColleague(id);
        toast.success('User Deleted Successfully');
      } catch (e) {
        console.log(e);
      }
    },
    {
      onSuccess: () => {
        // toast.success('User Added Successfully');
      },
    },
  );
  const {control, handleSubmit, setValue, reset, errors} = useForm<IFormInputs>(
    {
      defaultValues: {
        // resolver: yupResolver(schema),
        colleagues: colleagues,
      },
    },
  );

  const {fields} = useFieldArray({
    control,
    name: 'colleagues',
  });

  React.useEffect(() => {
    reset({colleagues: colleagues});
  }, [colleagues]);

  const [selectedItem, setSelectedInputItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const cache = useQueryClient();
  const [avatar, setAvatar] = useState<{
    filename: string;
    url: string;
  } | null>();

  const [file, setFile] = useState<any>();
  // const {data, loading: imageLoading, uploadFile} = useImage();
  const handleEditClick = (item, index) => {
    setSelectedInputItem({...item, _index: index});
    // setFile(null);
    setAvatar(null);
  };
  const onSubmit = async (data: any) => {
    console.log('%c data:', 'color:red;', data);
    // undefined;
    setLoading(true);
    try {
      const _data = {
        ...colleagues?.[selectedItem._index],
        ...data?.colleagues?.[selectedItem._index],
      };
      const {
        companyId,
        name,
        avatar: _avatar,
        role,
        email,
        linkedin,
        id,
      } = _data;

      await gate.editCompanyUserColleague({
        id,
        companyId,
        name,
        role,
        avatar: avatar ? avatar.filename : _avatar,
        email,
        linkedin,
      });
      toast.success('Colleague Updated Successfully');

      cache.invalidateQueries('getCompanyUserColleague');
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full text-md medium:text-md large:text-lg xlarge:text-xl ">
      <form
        className="w-full flex flex-col justify-center"
        onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-between">
          <div
            className="flex flex-col overflow-y-auto "
            style={{minWidth: 700, height: 500}}>
            {fields
              ?.filter((i) => typeof i.deletedAt !== 'string')
              ?.map?.((item, index) => {
                const isActive = selectedItem?.id === item?.id;
                const inputClassName = classNames(
                  'border-gray-200  h-10 rounded-lg ',
                  {
                    border: isActive,
                  },
                );
                return (
                  <fieldset key={item.id}>
                    <div className="flex">
                      <div
                        className=" flex  border  p-8 flex-grow border-opacity-30 "
                        style={{
                          borderRadius: 8,
                          marginBottom: 5,
                          borderColor: '#979797',
                        }}>
                        <div className="flex flex-col w-full">
                          <div className="flex">
                            <label
                              htmlFor={item.id}
                              className="flex-3 cursor-pointer ">
                              <img
                                src={
                                  isActive && !!avatar?.url
                                    ? getDownloadAddress(avatar.url)
                                    : item.avatar
                                    ? getDownloadAddress(item.avatar as string)
                                    : NOT_FOUND
                                }
                                alt="..."
                                className="h-20 w-20 mr-8 ml-8 mt-8 rounded-full bg-gray-400"
                              />
                              {/* {isActive && 'dsfsdf'} */}
                              <input
                                id={item.id}
                                type="file"
                                disabled={!isActive}
                                onChange={(e) => setFile(e as any)}
                                className="hidden"
                                name="avater"
                              />
                            </label>
                            <div className="flex flex-col flex-grow">
                              <Controller
                                as={Input}
                                name={`colleagues[${index}].name`}
                                title="Name"
                                control={control}
                                defaultValue={item.name}
                                inputClassName={inputClassName}
                                disabled={!isActive}
                              />
                              {/* <div className="flex justify-between "></div> */}

                              <Controller
                                as={Input}
                                name={`colleagues[${index}].email`}
                                title="Email"
                                control={control}
                                labelClassName="text-15 pb-2 font-normal"
                                inputClassName={inputClassName}
                                error=""
                                defaultValue={item.email}
                                disabled={!isActive}
                              />
                              <Controller
                                as={Input}
                                name={`colleagues[${index}].linkedIn`}
                                title="LinkedIn"
                                control={control}
                                labelClassName="text-15 pb-2 font-normal"
                                inputClassName={inputClassName}
                                error=""
                                defaultValue={item.linkedin}
                                disabled={!isActive}
                              />
                            </div>
                          </div>
                          {isActive && (
                            <div className="w-full flex justify-center items-center ">
                              <button
                                type="submit"
                                className="  flex justify-center items-center text-24 text-white rounded-full w-40 h-15 transition-all duration-300 hover:shadow-xl px-5 py-3"
                                style={{backgroundColor: '#50BE64'}}
                                // onClick={handleChangeFeedback}
                              >
                                Save
                                {loading && <Spinner className="w-6 h-6" />}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="flex justify-end cursor-pointer ml-5 "
                        style={{width: 50}}>
                        <img
                          style={{width: 18}}
                          className=""
                          src={edit}
                          onClick={() => {
                            handleEditClick(item, index);
                          }}
                          alt="..."
                        />
                        <img
                          style={{width: 25}}
                          className=" ml-5"
                          src={trashSVG}
                          onClick={() => {
                            deleteUser(item?.id);
                          }}
                          alt="..."
                        />
                      </div>
                    </div>
                  </fieldset>
                );
              })}
          </div>

          {/* <div className="w-full flex justify-center items-center">
          <button
            type="submit"
            className=" mt-5 flex justify-center items-center text-24 text-white rounded-full w-40 h-15 transition-all duration-300 hover:shadow-xl px-5 py-3"
            style={{backgroundColor: '#50BE64'}}
            // onClick={handleChangeFeedback}
          >
            Save
            {loading && <Spinner className="w-6 h-6" />}
          </button>
        </div> */}
        </div>
      </form>
      <Cropper
        file={file}
        setResponse={(res) => {
          setAvatar(res.data);
        }}
      />
    </div>
  );
};

export default ColleagueDropDown;
