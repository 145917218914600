import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {getCompany} from 'store/reducers/company';
import toasts from 'providers/toast-provider/toasts';

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({children}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // TODO: check accessToken via token.get() after implement logout !
    const isLoggedIn = !!localStorage.getItem('ACCESS_TOKEN');
    const isLoginPage = location.pathname.includes('/login');
    // ---------- protect routes from unauthorized user ------------ //
    if (!isLoggedIn && !isLoginPage) {
      history.push('/login');
    } else if (isLoggedIn && isLoginPage) {
      history.push('/general'); // general is our panel home page.
    }
    // --------- Startup request for display departments on the sidebar ------------ //
    if (isLoggedIn && !isLoginPage) {
      dispatch(getCompany());
    }
  }, [location, history, dispatch]);

  return <>{children}</>;
};

export default AuthProvider;
