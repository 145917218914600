import React, {useEffect, useRef, useState} from 'react';

import {Cropper} from 'components';
import SectionTitle from './sectionTitle';

import {trashSVG} from 'assets/svg';
import {imagePlaceholder, profilePlaceholder} from 'assets/images';
import {getDownloadAddress} from 'utils';

interface ImageSectionProps {
  title: string;
  imageSrc?: string;
  isProfile?: boolean;
  setImage: Function;
  editMode: boolean;
}

const ImageSection: React.FC<ImageSectionProps> = ({
  title,
  imageSrc,
  isProfile,
  setImage,
  editMode,
}) => {
  const [imageSource, setImageSource] = useState<string | null>(
    imagePlaceholder,
  );
  const [file, setFile] = useState();

  useEffect(() => {
    if (imageSrc) return setImageSource(imageSrc);
    if (isProfile) return setImageSource(profilePlaceholder);
    // if (!imageSrc) return setImageSource(imagePlaceholder);
  }, [imageSrc, isProfile]);

  const inputRef = useRef(null);

  const removeFile = () => {
    ((inputRef?.current as never) as HTMLInputElement).value = '';
  };

  const handleSelectImage = () => {
    if (editMode && !imageSrc) {
      // remove previous file (if exist)
      removeFile();
      // click on th input file to open file uploader
      ((inputRef?.current as never) as HTMLInputElement).click();
    }
  };

  const handleDeleteImage = () => {
    setImageSource(imagePlaceholder);
    setImage({filename: '', url: ''});
  };

  const profileClass = 'max-w-full h-auto rounded-full w-20';
  const pictureClass = 'w-full h-full object-cover w-24';
  return (
    <>
      <SectionTitle title={title} />
      <div className="flex justify-start items-end h-24">
        <div className="w-32 h-20">
          {
            <img
              src={imageSource ? getDownloadAddress(imageSource) : ''}
              alt={title}
              onClick={handleSelectImage}
              className={isProfile ? profileClass : pictureClass}
            />
          }

          <input
            type="file"
            name={title}
            ref={inputRef}
            onChange={(e) => setFile(e as any)}
            accept="image/*"
            className="hidden"
          />
        </div>
        {imageSrc && editMode && (
          <div className="p-2 cursor-pointer" onClick={handleDeleteImage}>
            <img src={trashSVG} alt="trash" className="w-3" />
          </div>
        )}

        {console.log('imageSource:', imageSource)}
      </div>
      <Cropper
        file={file}
        setResponse={(res) => {
          setImage(res.data);
          setImageSource(res.data.url);
        }}
      />
    </>
  );
};

export default ImageSection;
