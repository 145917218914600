import React, {useState, useEffect} from 'react';
import {useQueryClient} from 'react-query';

import Spinner from 'components/spinner';
import {trashSVG, edit, feedbackSort} from 'assets/svg';
import gate from 'gate';

export interface AddUserProps {
  questions: [];
  companyId: number;
}

const FeedbackDropDown: React.FC<AddUserProps> = ({
  questions = [],
  companyId,
}) => {
  const [text, setText] = useState();
  const [selectedFeedbackItem, setSelectedInputItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const cache = useQueryClient();
  const [isAddActive, setAddActive] = useState<boolean>(false);

  useEffect(() => {
    if (isAddActive) {
      setText('');
    }
  }, [isAddActive]);

  const handleChangeFeedback = async (id) => {
    if (isAddActive && !selectedFeedbackItem) {
      try {
        await gate.createCompanyFeedback({
          companyId,
          feedback: text,
        });
        cache.invalidateQueries('getCompanyFeedbackScores');
        setAddActive(false);
        setText('');
      } catch (e) {
        console.log(e);
      }
    } else {
      setLoading(true);
      try {
        const res = await gate.editCompanyFeedback({
          companyId,
          id: selectedFeedbackItem?.id,
          feedback: text,
        });
        setSelectedInputItem(null);
        cache.invalidateQueries('getCompanyFeedbackScores');
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };
  const handleEditClick = (id) => {
    setSelectedInputItem(id);
    setText(questions?.[id]?.feedback);
  };

  const handleDeleteFeedback = async (id) => {
    if (window.confirm('Are you sure?')) {
      setLoading(true);
      try {
        const res = await gate.deleteCompanyFeedback(id);
        cache.invalidateQueries('getCompanyFeedbackScores');
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  let questions_ = questions || [];
  questions_.sort((a, b) => (a.id > b.id ? 1 : -1));

  return (
    <div className="flex flex-col w-full">
      <div className="w-full pb-8 text-md medium:text-md large:text-lg xlarge:text-xl font-bold">
        Feedback Questions
        <button
          className="float-right text-16 self-end pr-28"
          // style={{width: 25, height: 25, borderRadius: '50%'}}
          onClick={() => {
            setSelectedInputItem(null);
            setAddActive((isAddActive) => !isAddActive);
          }}>
          {isAddActive ? 'Cancel' : 'Add New'}
        </button>
      </div>

      {isAddActive && (
        <div className="flex flex-row justify-between">
          {/* style={{width: '85%'}}> */}
          <div
            className="h-20 border  p-3 flex-grow relative"
            style={{
              borderRadius: 8,
              marginBottom: 5,
              borderColor: '#979797',
            }}>
            <img
              className="h-8 absolute"
              style={{top: 20}}
              src={feedbackSort}
              onClick={() => {}}
              alt="..."
            />
            <input
              className="w-full h-full pl-8"
              value={text}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setText(e.target.value)
              }
            />
          </div>
          <div className="flex justify-end px " style={{}}></div>
        </div>
      )}
      {questions_?.map((item) => {
        const isActive = selectedFeedbackItem?.id === item?.id;
        return (
          <div className="flex flex-row justify-between">
            <div
              className="h-20 border  p-3 flex-grow relative"
              style={{
                borderRadius: 8,
                marginBottom: 5,
                borderColor: '#979797',
              }}>
              <img
                className="h-8 absolute"
                style={{top: 20}}
                src={feedbackSort}
                onClick={() => {}}
                alt="..."
              />
              <input
                className="w-full h-full pl-8"
                style={{opacity: isActive ? 1 : 0.5}}
                value={isActive ? text : item?.feedback}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setText(e.target.value)
                }
                disabled={!isActive}
              />
            </div>
            <div className="flex justify-end px cursor-pointer">
              <img
                className="w-5 ml-5"
                src={edit}
                onClick={() => {
                  handleEditClick(item);
                }}
                alt="..."
              />
              <img
                className="w-5 ml-5 cursor-pointer"
                src={trashSVG}
                onClick={() => {
                  handleDeleteFeedback(item?.id);
                }}
                alt="..."
              />
            </div>
          </div>
        );
      })}

      <div className="w-full grid grid-cols-3 my-4"></div>

      <div className="w-full flex justify-center items-center">
        <button
          type="submit"
          className=" mt-5 flex justify-center items-center text-24 text-white rounded-full w-40 h-15 transition-all duration-300 hover:shadow-xl px-5 py-3"
          style={{backgroundColor: '#50BE64', opacity: text ? 1 : 0.5}}
          onClick={handleChangeFeedback}>
          Save
          {loading && <Spinner className="w-6 h-6" />}
        </button>
      </div>
    </div>
  );
};

export default FeedbackDropDown;
