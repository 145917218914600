import React from 'react';

import {cutString} from 'helpers/cutString';
import {DepartmentType} from 'interfaces/departmentType';

import {copySVG, removeSVG} from 'assets/svg';

interface DepartmentCardProps {
  department: DepartmentType;
  onSelect: Function;
  onDuplicate: Function;
  onDelete: Function;
}

const DepartmentCard: React.FC<DepartmentCardProps> = ({
  department,
  onSelect,
  onDuplicate,
  onDelete,
}) => {
  const handleDelete = () => {
    onDelete(department.id);
  };

  const waitForServerResponse = department.loading;

  return (
    <div
      className={`flex flex-row justify-between items-center cursor-pointer hover:shadow hover:bg-yellow-200 duration-300 h-16 px-6
        ${waitForServerResponse && 'opacity-25 pointer-events-none'}
     `}>
      <p className="flex-1 text-md" onClick={() => onSelect(department)}>
        APP {cutString(department.name, 12).toUpperCase()}
      </p>
      <div className="flex flex-row">
        <img
          src={copySVG}
          alt="duplicate"
          className="mx-3 p-1 w-8 h-8"
          onClick={() => onDuplicate(department)}
        />
        <img
          src={removeSVG}
          alt="remove"
          className="p-1 w-8 h-8"
          onClick={handleDelete}
        />
      </div>
    </div>
  );
};

export default DepartmentCard;
