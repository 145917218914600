import React, {FC} from 'react';
import {PDFReader} from 'components';
import {getDownloadAddress} from 'utils';
interface PropsTypes {
  url: string;
}
const PDFWidget: FC<PropsTypes> = ({url}) => {
  return url && url !== '' ? (
    <PDFReader url={getDownloadAddress(url)} />
  ) : (
    <div className="flex justify-center items-center text-lg pt-10">
      Select the PDF file
    </div>
  );
};

export default PDFWidget;
