import React, {Fragment, useEffect, useRef, useState} from 'react';
import {EditorRemoveSVG} from 'assets/svg';

import {QuizItem} from 'components';

import WidgetWrapper, {QuizWidgetType} from './withWidget';
import {useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import gate from 'gate';
import {useDispatch} from 'react-redux';
import {setPage} from 'store/reducers/quizWidget';

export const initialData: QuizWidgetType = {
  description: '',
  question: '',
  answer: '',
  type: 'TrueAndFalse',
  choices: [],
};
const PdfWidget = () => {
  const {action, id} = useParams<{action: string; id: string}>();
  const dispatch = useDispatch();
  const [isMultipleQuiz, setIsMultipleQuiz] = useState(false);
  const [data, setData] = useState<QuizWidgetType[]>([initialData]);
  const [savaAndNew, setSavaAndNew] = useState<boolean>(false);
  const [page, _setPage] = useState<number>(0);
  const refForm = useRef<HTMLFormElement>(null);

  const {data: _data, isLoading} = useQuery(
    `widget/${id}`,
    async () => gate.viewWidget(id),
    {enabled: action === 'edit'},
  );

  useEffect(() => {
    if (_data?.data) {
      return setData(_data?.data?.content);
    }
  }, [isLoading]);

  const handleOnPageChange = (page: number) => {
    dispatch(setPage(page));
    _setPage(page);
  };
  useEffect(() => {
    return () => {
      dispatch(setPage(0));
    };
  }, []);

  const handleIncrementPage: Function = () => {
    let _page = page;
    _page++;
    setData((prev) => {
      return [...prev, initialData];
    });
    dispatch(setPage(page));
    _setPage(_page);
  };

  const onSubmit = (formData: QuizWidgetType) => {
    const newData = data;
    if (formData.choices && Object.entries(formData.choices).length) {
      formData.type = 'MultipleChoice';
    } else {
      formData.type = 'TrueAndFalse';
      formData.answer = formData.answer === 'false' ? false : true;
    }
    newData[page] = formData;
    setData([...newData]);
    if (savaAndNew) {
      handleIncrementPage();
    }
    setSavaAndNew(false);
  };
  const handleDelete = () => {
    if (page === 0) {
      return;
    }
    const newData = data;
    newData.splice(page, 1);

    _setPage((prev) => prev - 1);
    dispatch(setPage(page - 1));
    setData([...newData]);
  };

  const titleForTrueFalse =
    '  Add true or false questions and let the users guess. Set wether a question is true or false and add a description';

  const titleForMultipleChoice =
    '  Add multiple choice questions and let the users guess. Add a description and add choices';
  return (
    <WidgetWrapper widgetData={data} QuizFormRef={refForm} widgetType={'Quiz'}>
      <div className="w-full flex justify-center flex-col">
        <div className=" py-3 text-21">QUESTION TYPE</div>
        <div className="w-full py-3">
          {isMultipleQuiz ? titleForMultipleChoice : titleForTrueFalse}
        </div>

        {data.map((quiz, index) => {
          if (page === index)
            return (
              <Fragment key={index}>
                <QuizItem
                  forwardRef={refForm}
                  data={quiz}
                  onChangeQuizType={(type: string) => {
                    if (type === 'MultipleChoice') {
                      setIsMultipleQuiz(true);
                    }
                    if (type === 'TrueAndFalse') {
                      setIsMultipleQuiz(false);
                    }
                  }}
                  onSubmit={onSubmit}
                />
              </Fragment>
            );
        })}
        <div className="col-span-2 rounded-b-xl relative uppercase p-2 font-bold bg-gray-350 flex justify-start items-center ">
          page
          {data.map((p, index) => {
            if (page === index) {
              return (
                <button
                  key={index}
                  onClick={() => handleOnPageChange(index)}
                  type="submit"
                  form="hook-form"
                  className="m-2 p-1 w-8 h-8 flex  justify-center  items-center transition-all duration-300 hover:bg-gray-400 rounded-full  text-black border border-gray-500">
                  <div className="border-black border-r-2 border-b-2 transform rotate-45 w-2 h-4 " />
                </button>
              );
            }
            return (
              <>
                <button
                  key={index}
                  onClick={() => handleOnPageChange(index)}
                  className={`m-2 p-3 w-8 h-8 flex ${
                    page === index && 'bg-gray-500'
                  } justify-center  items-center animate-opacity transition-all duration-300 hover:bg-gray-300 rounded-full text-10 text-black border border-gray-500`}>
                  {index + 1}
                </button>
                <div className=" absolute right-0 mr-4">
                  <button
                    onClick={handleDelete}
                    className={`m-2 w-8 h-8 flex  justify-center items-center transition-all duration-300 hover:bg-gray-300 rounded-full  text-black border border-gray-500`}>
                    <img src={EditorRemoveSVG} alt="" />
                  </button>
                </div>
              </>
            );
          })}
          <button
            type="submit"
            form="hook-form"
            onClick={() => setSavaAndNew(true)}
            className={`m-2 p-3 w-8 h-8 flex  justify-center items-center transition-all duration-300 hover:bg-gray-300 rounded-full  text-black border border-gray-500`}>
            +
          </button>
        </div>
      </div>
    </WidgetWrapper>
  );
};
export default PdfWidget;
