import React from 'react';
import {ToastContainer} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

interface ToastProviderProps {
  children?: React.ReactNode;
}

const ToastProvider: React.FC<ToastProviderProps> = ({children}) => {
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {children}
    </>
  );
};

export default ToastProvider;
