import React from 'react';
import classnames from 'classnames';
interface InputProps {
  name: string;
  error?: string;
  title?: string;
  type?: string;
  placeholder?: string;
  rest?: any;
  labelClassName?: string;
  className?: string;
  inputClassName?: string;
  errorClassName?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
}

const Input: React.FC<InputProps> = ({
  error,
  errorClassName,
  labelClassName,
  inputClassName,
  className,
  placeholder,
  name,
  type,
  title,
  autoComplete = 'off',
  autoFocus = false,
  disabled,
  ...rest
}) => {
  return (
    <div className={className + ' mb-4'}>
      <label className={`block pb-1 ${labelClassName}`} htmlFor={name}>
        {title}
      </label>
      <input
        {...rest}
        placeholder={placeholder}
        type={type}
        id={name}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={classnames(
          error && errorClassName,
          'w-full  py-3 text-sm mb-2',
          inputClassName,
          !disabled && 'px-3',
        )}
      />

      {error && (
        <p
          className={`text-md text-center text-red-600 h-4  w-full ${errorClassName}`}>
          {error}
        </p>
      )}
    </div>
  );
};

export default Input;
