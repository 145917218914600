import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  company: {
    loading: true, // set "true" to prevent re-rendering on initial fetch request
    resetData: false,
  },
  departments: [],
  activeDepartment: {
    resetData: false,
  },
  departmentsStatus: {
    loading: true,
    activeDepartmentId: 0,
  },
  _category: '',
};

const companyReducer = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setTargetCategory: (state, {payload}) => {
      state._category = payload;
    },
    getCompany: (state) => {
      state.company = {
        loading: true,
      };
      state.departmentsStatus = {
        ...state.departmentsStatus,
        loading: true,
      };
    },

    getCompanySuccess: (state, {payload}) => {
      // the "departments" data is in the company (we mixed them on fetch). now separate them:
      const {departments} = payload;
      delete payload.departments;

      const departments_ = departments.filter((d) => d.deletedAt === null);

      state.company = {
        loading: false,
        ...payload,
      };
      state.departments = [...departments_];
      state.departmentsStatus = {
        ...state.departmentsStatus,
        loading: false,
      };
    },

    setActiveDepartment: (state, {payload}) => {
      state.departmentsStatus.activeDepartmentId = payload.id;
      state.activeDepartment = payload;
    },

    updateCompany: (state, {payload}) => {},

    updateCompanySuccess: (state, {payload}) => {
      state.company = {
        ...state.company,
        ...payload,
      };
    },

    updateCompanyFailure: (state) => {
      state.company = {
        ...state.company,
        resetData: !state.company.resetData,
      };
    },

    updateDepartment: (state, {payload}) => {},

    updateDepartmentSuccess: (state, {payload}) => {
      state.departments = [
        ...state.departments.map((department) =>
          department.id === payload.id
            ? {...department, ...payload}
            : department,
        ),
      ];
      state.activeDepartment = {...payload};
      state.departmentsStatus = {
        ...state.departmentsStatus,
        loading: false,
      };
    },

    updateDepartmentFailure: (state) => {
      state.activeDepartment = {
        ...state.activeDepartment,
        resetData: !state.activeDepartment.resetData,
      };
    },

    createDepartment: (state, {payload}) => {
      state.departmentsStatus = {
        ...state.departmentsStatus,
        loading: true,
      };
    },

    createDepartmentSuccess: (state, {payload}) => {
      state.departments = [...state.departments, payload];
      state.departmentsStatus = {
        ...state.departmentsStatus,
        loading: false,
        activeDepartmentId: payload.id,
      };
    },

    createDepartmentFailure: (state) => {
      state.departmentsStatus = {
        ...state.departmentsStatus,
        loading: false,
      };
    },

    cloneDepartment: (state, {payload}) => {
      state.departmentsStatus = {
        ...state.departmentsStatus,
        loading: true,
      };
    },

    cloneDepartmentSuccess: (state, {payload}) => {
      state.departments = [...state.departments, payload];
      state.departmentsStatus = {
        ...state.departmentsStatus,
        loading: false,
        activeDepartmentId: payload.id,
      };
      state.activeDepartment = {
        ...payload,
        resetData: state.activeDepartment.resetData,
      };
    },

    cloneDepartmentFailure: (state) => {
      state.departmentsStatus = {
        ...state.departmentsStatus,
        loading: false,
      };
    },

    deleteDepartment: (state, {payload}) => {
      const departments = [
        ...state.departments.map((department) =>
          department.id === payload.id
            ? {...department, loading: true}
            : department,
        ),
      ];

      state.departments = departments;
    },

    deleteDepartmentSuccess: (state, {payload}) => {
      const departments = state.departments.filter(
        (department) => department.id !== payload.id,
      );

      state.departments = [...departments];
      state.departmentsStatus = {
        ...state.departmentsStatus,
        activeDepartmentId: departments[0].id, // jump to another department after delete current dep
      };
      state.activeDepartment = {
        ...departments[0],
        resetData: state.activeDepartment.resetData,
      };
    },

    deleteDepartmentFailure: (state, {payload}) => {
      state.departments = [
        ...state.departments.map((department) =>
          department.id === payload.id
            ? {...department, loading: false}
            : department,
        ),
      ];
    },

    resetData: (state) => {
      state.company = {
        ...state.company,
        resetData: !state.company.resetData,
      };

      state.activeDepartment = {
        ...state.activeDepartment,
        resetData: !state.activeDepartment.resetData,
      };
    },
  },
});

export const {
  getCompany,
  getCompanySuccess,
  setActiveDepartment,
  updateCompany,
  updateCompanySuccess,
  updateCompanyFailure,
  updateDepartment,
  updateDepartmentSuccess,
  updateDepartmentFailure,
  createDepartment,
  createDepartmentSuccess,
  createDepartmentFailure,
  deleteDepartment,
  deleteDepartmentSuccess,
  deleteDepartmentFailure,
  resetData,
  setTargetCategory,
  cloneDepartment,
  cloneDepartmentSuccess,
  cloneDepartmentFailure,
} = companyReducer.actions;

export default companyReducer.reducer;
