import React from 'react';
import {EditorAddSVG, EditorRemoveSVG} from 'assets/svg';

import {Spinner} from 'components';
import {useParams} from 'react-router-dom';

const EditorFooter = ({
  widgetMeta,
  page,
  addPage,
  removePage,
  selectPage,
  loading,
}) => {
  const {action} = useParams();
  const pages = widgetMeta;
  return (
    <div
      className="col-span-2 rounded-b-xl p-2 bg-gray-350 flex justify-between items-center "
      style={{height: '10%'}}>
      <div className="col-span-2 rounded-b-xl uppercase p-2 font-bold flex justify-start items-center overflow-scroll">
        <span className="mr-3 ml-3 font-normal text-xs">PAGE</span>
        {pages?.map((p, index) => {
          return (
            <button
              key={`btn_${index}`}
              onClick={() => selectPage(index)}
              className={`m-2 p-3 w-8 h-8 flex ${
                page === index && 'bg-gray-500'
              } justify-center  items-center animate-opacity transition-all duration-300 hover:bg-gray-300 rounded-full text-10 text-black border border-gray-500`}>
              {index + 1}
            </button>
          );
        })}
        <button
          key="btn_add"
          onClick={addPage}
          style={{minWidth: 23}}
          className={`m-2 p-2 w-8 h-8 flex justify-center  items-center animate-opacity transition-all duration-300 hover:bg-gray-300 rounded-full text-10 text-black border border-gray-500`}>
          <img src={EditorAddSVG} alt="" />
        </button>
      </div>

      {loading ? (
        <div
          className={`m-2 w-8 h-8 flex justify-center items-center text-10 float-right`}>
          <Spinner />
        </div>
      ) : (
        widgetMeta?.length > 1 && (
          <button
            key="btn_add"
            onClick={removePage}
            className={`m-2 w-8 h-8 flex justify-center items-center text-10 float-right`}>
            <img src={EditorRemoveSVG} alt="" />
          </button>
        )
      )}
    </div>
  );
};

export default React.memo(EditorFooter);
