export const ItemTypes = {
  PDF: 'PDF',
  MEDIA: 'MEDIA',
  QUIZ: 'QUIZ',
  GALLERY: 'GALLERY',
  TEXT: 'TEXT',
  URL: 'URL',
  TODO: 'TODO',
  AUDIO: 'AUDIO',
};
