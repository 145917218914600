import React, {Children, FC, ReactNode} from 'react';
interface TabsProps {
  children?: ReactNode;
  tabsNames: string[];
  activeTabClassName?: string;
  tabClassName?: string;
  tabsClassName?: string;
  openTab: number;
  setOpenTab: Function;
}

const Tabs: FC<TabsProps> = ({
  children,
  tabsNames = [],
  activeTabClassName,
  tabClassName,
  openTab,
  setOpenTab,
}) => {
  return (
    <div className=" w-full px-32 my-4 ">
      <div className=" flex w-full justify-around  uppercase overflow-hidden bg-gray-250  text-base rounded-full">
        {tabsNames.map((tab, key) => {
          return (
            <div
              key={key}
              onClick={() => setOpenTab(key)}
              className={` ${tabClassName}  ${
                key === openTab && activeTabClassName
              } `}>
              {tab}
            </div>
          );
        })}
      </div>

      <div className="w-full pt-5">
        {Children.map(children, (Child, key) => {
          if (openTab === key) return <>{Child}</>;
        })}
      </div>
    </div>
  );
};
export default Tabs;
