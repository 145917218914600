import React from 'react';
import cn from 'classnames';

import Pdf from './PDF';
import Todo from './Todo';
import Quiz from './Quiz';
import Media from './Media';
import Audio from './Audio';
import Editor from './Text';
import Gallery from './Gallery';
// import classes from '../preview.module.css';

interface Props {
  name?: string;
  mainColor?: string;
  backgroundColor?: string;
  categories?: any;
  categoriesIsLoading?: boolean;
  header?: string;
  widgetType?: any;
  widgetData?: any;
  page?: number;
}

const Widget = ({
  page,
  mainColor = 'white',
  backgroundColor = 'white',
  widgetType,
  widgetData,
}: Props) => {
  return (
    <div className="w-full animate-fadeRight  flex flex-col">
      {widgetType !== 'Url' && (
        <div
          className={'w-full bg-white p-4  min-h-screen overflow-scroll'}
          style={widgetType === 'Editor' ? {height: 716} : {}}>
          {widgetType === 'Quiz' && <Quiz data={widgetData} />}
          {widgetType === 'Pdf' && <Pdf url={widgetData.url} />}
          {widgetType === 'Media' && (
            <Media url={widgetData.url} filename={widgetData.filename} />
          )}
          {widgetType === 'Gallery' && <Gallery data={widgetData} />}
          {widgetType === 'Editor' && <Editor data={widgetData} page={page} />}
          {widgetType === 'Audio' && <Audio data={widgetData} />}
          {widgetType === 'ToDo' && <Todo data={widgetData} />}
        </div>
      )}
    </div>
  );
};

export default Widget;
