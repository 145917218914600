import React, {ReactElement, useState} from 'react';
import {useKeenSlider} from 'keen-slider/react';

export interface SliderProps {
  data: any[];
  renderItem: (item: any, index: number) => ReactElement;
}

const Slider: React.FC<SliderProps> = ({data, renderItem}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, slider] = useKeenSlider<any>({
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });
  return (
    <>
      <div ref={sliderRef} className="w-full keen-slider  ">
        {!data.length ? (
          <div>laoding...</div>
        ) : (
          data?.map((item, index) => {
            return (
              <div key={index} className="keen-slider__slide ">
                {renderItem(item, index)}
              </div>
            );
          })
        )}
      </div>
      <div className="w-full flex absolute top-26-rem mt-3 p-4 justify-center items-center ">
        {data?.map((i, idx) => {
          return (
            <button
              key={idx}
              onClick={() => {
                slider.moveToSlideRelative(idx);
              }}
              style={{backgroundColor: 'black'}}
              className={
                'w-3 h-3  mx-1 rounded-full transform  transition-all duration-300 ' +
                (currentSlide === idx ? 'scale-125 opacity-100 ' : 'opacity-50')
              }
            />
          );
        })}
      </div>
    </>
  );
};

export default Slider;
