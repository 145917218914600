import React from 'react';
import cn from 'classnames';

import Home from './Home';
import Widget from './Widgets/Widget';

import classes from './preview.module.css';

export type QuizType = 'TrueAndFalse' | 'MultipleChoice';
export interface QuizWidgetType {
  question: string;
  qiuzType: QuizType;
  options?: {
    [k: string]: string;
  };
  answer: string | boolean;
  description: string;
}
export interface EditorWidgetType {
  editableText: string;
  viewableText: string;
}

export type widgetType = {
  Media: {filename: string; url: string};
  Pdf: {filename: string};
  Quiz: QuizWidgetType[];
  Gallery: {gallery: {url: string; filename: string; description: string}[]};
  Url: {url: string};
  ToDo: {tasks: string[]};
  Text: EditorWidgetType;
  // Feedback: {test3: string};
  // Text: {test4: string};
};

interface PreviewPageProps {
  name?: string;
  mainColor?: string;
  backgroundColor?: string;
  categories?: any;
  categoriesIsLoading?: boolean;
  header?: string;
  widgetType?: any;
  widgetData?: any;
  page?: number;
  accentColor?: string;
}
const PreviewGeneral: React.FC<PreviewPageProps> = (props) => {
  return (
    <div className="col-span-2">
      <div className="relative justify-center items-center">
        <div
          className={cn(classes.container)}
          style={{backgroundColor: props.backgroundColor}}>
          <div className={classes.container_top}>
            <div className={classes.container_top_in}></div>
          </div>
          {!props.widgetType ? <Home {...props} /> : <Widget {...props} />}
        </div>
      </div>
    </div>
  );
};

export default PreviewGeneral;
