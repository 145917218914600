import {toast} from 'react-toastify';

// the toasts default configuration sets on  toast-provider page and can be overwritten here

const toasts = {
  loginFailure: () =>
    toast.error('USERNAME OR PASSWORD IS INCORRECT. PLEASE TRY AGAIN.', {
      position: 'top-right',
    }),

  forgetPassFailure: () =>
    toast.error('NOT SUCCESSFUL. PLEASE TRY AGAIN.', {
      position: 'top-right',
    }),

  newpassFailure: () =>
    toast.error(
      'YOUR TOKEN HAS EXPIRED! PLEASE CONFIRM YOUR EMAIL ON THE "FORGET PASSWORD" PAGE AGAIN.',
      {position: 'top-right', autoClose: 10000},
    ),

  unauthorized: () =>
    toast.error(
      'YOU ARE NOT LOGGED IN OR TOKEN HAS EXPIRED. PLEASE LOGIN AGAIN',
      {position: 'top-right', autoClose: 8000},
    ),

  getCompanyFailure: () =>
    toast.error(
      'THERE IS AN ERROR. MAKE SURE YOU ARE CONNECTED TO INTERNET AND THEN REFRESH THE PAGE. IF THE PROBLEM PERSISTS, CONTACT YOUR HELP DESK OR SYSTEM ADMINISTRATION',
      {
        autoClose: 12000,
      },
    ),

  updateCompanySuccess: () => toast.success('COMPANY SUCCESSFULLY UPDATED'),
  updateCompanyFailure: () =>
    toast.error('COMPANY WAS NOT UPDATED. PLEASE TRY AGAIN'),

  updateDepartmentSuccess: () =>
    toast.success('DEPARTMENT SUCCESSFULLY UPDATED'),
  updateDepartmentFailure: () =>
    toast.error('DEPARTMENT WAS NOT UPDATED. PLEASE TRY AGAIN'),

  createDepartmentSuccess: () => toast.success('APP SUCCESSFULLY CREATED.'),
  createDepartmentFailure: () =>
    toast.error('APP WAS NOT CREATED. PLEASE TRY AGAIN'),

  cloneDepartmentSuccess: () => toast.success('APP SUCCESSFULLY CLONED.'),
  cloneDepartmentFailure: () =>
    toast.error('APP WAS NOT CLONED. PLEASE TRY AGAIN'),

  deleteDepartmentSuccess: () => toast.success('APP SUCCESSFULLY DELETED'),
  deleteDepartmentFailure: () =>
    toast.error('APP WAS NOT DELETED. PLEASE TRY AGAIN'),

  uploadImageSuccess: () =>
    toast.success('SUCCESSFULLY UPLOADED', {autoClose: 4000}),
  uploadImageFailure: () =>
    toast.error('IMAGE NOT UPLOADED. PLEASE TRY AGAIN', {autoClose: 5000}),

  emptyDepartment: () =>
    toast.error('FIRST SELECT A DEPARTMENT', {
      position: 'top-right',
      autoClose: 4000,
    }),
  createWidgetSuccessfull: () => {
    toast.success('SUCCESSFULLY Created', {autoClose: 4000});
  },
};

export default toasts;
