import React from 'react';

import SectionTitle from './sectionTitle';

const borderColor = (isEdit: boolean, hasError: string | undefined) => {
  let color = '';
  if (isEdit) {
    color = 'border-yellow-500 border-b-2';
  }
  if (hasError) {
    color = 'border-red-700 border-b-4';
  }
  return color;
};

interface LanguageSectionProps {
  title: string;
  name: string;
  forwardRef: VoidFunction;
  editMode: boolean;
  error?: string | undefined;
  languages: Array<{id: number; name: string}>;
}

const LanguageSection: React.FC<LanguageSectionProps> = ({
  title,
  name,
  forwardRef,
  editMode,
  languages,
  error,
}) => {
  return (
    <>
      <SectionTitle title={title} />
      <select
        disabled={!editMode}
        name={name}
        ref={forwardRef}
        className={`text-xl 2xl:text-xxl font-bold -mt-2 mb-1 bg-transparent ${borderColor(
          editMode,
          error,
        )}`}>
        {languages.map((language) => (
          <option key={language.id} value={language.name}>
            {language.name}
          </option>
        ))}
      </select>
      <p className="text-sm text-left text-red-600 h-4 p-2 w-full -mt-12">
        {error && error}
      </p>
    </>
  );
};

export default LanguageSection;
