import api from './config';
import {USER_COLLEAGUE} from 'interfaces/api';
const auth = {
  login: (data) => api.post('company/login', data),
  forgetPassword: (data) => api.post('company/forget-password-request', data),
  newPassword: (data) => api.post('company/forget-password', data),
};

const upload = {
  uploadFile: (data) =>
    api.post('storage/upload', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: null,
      },
    }),
};
interface COPMPANY_USERS {
  departmentID: int;
  page?: int;
  offset?: int;
  limit?: int;
  active?: boolean;
}

const company = {
  getCompanyDepartmentUsers: ({
    departmentID,
    page = 1,
    offset,
    limit,
    active = true,
  }: COPMPANY_USERS) => api.get(`/company/users/${departmentID}/list`),
  getCompanyUsers: (page = 1, text = '') =>
    api.get(`/company/users?sort=id,DESC&limit=10&page=${page}`),
  getCompanyUser: (id) => api.get(`/company/users/${id}`),
  createCompanyUsers: (data) => api.post(`/company/users`, data),
  getUserDetails: (id) => api.get(`/company/users/${id}/details`),
  resetCompanyUserPasswordById: ({id, data}) =>
    api.post(`/company/users/${id}/reset-password`, data),

  deleteCompanyUserById: ({id}) => api.delete(`/company/users/${id}`),

  getCompanyMostWidget: (departmentID) =>
    api.get(`/company/widget/${departmentID}/most-view`),
  getCompanyLeastWidget: (departmentID) =>
    api.get(`/company/widget/${departmentID}/least-view`),
  ///Feedback
  createCompanyFeedback: (data) => api.post(`/company/feedback`, data),
  editCompanyFeedback: ({id, companyId, feedback}) =>
    api.patch(`/company/feedback/${id}`, {feedback, companyId}),
  getCompanyFeedback: ({departmentID}) =>
    api.get(`/company/feedback?sort=id,DESC`),
  deleteCompanyFeedback: (id) => api.delete(`/company/feedback/${id}`),

  //coleague
  createCompanyUserColleague: (data: USER_COLLEAGUE) =>
    api.post(`/company/colleague?sort=id,DESC`, data),
  editCompanyUserColleague: ({id, ...data}) =>
    api.patch(`/company/colleague/${id}`, data),
  getCompanyUserColleague: () => api.get(`/company/colleague?sort=id,DESC`),
  deleteCompanyUserColleague: (id) => api.delete(`/company/colleague/${id}`),

  getCompany: () => api.get('company/info'),
  // getCompany: () => api.get('company?join=departments'),
  getDepartments: () => api.get('company/department'),
  updateCompany: (id, data) => api.patch(`company/${id}`, data),
  updateDepartment: (id, data) => api.patch(`company/department/${id}`, data),
  cloneDepartment: (id) => api.post(`company/department/${id}/clone`),
  createDepartment: (data) => api.post('company/department', data),
  deleteDepartment: (id) => api.delete(`company/department/${id}`),
  getCompanyFeedbackScores: () => api.get('company/feedback/scores'),
};

const FAQ = {
  getFAQ: (departmentId) =>
    api.get(`/company/faq?filter=Faq.departmentId||$eq||${departmentId}`),
  createFAQ: (data) => api.post('/company/faq', data),
  updateFAQ: (data, id) => api.put(`/company/faq/${id}`, data),
  deleteFAQ: (id) => api.delete(`/company/faq/${id}`),
  updateFAQ: (data, id) => api.put(`/company/faq/${id}`, data),
};
const widget = {
  createWidget: (data) =>
    api.post(`company/widget/${data.url}`, {...data.value}),
  updateWidget: (data, id, {oldContent, newContent}) => {
    if (oldContent && newContent) {
      const newContentWithoutId = newContent.filter((item) => !item.id);
      const newContentWithId = newContent.filter((item) => item.id);
      const removeContent = oldContent.filter(
        (item) => !newContent.find((i) => i.id === item.id),
      );
      const url = data.url === 'to-do' ? 'todo' : data.url;
      newContentWithoutId.forEach((item) => {
        let itemData = {...item};
        if (url === 'quiz') {
          if (Array.isArray(item.choices)) {
          } else {
            itemData.choices = [];
          }
        }

        api.post(`company/widget/${id}/${url}/item`, itemData);
      });
      newContentWithId.forEach((item) => {
        let itemData = {...item};
        if (url === 'quiz') {
          if (Array.isArray(item.choices)) {
          } else {
            itemData.choices = [];
          }
        }
        if (url === 'todo') {
          itemData.question = itemData.task;
        }
        api.patch(`company/widget/${id}/${url}/item/${item.id}`, itemData);
      });
      removeContent.forEach((item) => {
        api.delete(`company/widget/${id}/${url}/item/${item.id}`);
      });
    }

    return api.patch(`company/widget/${id}/${data.url}`, {...data.value});
  },
  createCategory: (data) => api.post(`company/category`, {...data.value}),
  viewWidget: (id) => api.get(`company/widget/${id}`),
  getAllCategories: () =>
    api.get(
      'company/category?sort=id%2CASC&sort=widgets.order%2CASC&sort=widgets.orderUpdatedAt%2CDESC',
    ),
  updateCategories: (data) =>
    api.patch(`company/category/${data.id}`, {...data.value}),
  deleteWidget: (id) => api.delete(`/company/widget/${id}`),
  viewCategory: (data) => api.get(`company/category/${data.id}`),
  changePosition: ({id, position}) =>
    api.post(`company/widget/${id}/position/change`, {position}),
  deleteCategory: ({id}) => api.delete(`company/category/${id}`),
};

const gate = {
  ...auth,
  ...upload,
  ...company,
  ...FAQ,
  ...widget,
};

export default gate;
