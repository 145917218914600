import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  loggedIn: !!localStorage.getItem('ACCESS_TOKEN'),
  token: null,
  username: '',
  role: null,
  expiresIn: '',
};

const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, {payload}) => {
      const {token, username, role, expiresIn} = payload;
      state.token = token;
      state.username = username;
      state.role = role;
      state.expiresIn = expiresIn;

      state.loggedIn = true;
    },
    logout: (state) => ({
      ...state,
      loggedIn: false,
      token: null,
      username: '',
      role: null,
      expiresIn: '',
    }),
  },
});

export const {login, logout} = userReducer.actions;

export default userReducer.reducer;
