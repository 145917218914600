import React, {useState, useEffect} from 'react';
import Clipboard from 'react-clipboard.js';
import cn from 'classnames';
import {SectionTitle} from './index';

import {copyLinkSVG} from 'assets/svg';

interface UrlSectionProps {
  title: string;
  name: string;
  forwardRef: VoidFunction;
  tooltips?: string;
  getValues: Function;
  watch: string;
}

const UrlSection: React.FC<UrlSectionProps> = ({
  title,
  name,
  forwardRef,
  tooltips,
  getValues,
  watch,
}) => {
  const [popUp, setPopUp] = useState(false);

  // close pop-up
  useEffect(() => {
    if (popUp) {
      setTimeout(() => {
        setPopUp(false);
      }, 1000);
    }
  }, [popUp]);

  // const copyLink = () => {
  //   const value = getValues(name);
  //   // TODO: is there a better way to skip appendChild ?
  //   const textArea = document.createElement('textarea'); // copy command only working on the input element
  //   textArea.value = `https://headstart.work${value}`;
  //   textArea.style.display = 'none';
  //   document.body.appendChild(textArea);
  //   textArea.focus();
  //   textArea.select();

  //   try {
  //     const result = document.execCommand('copy');
  //     result && setPopUp(true);
  //   } catch (e) {
  //     console.error('Oops, unable to copy');
  //   } finally {
  //     // document.body.removeChild(textArea);
  //   }
  // };

  const value = getValues(name);

  const onSuccess = () => {
    setPopUp(true);
  };

  return (
    <div>
      <SectionTitle title={title} tooltips={tooltips} />
      <div className="flex justify-start items-center">
        <p
          className={cn(
            ' font-bold  mr-2',
            (value as string)?.length < 20 ? 'text-lg' : 'text-md',
          )}>
          HEADSTART.WORK{value}
        </p>
        <input
          type="text"
          name={name}
          ref={forwardRef}
          disabled={true}
          hidden
          size={watch?.length}
          className="text-xl 2xl:text-xxl font-bold -mt-1 mb-1 bg-transparent overflow-hidden"
        />
        <Clipboard
          data-clipboard-text={`https://headstart.work${value}`}
          onClick={onSuccess}>
          {/* copy to clipboard */}
          <img
            src={copyLinkSVG}
            alt="copy-link"
            className="w-8 p-2 cursor-pointer"
          />
        </Clipboard>
        <p
          className={`bg-white shadow border rounded-lg p-2 m-2 text-sm font-semibold transition duration-300 ${
            popUp ? 'opacity-100 visible' : 'opacity-0 invisible'
          }`}>
          Copied !
        </p>
      </div>
    </div>
  );
};

export default UrlSection;
