import React from 'react';
import {useDrop} from 'react-dnd';
import {ItemTypes} from './ItemTypes';

const types = Object.values(ItemTypes);

const Category = ({name, children, style}) => {
  const [{canDrop, isOver}, drop] = useDrop({
    accept: types,
    drop: () => ({name}),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  let filter = 'blur(0px)';
  let backgroundColor = 'transparent';
  const isActive = canDrop && isOver;

  if (isActive) {
    filter = 'blur(2px)';
    backgroundColor = '#ffca28';
  }
  // else if (canDrop) {
  //   backgroundColor = 'blue';
  // }

  return (
    <div ref={drop} className="relative" style={{...style, backgroundColor}}>
      <div style={{filter}}>{children}</div>

      {isActive && (
        <div
          className="absolute inset-0 text-xl flex items-center justify-center"
          style={{background: '#ffffff75', zIndex: 1001}}>
          Add to {name}
        </div>
      )}
    </div>
  );
};

export default Category;
