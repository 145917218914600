import {
  FeedItem,
  HorizontalList,
  MainLayout,
  Progress,
  Widgets,
} from 'components';
import React, {FC} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {ReactComponent as Back} from 'assets/svg/backIcon.svg';
import {widgetsObj} from 'pages/users';
import {useQuery} from 'react-query';
import gate from 'gate';
import {QuizType, USER_TYPE, widgetType} from 'interfaces/api/user';
import {capitalize, divide} from 'lodash';
import {getDownloadAddress} from 'utils';
import {profilePlaceholder} from 'assets/images';

const User: FC = () => {
  const {id} = useParams<any>();
  const router = useHistory();
  const {isLoading, data} = useQuery<{data: USER_TYPE}>(
    'user/' + id,
    async () => gate.getUserDetails(id),
  );

  const {
    avatar,
    department,
    email,
    feedbacks,
    firstName,
    lastName,
    progress,
    startTime,
  } = data?.data ?? {};

  const _progress =
    Math.round(
      (Number(progress?.completeItem) / Number(progress?.allItem)) * 100,
    ) || 0;
  const date =
    new Date(startTime ?? new Date())?.getTime() - new Date().getTime();
  const startday = Math.round(date / 86400000) ?? -1;
  return (
    <MainLayout isLoading={isLoading}>
      <div className="w-full text-gray-700 relative flex flex-col">
        <div className="flex absolute mt-5  top-0 left-0 w-full justify-start p-4">
          <Back
            className="-ml-4  cursor-pointer w-8  "
            onClick={() => router.goBack()}
          />
        </div>
        <div className="w-full pl-16 p-4    flex flex-col ">
          <div className="text-5xl p-4">
            {capitalize(firstName) + ' ' + capitalize(lastName)}
          </div>{' '}
          <div className="w-2/3 flex justify-center items-center">
            <img
              // style={{minWidth: 128}}
              className=" w-32 rounded-full mx-4 my-8 whitespace-no-wrap  h-32  bg-black"
              src={avatar ? getDownloadAddress(avatar) : profilePlaceholder}
              alt="..."
            />

            <div className="flex mx-3 flex-col w-1/5 ">
              <div className="text-21">starting Date</div>
              <div className="text-24 font-bold">
                {(startTime as any)?.substr(0, 10)}
              </div>
            </div>

            <div className="flex mx-3 flex-col w-1/5 ">
              <div className="text-21">starts in</div>
              <div className="text-24 font-bold">{startday} days</div>
            </div>
            <div className="w-2/5 mx-3">
              <Progress progressPercent={_progress} forUser={true} />
            </div>
          </div>
          {department?.categories.map(({name, widgets}, index) => (
            <>
              <div className="text-5xl p-4>">{name}</div>{' '}
              {!!widgets.completed.length && (
                <div className="my-10 ">
                  <p className="text-lg medium:text-lg large:text-xl xlarge:text-xxl font-bold">
                    Completed
                  </p>
                  <HorizontalList
                    data={widgets.completed}
                    renderItem={(widgetData: widgetType, index) => (
                      <div className="px-6 py-2">
                        <Widgets {...widgetData} index={index} />
                      </div>
                    )}
                  />
                </div>
              )}
              {!!widgets.notCompleted.length && (
                <div className="my-10 ">
                  <p className="text-lg medium:text-lg large:text-xl xlarge:text-xxl font-bold">
                    Not completed
                  </p>
                  <HorizontalList
                    data={widgets.notCompleted}
                    renderItem={(widgetData: widgetType, index) => (
                      <div className="px-6 py-2">
                        <Widgets {...widgetData} index={index} />
                      </div>
                    )}
                  />
                </div>
              )}
              {!!widgets.pastDueDate.length && (
                <div className="my-10 ">
                  <p className="text-lg medium:text-lg large:text-xl xlarge:text-xxl font-bold">
                    Past due date
                  </p>
                  <HorizontalList
                    data={widgets.pastDueDate}
                    renderItem={(widgetData: widgetType, index) => (
                      <div className="px-6 py-2">
                        <Widgets {...widgetData} index={index} />
                      </div>
                    )}
                  />
                </div>
              )}
              {!!widgets.quizScores.length && (
                <div className="w-2/3 mb-10">
                  <div className="text-5xl  w-1/3 p-4>">Quiz Scores</div>
                  <HorizontalList
                    data={widgets.quizScores}
                    renderItem={({
                      quizCount,
                      trueAnswer,
                      text,
                      title,
                    }: QuizType) => {
                      return (
                        <div className="flex w-64 flex-col">
                          <div className="flex w-full justify-start my-5 ">
                            <div className="  flex font-bold ">{title}</div>
                          </div>
                          <div className="w-full  flex flex-col">
                            <div className="w-full p-3 text-gray-700 text-xs">
                              {`${trueAnswer} out ${quizCount} correct`}
                            </div>
                            <Progress
                              text=""
                              progressPercent={Math.round(
                                (trueAnswer / quizCount) * 100,
                              )}
                            />
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              )}
            </>
          ))}
          <div className="w-1/3">
            {/* <Progress progressPercent={75} /> */}

            <div className="text-5xl mt-4 my-10  whitespace-no-wrap p-4>">
              Feedback Questions
            </div>
            <div className="justify-start items-center">
              {data?.data.feedbacks.map(({feedback, rate}, index: number) => (
                <div key={index} className="my-3">
                  <FeedItem title={feedback.feedback} score={rate} />
                </div>
              ))}
            </div>
            {/* <div className="flex w-full mt-10 justify-start items-baseline flex-col">
              <button className="text-24 font-bold my-3 uppercase">
                reset password
              </button>
              <button className="text-24 font-bold fo my-3 uppercase">
                delete password
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default User;
