import Pages from 'pages';
import URLS from './urls';
import {RouteProps} from 'react-router';

const routes: RouteProps[] = [
  {
    path: URLS.ROUTE_LOGIN,
    component: Pages.Login,
    exact: true,
  },
  {
    path: URLS.ROUTE_LOGIN_FORGETPASS,
    component: Pages.ForgetPass,
    exact: true,
  },
  {
    path: URLS.ROUTE_LOGIN_NEWPASS,
    component: Pages.NewPass,
    exact: true,
  },
  {
    path: URLS.ROUTE_GENERAL,
    component: Pages.GeneralPage,
    exact: true,
  },
  {
    path: URLS.ROUTE_USERS,
    component: Pages.UsersPage,
    exact: true,
  },
  {
    path: URLS.ROUTE_FAQ,
    component: Pages.FAQPage,
    exact: true,
  },
  {
    path: URLS.ROUTE_LAYOUT,
    component: Pages.LayoutPage,
    exact: true,
  },

  {
    path: URLS.ROUTE_LAYOUT_MODIFY_WIDGETS,
    component: Pages.WidgetsPage,
    exact: true,
  },
  {
    path: URLS.ROUTE_USES,
    component: Pages.User,
    exact: true,
  },
];

export default routes;
