import React, {useState, useReducer, useEffect} from 'react';

import {Editor} from 'components';

import WidgetWrapper from './withWidget';
import {useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import gate from 'gate';
// import FileUploader from './withWidget/file-uploader';

const translate = {
  'Editor Caption':
    'Add text and pictures to your widget. We  recommend to use multiple screens for longer texts',
};

const initialData = {
  page: 0,
  data: [
    {
      editableText: '',
      viewableText: '',
    },
  ],
};

function editorsReducer(state = [], action) {
  if (action.type === 'add') {
    const data = [...state.data, ...initialData.data];

    return {page: state.page + 1, data};
  } else if (action.type === 'remove') {
    const state_ = [...state.data];
    const {index} = action.payload;
    const data = state_.filter((d, i) => i !== index);

    const page = data[index] ? index : index - 1;

    return {page, data};
  } else if (action.type === 'onChangeText') {
    const {page, editableText, viewableText, id} = action.payload;
    const data = [...state.data];
    if (id) {
      data[page] = {editableText, viewableText, id};
    } else {
      data[page] = {editableText, viewableText};
    }

    return {...state, data};
  } else if (action.type === 'page') {
    const {page} = action.payload;
    return {...state, page};
  } else if (action.type === 'edit') {
    const data = action.payload ?? [];
    // const page = data.length - 1 === 0 ? 0 : data.length - 1;
    const page = data.length - 1;
    return {page, data};
  } else {
    return state;
  }
}

const EditorWidget = () => {
  // const [page, setPage] = useState(0);
  const [widgetMeta, widgetMetaDispatch] = useReducer(
    editorsReducer,
    initialData,
  );
  const [widgetMeta_, setWidgetMeta_] = useState(initialData.data);
  const [_id, setId] = useState(null);
  const {action, id} = useParams();
  const {data: _data, isLoading} = useQuery(
    `widget/${id}`,
    async () => gate.viewWidget(id),
    {enabled: action === 'edit'},
  );
  useEffect(() => {
    if (_data?.data) {
      setId(_data?.data?.content?.[0]?.id ?? null);
      widgetMetaDispatch({payload: _data?.data?.content, type: 'edit'});
    }
  }, [isLoading]);

  useEffect(() => {
    setId(widgetMeta?.data[widgetMeta.page]?.id ?? null);
  }, [widgetMeta.page]);
  useEffect(() => {
    setWidgetMeta_(widgetMeta.data);
  }, [widgetMeta]);

  const _getWidgetMeta = () => {
    return widgetMeta.data;
  };

  return (
    <WidgetWrapper
      widgetData={widgetMeta_}
      widgetType={'Editor'}
      page={widgetMeta.page}>
      <div className="w-136">
        <div>{translate['Editor Caption']}</div>
        <div className="mt-2 w-full h-160">
          <Editor
            // page={page}
            id={_id}
            page={widgetMeta.page}
            widgetMeta={widgetMeta.data}
            getWidgetMeta={_getWidgetMeta}
            widgetMetaDispatch={widgetMetaDispatch}
          />
        </div>
      </div>
    </WidgetWrapper>
  );
};
export default EditorWidget;
