import React from 'react';

import {Progress} from 'components';
import {getDownloadAddress} from 'utils';
import {Link} from 'react-router-dom';

export interface UserProps {
  item: USER_TYPE;
}

type USER_TYPE = {
  imgUrl?: string;
  name?: string;
  firstName: string;
  lastName: string;
  id?: string;
  avatar: string | null;
};
//Todo refactoring
const NOT_FOUND =
  'https://safetyaustraliagroup.com.au/wp-content/uploads/2019/05/image-not-found.png';

const User: React.FC<UserProps> = ({item, onClick = () => {}}) => {
  const {avatar} = item;
  return (
    <Link
      to={'/user/' + item?.id}
      className="flex justify-between items-center p-3 w-full"
      // style={{height: 80}}
      // onClick={() => onClick(item?.id)}
    >
      <div className="flex items-center w-full ">
        <img
          src={avatar ? getDownloadAddress(avatar) : NOT_FOUND}
          className="w-14 h-14 mr-4 rounded-full border-none bg-gray-400"
          alt="..."
        />
        <div className="font-bold text-24 overflow-hidden">
          {item?.firstName + ' ' + item?.lastName}
        </div>
      </div>
      {/* <Progress progressPercent={item?.progress || 0} /> */}
    </Link>
  );
};

export default User;
