import React from 'react';
import {ReactComponent as TrashIcon} from 'assets/svg/trash-filled.svg';
export interface TodoItemProps {
  text?: string;
  id?: number;
  index: number;
  onBlur: Function;
  onDelete: Function;
  onChange: Function;
  edit?: boolean;
}

const TodoItem: React.FC<TodoItemProps> = ({
  onChange,
  index,
  id,
  onDelete,
  onBlur,
  text,
  edit,
}) => {
  return (
    <>
      <div className="flex animate-opacity justify-between my-2 items-center">
        <textarea
          onChange={(e) => onChange(e.target.value, index, id)}
          value={text}
          onBlur={() => onBlur()}
          className="cursor-pointer px-2 w-full py-3 border border-gray-400 rounded-lg  text-sm"
        />

        <button
          onClick={(e) => {
            e.preventDefault();
            onDelete(text);
          }}
          className="ml-5">
          <TrashIcon />
        </button>
      </div>
    </>
  );
};

export default TodoItem;
