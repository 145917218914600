import React, {FC} from 'react';
interface Props {
  url: string;
}

const PDFReader: FC<Props> = ({url}) => {
  return (
    <iframe
      src={`https://docs.google.com/viewer?url=${url}&embedded=true`}
      frameBorder="0"
      width="100%"
      className="min-h-screen"></iframe>
  );
};

export default PDFReader;
