import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';

import arrayMove from 'array-move';

import {MainLayout} from 'components';
import Section from 'components/scrollableItem';
import RightNavbar from 'components/MasterLayout/sidebar/RightNavBar';

import TODOSVG from 'assets/svg/todo.svg';
import PDFSVG from 'assets/svg/pdf.svg';
import EDITORSVG from 'assets/svg/editor.svg';
import GALLERYSVG from 'assets/svg/media.svg';
import MEDIASVG from 'assets/svg/media.svg';
import QUIZSVG from 'assets/svg/question.svg';
import WEBSVG from 'assets/svg/web.svg';
import VOICESVG from 'assets/svg/voice.svg';

import classes from './layout.module.css';
import urls from 'providers/routes-provider/routes/urls';
import replaceSlugFromLink from 'helpers/replaceSlugFromLink';
import {useQuery} from 'react-query';
import gate from 'gate';

import {CreateWidgetModal} from 'components/Modal';
import {useDispatch} from 'react-redux';
import {setTargetCategory} from 'store/reducers/company';
import {selectActiveDepartment} from 'store/selectors/companySelector';

import ModifyCategporyModal from 'components/Modal/ModifyCategoryModal';
import DeleteWidgetModal from 'components/Modal/DeleteWidgetModal';

import Icon from './Icon';
import Category from './Category';
import {getDownloadAddress} from 'utils';

// export interface LayoutPageProps {}

const translate = {
  'drag-and-drop widget':
    'Drag and drop widgets below in the preview screen to add sections to your app',
  widget: 'Widgets',
};

const icons = [
  {name: 'PDF', src: PDFSVG},
  {name: 'MEDIA', src: MEDIASVG},
  {name: 'QUIZ', src: QUIZSVG},
  {name: 'GALLERY', src: GALLERYSVG},
  {name: 'TEXT', src: EDITORSVG},
  {name: 'URL', src: WEBSVG},
  {name: 'TODO', src: TODOSVG},
  {name: 'AUDIO', src: VOICESVG},
];

const LayoutPage = () => {
  const [items, itemsSet] = useState();
  const [modal, modalSet] = useState({
    CreateWidgetModal: {isOpen: false},
    ModifyCategporyModal: {isOpen: false, activeCategory: undefined},
    DeleteWidgetModal: {isOpen: false, activeWidget: undefined},
  });
  const [selectedWidgetType, selectedWidgetTypeSet] = useState();

  const history = useHistory();
  const dispatch = useDispatch();
  const {id: departmentId, mainColor} = useSelector(selectActiveDepartment);

  const {isLoading, data, clear, refetch} = useQuery(
    'layout',
    gate.getAllCategories,
  );

  React.useEffect(() => {
    if (data)
      itemsSet(
        data?.data.map((eachCategory) => ({
          ...eachCategory,
          categoryWidgets: eachCategory.categoryWidgets.map((eachWidget) => ({
            ...eachWidget,
            isLoading: false,
          })),
          isCategoryLoading: false,
        })),
      );
  }, [data]);

  const onDrop = ({position, categoryId}) => {
    if (position.oldIndex !== position.newIndex) {
      //Send this to BE
      const targetWidget = items
        .filter((eachCategory) => eachCategory.id == categoryId)[0]
        .categoryWidgets.filter(
          (eachWidget, id) => id === position.oldIndex,
        )[0];

      const targetWidgetonLastPosition = items
        .filter((eachCategory) => eachCategory.id == categoryId)[0]
        .categoryWidgets.filter(
          (eachWidget, id) => id === position.newIndex,
        )[0];

      gate
        .changePosition({
          position: position.newIndex,
          id: targetWidget.id,
        })
        .then(() => {
          refreshList();
        });

      itemsSet((lastItem) =>
        lastItem.map((eachCategory) => ({
          ...eachCategory,
          categoryWidgets:
            eachCategory.id === categoryId
              ? arrayMove(
                  eachCategory.categoryWidgets.map((eachWidget, id) => ({
                    ...eachWidget,
                    // isLoading: position.newIndex == id || position.oldIndex == id,
                    isLoading: position.oldIndex == id,
                  })),
                  position.oldIndex,
                  position.newIndex,
                )
              : eachCategory.categoryWidgets,
        })),
      );

      refreshList();
    }
  };

  const handleEditCategory = ({id}) => {
    modalSet((lastModalState) => ({
      ...lastModalState,
      ModifyCategporyModal: {
        ...lastModalState.ModifyCategporyModal,
        isOpen: true,
        activeCategory: id,
      },
    }));
  };

  const refreshList = () => {
    setTimeout(() => {
      refetch();
    }, 1000);
  };

  const handleOnRemoveWidget = ({itemId}) => {
    modalSet((lastModalState) => ({
      ...lastModalState,
      DeleteWidgetModal: {
        ...lastModalState.DeleteWidgetModal,
        isOpen: true,
        activeWidget: itemId,
      },
    }));
    return false;
  };

  const removeItem = ({itemId}) => {
    gate.deleteWidget(itemId);

    /* itemsSet((lastState) =>
      lastState.map((eachCategory) => ({
        ...eachCategory,
        categoryWidgets: eachCategory.categoryWidgets.filter(
          (eachWidget) => eachWidget.id !== itemId && eachWidget,
        ),
      })),
    ); */

    itemsSet((lastState) =>
      lastState.map((eachCategory) => ({
        ...eachCategory,
        categoryWidgets: eachCategory.categoryWidgets.map((eachWidget) =>
          eachWidget.id === itemId
            ? {...eachWidget, isLoading: true}
            : eachWidget,
        ),
      })),
    );

    refreshList();
  };

  const addSection = () => {
    modalSet((lastModalState) => ({
      ...lastModalState,
      ModifyCategporyModal: {
        isOpen: true,
        activeCategory: false,
      },
    }));
  };

  const handleOnIconClick = (widgetType) => {
    selectedWidgetTypeSet(widgetType);
    modifyCreateWidgetModal(true);
  };

  const handleOnIconAdd = (type, category) => {
    const {id: categoryId} = items_.find((i) => i.name === category);

    dispatch(setTargetCategory(categoryId));
    if (type === 'TOOD') {
      return history.push('/layout/To-Do-widget/' + categoryId);
    }
    // console.log('selectedWidgetType:', selectedWidgetType);
    history.push(
      replaceSlugFromLink(urls.ROUTE_LAYOUT_MODIFY_WIDGETS, {
        widgetName: type.toLowerCase(),
        action: 'create',
      }),
    );
  };

  const handleSubmitCreateWidgetModal = (selectedCat) => {
    dispatch(setTargetCategory(selectedCat));
    // if (selectedWidgetType === 'TOOD') {
    //   return history.push('/layout/To-Do-widget/' + selectedCat);
    // }
    console.log(selectedCat, selectedWidgetType);
    history.push(
      replaceSlugFromLink(urls.ROUTE_LAYOUT_MODIFY_WIDGETS, {
        widgetName: selectedWidgetType.toLowerCase(),
        action: 'create',
      }),
    );
  };

  const handleCloseModal = () => {
    modifyCreateWidgetModal(false);
    selectedWidgetTypeSet(undefined);
    refreshList();
  };

  // Modal

  const modifyCreateWidgetModal = (e) => {
    modalSet((lastModalData) => ({
      ...lastModalData,
      CreateWidgetModal: {...lastModalData.CreateWidgetModal, isOpen: e},
      ModifyCategporyModal: {isOpen: false},
      DeleteWidgetModal: {isOpen: false},
    }));
  };

  const items_ = items?.filter((i) => i.departmentId === departmentId) || [];
  const data_ =
    data?.data?.filter((d) => d.departmentId === departmentId) || [];

  return (
    <MainLayout isLoading={isLoading}>
      <DndProvider backend={HTML5Backend}>
        <div className="overflow-y-visible overflow-x-scroll hide-scrollbar">
          {items_ ? (
            items_.map((eachSection, key) => {
              return (
                <Category
                  key={`d_${key}`}
                  name={eachSection.name}
                  style={{
                    padding: '0 10px 20px',
                    borderRadius: 10,
                  }}>
                  <Section
                    axis="x"
                    key={key}
                    items={eachSection.categoryWidgets}
                    title={eachSection.name}
                    deleteItem={handleOnRemoveWidget}
                    onSortEnd={(e) =>
                      onDrop({position: e, categoryId: eachSection.id})
                    }
                    id={eachSection.id}
                    handleEditCategory={handleEditCategory}
                    mainColor={mainColor}
                  />
                </Category>
              );
            })
          ) : (
            <h2 className="text-2xl font-bold">Create Your First Category</h2>
          )}
          <div onClick={addSection} className={classes['add-item']}>
            +
          </div>
          {/* <New /> */}
        </div>
        <RightNavbar>
          <div className="w-100 opacity-0 m-5 ml-2" />
          <div className="fixed top-0 bottom-0 right-0 z-10 w-100 rounded-5xl bg-opacity-50 bg-white shadow-sidebar min-h-1/4 px-8 my-5 pt-12 mr-5 ml-2">
            <p className="text-2xl">{translate['drag-and-drop widget']}</p>
            <h2 className="font-bold mt-8 text-2xl">
              {translate.widget.toUpperCase() + ':'}
            </h2>
            <div className="flex justify-start items-start flex-row flex-wrap">
              {icons.map((eachIcon) => (
                <Icon
                  key={eachIcon.name}
                  name={eachIcon.name}
                  onAdd={handleOnIconAdd}
                  onClick={handleOnIconClick}
                  className="bg-white select-none cursor-pointer transition ease-in-out duration-200 bg-opacity-25 rounded-2xl w-24 h-24 p-2 mx-2 my-4 flex justify-end items-center flex-col hover:bg-opacity-100">
                  <div className="h-12 flex justify-center items-center">
                    <img
                      src={getDownloadAddress(eachIcon.src)}
                      alt={eachIcon.name}
                      style={{maxWidth: 30}}
                    />
                  </div>
                  <p className="pt-2">{eachIcon.name === 'TODO' ? 'TO-DO' : eachIcon.name}</p>
                </Icon>
              ))}
            </div>
          </div>
        </RightNavbar>
      </DndProvider>

      <CreateWidgetModal
        categories={data_}
        onSelected={handleSubmitCreateWidgetModal}
        isLoading={isLoading}
        onClose={handleCloseModal}
        active={modal.CreateWidgetModal.isOpen}
      />
      <ModifyCategporyModal
        onClose={handleCloseModal}
        id={modal.ModifyCategporyModal.activeCategory}
        active={modal.ModifyCategporyModal.isOpen}
      />
      <DeleteWidgetModal
        onClose={handleCloseModal}
        id={modal.DeleteWidgetModal?.activeWidget}
        onDelete={removeItem}
        active={modal.DeleteWidgetModal?.isOpen}
      />
    </MainLayout>
  );
};

export default LayoutPage;
