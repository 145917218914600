import React, {useState} from 'react';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {useHistory} from 'react-router-dom';

import {useIntersectionObserver} from 'hooks/useIntersectionObserver';
import addUser from 'assets/svg/addUser.svg';
import {
  AddUser,
  Dropdown,
  FeedItem,
  HorizontalList,
  MainLayout,
  Progress,
  Spinner,
  User,
  Widgets,
} from 'components';
import menuVertical from 'assets/svg/menuVertical.svg';
import gate from 'gate';
import {
  selectActiveDepartment,
  selectCompany,
  selectDepartments,
} from 'store/selectors/companySelector';
import {ReactComponent as Back} from 'assets/svg/backIcon.svg';

import ColleagueItem from './ColleagueItem';
import FeedbackDropDown from 'components/users/feedbackDropDown';
import ColleagueDropDown from './ColleagueDropdown';
import AddColleagueDropDown from './AddColleagueDropdown';
import {getDownloadAddress} from 'utils';
import BaseModal from 'components/Modal/BaseModal';
import {useInView} from 'react-intersection-observer';
type paginationDetailsType = {
  page: number;
  pageCount: number | null;
};
//TODO refactor
export interface UsersPageProps {}
export interface widgetsObj {
  viewCount: number | string;
  title: string;
  imgSrc?: string;
}
export interface userOptionObj {
  show: boolean;
  key: number;
}

const UsersPage: React.FC<UsersPageProps> = () => {
  const [selectedUser, setSelectedUser] = useState<{id: string}>();
  const [text, setText] = useState('');
  const {id: departmentID} = useSelector(selectActiveDepartment);
  const {id: companyID} = useSelector(selectCompany);
  const [users, setUsers] = useState<any[]>([]);
  const departments = useSelector(selectDepartments);
  const router = useHistory();

  const [{page, pageCount}, setPageDetails] = useState<paginationDetailsType>({
    page: 1,
    pageCount: null,
  });

  const cache = useQueryClient();
  const {
    mutate: retrieveUser,
    isLoading: isLoadingUser,
    data: userData,
  } = useMutation(
    async (id) => {
      try {
        const res = await gate.getCompanyUser(id);
        return res?.data;
      } catch (e) {
        console.log(e);
      }
    },
    {
      onSuccess: () => {
        // toast.success('User Added Successfully');
      },
    },
  );
  const {mutate: deleteUser, isLoading: isLoadingDeleteUser} = useMutation(
    async (id: number) => {
      try {
        const res = await gate.deleteCompanyUserById({id});
        toast.success('User Deleted Successfully');
        cache.invalidateQueries('getCompanyUsers');
      } catch (e) {
        console.log(e);
      }
    },
    {
      onSuccess: () => {
        // toast.success('User Added Successfully');
      },
    },
  );
  const {
    data: colleagues,
    refetch: refetchColleagues,
    isLoading: isLoadingColleague,
  } = useQuery('getCompanyUserColleague', () => gate.getCompanyUserColleague());

  const {fetchNextPage, data, isFetching} = useInfiniteQuery(
    'getCompanyUsers',
    async ({pageParam = 0}) => await gate.getCompanyUsers(pageParam, text),
    {
      getNextPageParam: (lastPage: any, allPage) => {
        console.log({lastPage});

        const {page: _page, pageCount} = lastPage?.data ?? {};
        if (page && page !== _page)
          setPageDetails({
            page: _page,
            pageCount,
          });
      },
      // retry: false,
    },
  );
  console.log('data:', data);
  const {data: mostViewedWidgets, isLoading: isLoadingMostViewedWidget} =
    useQuery(
      ['mostViewedWidget', departmentID],
      () => gate.getCompanyMostWidget(departmentID),

      {
        enabled: !!departmentID,
      },
    );
  const {data: feedbackScores, isLoading: isLoadingFeedbackScores} = useQuery(
    'getCompanyFeedbackScores',
    () => gate.getCompanyFeedbackScores(),
  );

  const {data: leastViewedWidgets, isLoading: isLoadingLeastViewedWidget} =
    useQuery(
      ['leastViewedWidget', departmentID],

      () => gate.getCompanyLeastWidget(departmentID),
      {
        enabled: !!departmentID,
      },
    );

  const [visible, setVisible] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);

  const [id, setId] = useState(0);
  const [feedbackDropDown, setFeedbackDropDownVisible] =
    useState<boolean>(false);
  const [colleagueDropDown, setColleagueDropDownVisible] =
    useState<boolean>(false);
  const [addColleagueDropDown, setAddColleagueDropDownVisible] =
    useState<boolean>(false);

  const [userOptionShow, setUserOptionShow] = useState<userOptionObj>({
    key: 0,
    show: false,
  });

  const lastDivRef = React.useRef<any>(null);
  const {ref, inView, entry} = useInView({
    /* Optional options */
    threshold: 0,
    onChange(inView, entry) {
      if (!isFetching) {
        let currentPage = page;

        if (pageCount) {
          if (currentPage >= pageCount) return;
        }

        fetchNextPage({pageParam: currentPage + 1});
      }
    },
  });

  const _colleagues = colleagues?.data?.filter(
    (i: any) => typeof i.deletedAt !== 'string',
  );

  const isUserNameContainSearchedText = (user: any) =>
    user.firstName.toLowerCase().includes(text.toLowerCase()) ||
    user.lastName.toLowerCase().includes(text.toLowerCase());
  const _users = users?.filter((i: any) => {
    return typeof i.deletedAt !== 'string' && isUserNameContainSearchedText(i);
  });

  return (
    <MainLayout
      isLoading={
        isLoadingColleague ||
        isLoadingFeedbackScores ||
        isLoadingLeastViewedWidget ||
        isLoadingMostViewedWidget
      }>
      <div className="grid grid-cols-10 min-h-screen">
        <div className="col-span-5 flex pr-5 flex-col">
          <div className="grid grid-cols-3">
            <div className="col-span-2">
              <input
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Search user"
                className="border border-gray-300 py-4 w-full rounded-full text-base px-3"
              />
            </div>
            <div
              onClick={() => setVisible(!visible)}
              className="cursor-pointer flex justify-center items-center">
              <div className="text-sm lg:text-md font-bold">ADD USER</div>
              <img className="ml-2 w-6 h-6 " src={addUser} alt="..." />
            </div>
            <Dropdown
              style={{top: 0, left: 157}}
              visible={visible}
              className="shadow-lg border border-gray-200  w-max-content rounded-3xl"
              onClose={() => setVisible(!visible)}>
              <div className="w-400 xlarge:w-720 flex flex-col justify-start items-center p-8 border-gray-300 bg-white">
                <div className="w-full pb-8 text-md medium:text-md large:text-lg xlarge:text-xl font-bold">
                  Add User
                </div>
                <AddUser
                  departmentId={departmentID}
                  onAdd={() => {
                    setUsers([...users]);
                  }}
                />
              </div>
            </Dropdown>

            <Dropdown
              full
              // style={{top: 0, left: 157}}
              visible={feedbackDropDown}
              className="w-1/2 shadow-lg border border-gray-200 rounded-3xl"
              onClose={() => setFeedbackDropDownVisible(!feedbackDropDown)}>
              <div className="xlarge:w-720 flex flex-col justify-start items-center p-8 border-gray-300 bg-white">
                <FeedbackDropDown
                  questions={feedbackScores?.data}
                  companyId={companyID}
                />
              </div>
            </Dropdown>

            <Dropdown
              // style={{top: 0, left: 157}}
              visible={colleagueDropDown}
              className="shadow-lg border border-gray-200  w-max-content rounded-3xl"
              onClose={() => setColleagueDropDownVisible(!colleagueDropDown)}>
              <div className="w-700 xlarge:w-720 flex flex-col justify-start items-center p-10 border-gray-300 bg-white">
                <div className="w-full pb-8 text-md medium:text-md large:text-lg xlarge:text-xl font-bold">
                  Colleagues
                </div>
                <ColleagueDropDown
                  colleagues={_colleagues}
                  companyId={companyID}
                />
              </div>
            </Dropdown>
            <Dropdown
              visible={addColleagueDropDown}
              className="shadow-lg border border-gray-200  w-max-content rounded-3xl"
              onClose={() =>
                setAddColleagueDropDownVisible(!addColleagueDropDown)
              }>
              <div className="w-700 xlarge:w-720 flex flex-col justify-start items-center p-10 border-gray-300 bg-white">
                <div className="w-full pb-8 text-md medium:text-md large:text-lg xlarge:text-xl font-bold">
                  Add Colleague
                </div>
                <AddColleagueDropDown
                  onDone={() => {
                    setAddColleagueDropDownVisible(!addColleagueDropDown);
                    refetchColleagues();
                  }}
                />
              </div>
            </Dropdown>
          </div>
          <div className="flex flex-col  justify-between flex-1 flex-grow h-full">
            {false && (
              <div className="flex-1">
                <div className="mt-10 mb-5 w-full text-lg medium:text-lg large:text-xl xlarge:text-xxl font-bold">
                  Active users
                </div>
                <div
                  className=" overflow-y-auto  flex flex-col "
                  style={{height: 400}}>
                  {_users
                    .filter((i: any) => i.isActive)
                    .map((item: any) => {
                      return (
                        <div key={item.id} className="flex w-full h-22">
                          <div className="flex  w-full justify-between items-center ">
                            <User item={item} />
                            <div className="flex justify-center items-center  ">
                              <img
                                onClick={() =>
                                  setUserOptionShow({key: item.id, show: true})
                                }
                                className="mt-6 large:ml-2 xlarge:ml-4 ml-2 p-4 cursor-pointer h-8 w-88"
                                src={menuVertical}
                                alt="..."
                              />
                            </div>
                          </div>
                          <Dropdown
                            visible={
                              userOptionShow.show &&
                              item.id === userOptionShow.key
                            }
                            onClose={() =>
                              setUserOptionShow({
                                key: 0,
                                show: false,
                              })
                            }
                            className="bg-white  shadow-xl rounded-lg absolute right-0">
                            <div className="flex flex-col py-4 w-64">
                              {/* <div className="cursor-pointer transition-all duration-300 hover:bg-gray-100 hover:shadow-md px-5 py-1">
                                VIEW STATS
                              </div> */}
                              {/* <div className="cursor-pointer  transition-all duration-300 hover:bg-gray-100 hover:shadow-md px-5 py-1">
                                APPS ACCESS
                              </div> */}
                              {/* <div className="cursor-pointer  transition-all duration-300 hover:bg-gray-100 hover:shadow-md px-5 py-1">
                                RESET PASSWORD
                              </div> */}
                              <div className="cursor-pointer  transition-all duration-300 hover:bg-gray-100 hover:shadow-md px-5 py-1 ">
                                DELETE USER
                              </div>
                            </div>
                          </Dropdown>
                        </div>
                      );
                    })}
                  <button
                  // onClick={fetchUser}
                  // disabled={!hasNextPage || isFetchingNextPage}
                  >
                    {/* {isFetchingNextPage
                      ? 'Loading more...'
                      : hasNextPage
                      ? ''
                      : 'Nothing more to load'} */}
                  </button>
                </div>
              </div>
            )}
            <div className="flex-1">
              <div className="mt-10 mb-5 w-full text-lg medium:text-lg large:text-xl xlarge:text-xxl font-bold">
                All Users
              </div>

              <div className=" overflow-scroll" style={{height: 800}}>
                {data?.pages?.map?.((page) => (
                  <React.Fragment key={page.nextId}>
                    {page?.data?.data?.map((item: any) => {
                      const progress =
                        Math.round(
                          (Number(item?.progress?.completeItem) /
                            Number(item?.progress?.allItem)) *
                            100,
                        ) || 0;

                      if (isUserNameContainSearchedText(item))
                        return (
                          <li key={item.id} className="flex  w-full py-2">
                            <div className="flex  w-full justify-between items-center ">
                              <User item={item} />
                              <div className="flex w-full justify-center items-center  ">
                                <Progress progressPercent={progress} />
                                <img
                                  onClick={() => {
                                    setUserOptionShow({
                                      key: item.id,
                                      show: true,
                                    });
                                  }}
                                  className="mt-6 large:ml-2 xlarge:ml-4 ml-2 p-2 cursor-pointer h-8 w-8"
                                  src={menuVertical}
                                  alt="..."
                                />
                              </div>
                            </div>
                            <Dropdown
                              visible={
                                userOptionShow.show &&
                                item.id === userOptionShow.key
                              }
                              onClose={() =>
                                setUserOptionShow({
                                  key: 0,
                                  show: false,
                                })
                              }
                              className="bg-white  shadow-xl rounded-lg absolute right-0">
                              <div className="flex flex-col py-4 w-64">
                                <div
                                  className="cursor-pointer transition-all duration-300 hover:bg-gray-100 hover:shadow-md px-5 py-1"
                                  onClick={() => {
                                    router.push(`/user/${item.id}`);
                                  }}>
                                  VIEW STATS
                                </div>
                                {/* <div className="cursor-pointer  transition-all duration-300 hover:bg-gray-100 hover:shadow-md px-5 py-1">
                                  APPS ACCESS
                                </div> */}
                                {/* <div
                                  className="cursor-pointer  transition-all duration-300 hover:bg-gray-100 hover:shadow-md px-5 py-1"
                                  onClick={() => {}}>
                                  RESET PASSWORD
                                </div> */}
                                <div
                                  className="cursor-pointer  transition-all duration-300 hover:bg-gray-100 hover:shadow-md px-5 py-1 "
                                  onClick={() => {
                                    setId(item.id);
                                    setOpen(true);
                                  }}>
                                  DELETE USER
                                </div>
                              </div>
                            </Dropdown>
                          </li>
                        );
                    })}
                  </React.Fragment>
                ))}

                <div ref={ref} className="w-full  h-6 "></div>

                {isFetching && (
                  <div
                    // ref={div}
                    className="w-full flex justify-center items-center">
                    <Spinner className="w-16 h-16" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-4">
          <div className="mb-5">
            <div className="flex flex-row justify-between items-center">
              <p className="text-lg medium:text-lg large:text-xl xlarge:text-xxl font-bold">
                Most viewed Widgets
              </p>
            </div>
            <HorizontalList
              data={mostViewedWidgets?.data}
              renderItem={(data, index) => (
                <div className="p-5 xl:p-6">
                  <Widgets {...data} index={index} />
                </div>
              )}
            />
          </div>
          <div className="mb-5">
            <p className="text-lg medium:text-lg large:text-xl xlarge:text-xxl font-bold">
              Least viewed Widgets
            </p>
            <HorizontalList
              data={leastViewedWidgets?.data}
              renderItem={(data, index) => (
                <div className="p-5 xl:p-6">
                  <Widgets {...data} index={index} />
                </div>
              )}
            />
          </div>

          <div className="mb-5">
            <div className="flex flex-row justify-between items-center">
              <p className="text-lg medium:text-lg large:text-xl xlarge:text-xxl font-bold">
                Feedback scores
              </p>
              <p
                className="text-l cursor-pointer medium:text-l font-bold large:text-l xlarge:text-l "
                onClick={() => setFeedbackDropDownVisible(true)}>
                edit
              </p>
            </div>
            {/* <HorizontalList
              data={feedbackScores?.data}
              renderItem={(item) => (
                <div className="p-2">
                  <FeedItem title={item.feedback} score={item.rate} />
                </div>
              )}
            /> */}
            {feedbackScores?.data
              ?.filter((i: any) => typeof i.deletedAt !== 'string')
              ?.map((item: any) => (
                <div className="p-2">
                  <FeedItem title={item.feedback} score={item.rate} />
                </div>
              ))}
          </div>

          <div className="mb-5">
            <div className="flex flex-row justify-between items-center">
              <p className="text-lg medium:text-lg large:text-xl xlarge:text-xxl font-bold">
                Colleagues
              </p>
              <button
                className="flex items-center"
                onClick={() => {
                  setAddColleagueDropDownVisible(!addColleagueDropDown);
                }}>
                <p className="text-lg medium:text-lg large:text-xl xlarge:text-xxl font-bold mr-4">
                  Add Colleagues
                </p>
                <img className=" w-6 h-6 " src={addUser} alt="..." />
              </button>
              <button
                className="text-l  medium:text-l font-bold large:text-l xlarge:text-l "
                onClick={() => {
                  setColleagueDropDownVisible(!colleagueDropDown);
                }}>
                edit
              </button>
            </div>
            <HorizontalList
              data={_colleagues}
              renderItem={(item) => (
                <div className="p-2">
                  <ColleagueItem item={item} />
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <BaseModal active={open} onClose={() => setOpen(false)}>
        <div
          className="flex flex-col justify-center items-center text-center
        font-semibold overflow-hidden w-136 bg-white
        rounded-2xl shadow-modal border-1 pt-12 pb-6 px-16">
          <p className="text-xl pt-8 pb-16">Are you sure</p>
          <div className="flex justify-around items-center w-full h-12">
            <button
              type="submit"
              onClick={() => deleteUser(id as number)}
              className="text-lg font-semibold p-4 cursor-pointer">
              YES
            </button>
            <button
              onClick={() => setOpen(false)}
              className="text-lg font-semibold p-4 text-red-600 cursor-pointer">
              CANCEL
            </button>
          </div>
        </div>
      </BaseModal>
    </MainLayout>
  );
};

export default UsersPage;
