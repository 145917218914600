import React from 'react';
import 'keen-slider/keen-slider.min.css';
import 'cropperjs/dist/cropper.css';
import {
  AuthProvider,
  ReactQueryProvider,
  ReduxProvider,
  RouterProvider,
  RoutesProvider,
  ToastProvider,
} from 'providers';

function App() {
  return (
    <ReduxProvider>
      <ReactQueryProvider>
        <RouterProvider>
          <AuthProvider>
            <RoutesProvider>
              <ToastProvider />
            </RoutesProvider>
          </AuthProvider>
        </RouterProvider>
      </ReactQueryProvider>
    </ReduxProvider>
  );
}

export default App;
