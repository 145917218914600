import React, {useState} from 'react';

import {Dropdown} from 'components';

import {EditorTextSVG, EditorArrowDownSVG} from 'assets/svg';

const Btn = ({style, title, onClick, type}) => {
  const _onClick = (e) => {
    e.preventDefault();

    onClick(style, type);
  };

  if (style === 'header-one') {
    return (
      <h1 className="text-black text-4xl font-normal" onMouseDown={_onClick}>
        {title}
      </h1>
    );
  } else if (style === 'header-two') {
    return (
      <h2 className="text-black text-2xl font-normal" onMouseDown={_onClick}>
        {title}
      </h2>
    );
  } else if (style === 'header-three') {
    return (
      <h3 className="text-black text-lg" onMouseDown={_onClick}>
        {title}
      </h3>
    );
  } else if (style === 'unstyled') {
    return (
      <p className="text-black" onMouseDown={_onClick}>
        {title}
      </p>
    );
  }
};

const SidebarDropdown = ({types, onChoose, typography}) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const handleShowDropDown = () => {
    setShowDropDown((prevState) => !prevState);
  };

  return (
    <div
      style={{minWidth: 120}}
      onClick={handleShowDropDown}
      className="RichEditor-styleButton cursor-pointer">
      <div
        className="flex justify-around items-center w-full h-12 rounded-2xl px-2"
        style={{backgroundColor: 'rgba(216, 216, 216, 1)'}}>
        <img src={EditorTextSVG} alt="" className="h-8 p-1" />
        <div className="text-black min-h-0 text-xs">Default Text</div>
        <img src={EditorArrowDownSVG} alt="-" className="w-3 h-2" />
      </div>
      <Dropdown
        visible={showDropDown}
        onClose={handleShowDropDown}
        // offsetY="3rem"
        // heightClassName="h-10"
        className="overflow-y-scroll w-52 bg-white shadow-dropdown rounded-2xl">
        <div
          style={{
            width: '13rem',
            background: '#F3F3F3',
            padding: '7px 5px 25px 18px',
          }}>
          {types.map((type) => (
            <Btn
              key={type.style}
              type={type.type}
              style={type.style}
              title={type.title}
              onClick={onChoose}
            />
          ))}
        </div>
      </Dropdown>
    </div>
  );
};

export default React.memo(SidebarDropdown);
