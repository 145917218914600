export default function replaceSlugFromLink(route: string, params: { [key: string]: string }): string {
    return route
        .split('/')
        .map((eachSubRoute: string) => {
            if (eachSubRoute[0] === ':') {
                return params[eachSubRoute.slice(1, eachSubRoute[eachSubRoute.length - 1] !== "?" ? eachSubRoute.length : eachSubRoute.length - 1)];
            }
            return eachSubRoute;
        })
        .join('/');
}