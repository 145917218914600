import React from 'react';
import {Redirect, useParams} from 'react-router-dom';

import urls from 'providers/routes-provider/routes/urls';

import PDF_WIDGET from './Pdf';
import URL_WIDGET from './Url';
import QUIZ_WIDGET from './Quiz';
import EDITOR_WIDGET from './Editor';
import MEDIA_WIDGET from './Media';
import GALLERY_WIDGET from './Gallery';
import TODO_WIDGET from './ToDo';
import AUDIO_WIDGET from './Audio';

const widgets: any = {
  PDF: PDF_WIDGET,
  URL: URL_WIDGET,
  QUIZ: QUIZ_WIDGET,
  TEXT: EDITOR_WIDGET,
  MEDIA: MEDIA_WIDGET,
  GALLERY: GALLERY_WIDGET,
  TODO: TODO_WIDGET,
  AUDIO: AUDIO_WIDGET,
};

export default function Widgets() {
  const {widgetName} = useParams<{widgetName: string}>();

  return (
    (widgets[widgetName.toUpperCase()] &&
      widgets[widgetName.toUpperCase()]()) || (
      <Redirect to={urls.ROUTE_GENERAL as string} />
    )
  );
}
