import React from 'react';

import BaseModal from './BaseModal';

interface DeleteModalProps {
  active: boolean;
  onClose: VoidFunction;
  onDelete: VoidFunction;
  departmentName: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  onClose,
  active,
  onDelete,
  departmentName,
}) => {
  return (
    <BaseModal active={active} onClose={onClose}>
      <div
        className="flex flex-col justify-center items-center text-center
        font-semibold overflow-hidden w-136 bg-white
        rounded-2xl shadow-modal border-1 pt-12 pb-6 px-16">
        <p className="text-lg">DELETE APP</p>
        <p className="text-xl pt-8 pb-16">
          {`Are you sure to delete APP ${departmentName.toUpperCase()}`}?
        </p>
        <div className="flex justify-around items-center w-full h-12">
          <button
            type="submit"
            onClick={onDelete}
            className="text-lg font-semibold p-4 cursor-pointer">
            YES
          </button>
          <button
            type="submit"
            className="text-lg font-semibold p-4 text-red-600 cursor-pointer">
            CANCEL
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteModal;
