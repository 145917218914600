export function cutString(inputString: string, len: number) {
  try {
    if (inputString.length > len) {
      return `${inputString.slice(0, len)} ...`;
    } else {
      return inputString;
    }
  } catch (e) {
    return inputString;
  }
}
