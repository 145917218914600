import React from 'react';
import cn from 'classnames';
export interface ProgressProps {
  progressPercent: number;
  text?: string;
  forUser?: boolean;
}

const Progress: React.FC<ProgressProps> = ({
  progressPercent = 0,
  text = 'progress',
  forUser = false,
}) => {
  const handleColor = (per: number) => {
    if (per < 25) return 'red-500';
    if (25 < per && per < 60) return 'yellow-500';
    if (60 < per && per < 75) return 'blue-500';
    return 'green-500';
  };

  const handleColor_ = (per: number) => {
    if (per < 25) return 'rgb(239, 68, 68)';
    if (25 < per && per < 60) return 'rgb(245, 158, 11)';
    if (60 < per && per < 75) return 'rgb(59, 130, 246)';
    return 'rgb(16, 185, 129)';
  };
  // console.log(handleColor(10));
  return (
    <div className={`w-8/12 text-24 text-${handleColor(progressPercent)}`}>
      {text ||
        (forUser && (
          <div
            className={cn('mb-1', {
              'pl-8': !forUser,
              'text-21 text-gray-700': forUser,
            })}>
            {text}
          </div>
        ))}
      <div className={cn('flex', {'justify-center items-center': forUser})}>
        {text && !forUser && (
          <div className="mr-2 large:mr-3 xlarge:mr-6 w-8">
            {progressPercent}%
          </div>
        )}
        <div
          className={`border flex items-center h-4 border-${handleColor(
            progressPercent,
          )} rounded-full w-full`}>
          <div
            style={{
              width: `${progressPercent}%`,
              backgroundColor: handleColor_(progressPercent),
            }}
            className={` bg-${handleColor(progressPercent)} h-4 rounded-full`}
          />
        </div>
        {forUser && (
          <div className="ml-2 text-gray-700 text-21">
            {progressPercent.toFixed(0)}%
          </div>
        )}
      </div>
    </div>
  );
};

export default Progress;
