import React from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Controller} from 'react-hook-form';
import {useMutation, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as yup from 'yup';
import {useSelector} from 'react-redux';

import {cutString} from 'helpers/cutString';
import {Input} from 'components';
import Spinner from 'components/spinner';
import gate from 'gate';
import {selectDepartments} from 'store/selectors/companySelector';

export interface AddUserProps {
  departmentId: string;
  onAdd: () => void;
}

interface IFormInputs {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirm: string;
}
const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required().email(),
  password: yup
    .string()
    .required()
    .min(6, 'Password is too short - should be 6 chars minimum.'),

  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords are not equal')
    .required('Password confirm is required'),
});

const AddUser: React.FC<AddUserProps> = ({onAdd = () => {}}) => {
  const departments = useSelector(selectDepartments);
  const cache = useQueryClient();

  const {mutate: addUser, isLoading} = useMutation(
    async (data) => {
      try {
        const res = await gate.createCompanyUsers(data);
        onAdd(res?.data);
        toast.success('User Added Successfully');
        cache.invalidateQueries('getCompanyUsers');
      } catch (e) {
        console.log(e);
      }
    },
    {
      onSuccess: () => {
        // toast.success('User Added Successfully');
      },
    },
  );

  const {control, handleSubmit, register, errors, getValues} = useForm<
    IFormInputs
  >({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
    },
  });
  const onSubmit = (data: any) => {
    addUser({...data, department: parseInt(data?.department)});
  };

  return (
    <form
      className="w-full flex flex-col justify-center"
      onSubmit={handleSubmit(onSubmit)}>
      <Controller
        as={Input}
        name="firstName"
        title="First Name"
        control={control}
        labelClassName="text-21 pb-2 font-normal"
        inputClassName="border-gray-200 border  py-4 rounded-lg"
        error={errors.firstName?.message}
      />
      <Controller
        as={Input}
        name="lastName"
        title="Last Name"
        control={control}
        labelClassName="text-21 pb-2 font-normal"
        inputClassName="border-gray-200 border  py-4 rounded-lg"
        error={errors.lastName?.message}
      />
      <Controller
        as={Input}
        name="email"
        title="Email"
        control={control}
        labelClassName="text-21 pb-2 font-normal"
        inputClassName="border-gray-200 border  py-4 rounded-lg"
        error={errors.email?.message}
      />
      <Controller
        as={Input}
        name="password"
        type="password"
        title="Password"
        control={control}
        labelClassName="text-21 pb-2 font-normal"
        inputClassName="border-gray-200 border  py-4 rounded-lg"
        error={errors.password?.message}
      />
      <Controller
        as={Input}
        name="passwordConfirm"
        type="password"
        title="Re-type Password"
        control={control}
        labelClassName="text-21 pb-2 font-normal"
        inputClassName="border-gray-200 border  py-4 rounded-lg"
        error={errors.passwordConfirm?.message}
      />
      <div className="w-full my-3 text-21 text-left ">Access apps</div>

      <div className="grid-cols-3 grid">
        {departments.map((item, index) => (
          <div
            className="p-1 w-full items-center flex justify-start"
            key={`iii_${index}`}>
            <input
              name="department"
              ref={register}
              className="w-19 h-19 "
              type="radio"
              value={item.id}
            />
            <p className="flex-1 text-md ml-3  ">
              {`APP ${cutString(item.name, 12).toUpperCase()} `}
            </p>
          </div>
        ))}
      </div>

      <div className="w-full flex justify-center items-center">
        <button
          type="submit"
          // onClick={onSubmit}
          className="bg-blue-600 mt-5 flex justify-center items-center text-24 text-white rounded-full w-40 h-15 transition-all duration-300 hover:shadow-xl px-5 py-3">
          {isLoading && <Spinner className="w-6 h-6" />}
          ADD
        </button>
      </div>
    </form>
  );
};

export default AddUser;
