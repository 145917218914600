import React, {ReactNode, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';

import {SortableContainer, SortableElement} from 'react-sortable-hoc';

import {
  SectionItemImage,
  SectionItemText,
} from 'components/scrollableItem/DraggableWidgets';

import classes from './scrollable.module.css';
import replaceSlugFromLink from 'helpers/replaceSlugFromLink';
import urls from 'providers/routes-provider/routes/urls';
import {EditPencilSVG, CrossSVG} from 'assets/svg';
import BaseModal from 'components/Modal/BaseModal';
import {useMutation, useQueryClient} from 'react-query';
import gate from 'gate';
import Button from 'components/Button';

type SortableItemType = {
  value: {
    type: 'img' | 'text' | string;
    thumbnail: string;
    id: string | number;
    title: string;
    text: string;
  };
  deleteItem: Function;
  lastItemColor?: string;
  mainColor: string;
};

type handleOnDeleteType =
  | React.MouseEvent<HTMLButtonElement, MouseEvent>
  | React.MouseEvent<HTMLDivElement>;

// Main:
const BaseSectionItem = ({
  children,
  data,
  isLoading = false,
}: {
  children: ReactNode;
  isLoading: boolean;
  data: any;
}) => {
  return (
    <div
      className={`${classes['section-item-wrapper']} ${
        isLoading ? classes['ghost-item'] : ''
      }`}>
      {children}
    </div>
  );
};

const SectionItem = SortableElement(
  ({value, deleteItem, lastItemColor, mainColor}: SortableItemType) => {
    const [isGrabb, isGrabbSet] = useState(false);
    const router = useHistory();
    const handleOnGrabbStart = () => {
      isGrabbSet(true);
      return false;
    };

    const handleOnGrabbEnd = () => {
      isGrabbSet(false);
    };

    const handleOnDelete = () => {
      // e.preventDefault();
      // e.stopPropagation();
      deleteItem(value.id);

      return false;
    };
    const handleOnEdit = (data: any) => {
      // e.preventDefault();
      // e.stopPropagation();
      router.push(
        `/layout/edit/${(data.type as string).toLowerCase()}/${
          data.id
        }?categoryId=${data.categoryId}`,
      );
    };
    return (
      <div
        onMouseEnter={handleOnGrabbStart}
        onTouchStart={handleOnGrabbStart}
        onMouseUp={handleOnGrabbEnd}
        onMouseLeave={handleOnGrabbEnd}
        onTouchEnd={handleOnGrabbEnd}
        className={isGrabb ? classes['grabbing'] : ''}>
        {value?.thumbnail && value?.thumbnail !== '' ? (
          <SectionItemImage
            text={value?.text}
            src={value.thumbnail}
            alt={value?.title}
          />
        ) : (
          <SectionItemText
            text={value.text}
            type={value.type}
            lastItemColor={lastItemColor}
            mainColor={mainColor}
          />
        )}
        <p className="pt-2 text-sm font-bold">{value.title}</p>
        <button className={classes[`delete-icon`]} onClick={handleOnDelete}>
          <img src={CrossSVG} alt="delete widget" />
        </button>
        <input
          className={classes[`delete-icon`]}
          type="image"
          src={CrossSVG}
          alt="delete"
          onClick={handleOnDelete}
        />

        <input
          className={classes[`edit-icon`]}
          type="image"
          src={EditPencilSVG}
          alt="edit"
          onClick={(e) => handleOnEdit(value)}
        />
      </div>
    );
  },
);

// Replace any
const SectionList = (props: {
  items: any;
  deleteItem: Function;
  mainColor: string;
}) => {
  return (
    <div className={classes['section-list']}>
      {props.items.map((value: any, index: number) => {
        const finalUrl = replaceSlugFromLink(urls.ROUTE_LAYOUT_MODIFY_WIDGETS, {
          widgetName: value.type,
          action: 'edit',
          id: value.id as string,
        });

        const isLoading = value.isLoading ? true : false;

        return (
          <BaseSectionItem data={value} isLoading={isLoading} key={index}>
            <SectionItem
              key={`item-${value}`}
              deleteItem={props.deleteItem}
              value={value}
              index={index}
              lastItemColor={index % 2 === 0 ? 'black' : 'white'}
              mainColor={props.mainColor}
            />
            {/* <Link
              className="w-1/2 h-6 absolute"
              style={{bottom: '-5%'}}
              to={finalUrl}></Link> */}
          </BaseSectionItem>
        );
      })}
    </div>
  );
};

const Section = SortableContainer(
  (props: {
    items: any;
    title: string;
    handleEditCategory: Function;
    id: string | number;
    deleteItem: Function;
    addItem: Function;
    mainColor: string;
  }) => {
    const handleOnEdit = () => {
      props.handleEditCategory({id: props.id});
    };
    const cache = useQueryClient();
    const {mutate, isLoading} = useMutation(async (id: string | number) => {
      try {
        await gate.deleteCategory({id});
        cache.invalidateQueries('layout');
      } catch (err) {}
    });

    const handleOnDeleteItem = (itemId: number | string) => {
      props.deleteItem({id: props.id, itemId});
    };

    const onDelete = (id: string | number) => {
      mutate(id);
    };

    return (
      <>
        <div className={classes['section']}>
          <div className={classes['section__title']}>
            <div className="flex justify-items-center items-center cursor-pointer">
              <h3>{props.title}</h3>
              <img
                onClick={handleOnEdit}
                className="px-3"
                src={EditPencilSVG}
                alt="edit category name"
              />

              {/* {!props?.items?.length && (
                <Button
                  spinnerColor="red"
                  isLoading={isLoading}
                  onClick={() => onDelete(props.id)}
                  className="flex mx-2 text-red-500">
                  delete
                </Button>
              )} */}
            </div>
          </div>
          {props.items.length > 0 ? (
            <SectionList
              deleteItem={handleOnDeleteItem}
              items={props.items}
              mainColor={props.mainColor}
            />
          ) : (
            <p className="h-10 text-2xl font-bold">Create Your First Widget </p>
          )}
        </div>
      </>
    );
  },
);

export default Section;
