import React, {useEffect} from 'react';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useMutation, useQueryClient} from 'react-query';
import gate from 'gate';
import {Spinner} from 'components';
import {useSelector} from 'react-redux';
import {selectActiveDepartment} from 'store/selectors/companySelector';
import {toast} from 'react-toastify';
interface Props {
  question?: string;
  answer?: string;
  id?: number;
  onClose: VoidFunction;
}

export interface RequestCreateFaq {
  id?: number;
  answer?: string;
  question?: string;
  departmentId?: number;
}
const schema = yup.object().shape({
  question: yup.string().required('Field is required'),
  answer: yup.string().required('Field is required'),
});
const AddQuestionForm: React.FC<Props> = ({answer, onClose, id, question}) => {
  const {id: departmentId} = useSelector(selectActiveDepartment);
  const cache = useQueryClient();

  const {isLoading: updateLoading, mutate: updateFAQ} = useMutation(
    async (data: RequestCreateFaq) => {
      try {
        await gate.updateFAQ(data, id);
        toast.success('updated successfully');
        onClose();
      } catch (error) {
        toast.error(error.message);
      }
    },
    {
      onSuccess: () => cache.invalidateQueries('faq'),
    },
  );
  const {mutate: createFAQ, isLoading} = useMutation(
    async (data: RequestCreateFaq) => {
      try {
        await gate.createFAQ(data);
        toast.success('created successfully');
        onClose();
      } catch (error) {
        toast.error(error.message);
      }
    },
    {
      onSuccess: () => cache.invalidateQueries('faq'),
    },
  );
  const defaultValues: any = {
    question: question ?? '',
    answer: answer ?? '',
  };
  const {errors, handleSubmit, register} = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = (data: RequestCreateFaq) => {
    if (id) {
      return updateFAQ({...data, departmentId});
    }
    createFAQ({...data, departmentId});
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="animate-opacity">
      <div className="w-full flex justify-center items-center flex-col">
        <div className="w-4/6 my-10 flex flex-col  ">
          <div className="text-left py-3 text-lg">QUESTION</div>

          <textarea
            name="question"
            ref={register}
            className="w-full focus:outline-none focus:border-blue-600 h-16 rounded-lg p-2 border border-gray-300"></textarea>
        </div>
      </div>
      <div className="w-full flex justify-center items-center flex-col">
        <div className="w-4/6 my-10  flex flex-col  ">
          <div className="text-left py-3 text-lg">ANSWER</div>

          <textarea
            name="answer"
            ref={register}
            className="w-full focus:outline-none focus:border-blue-600 h-64 rounded-lg p-2 border border-gray-300"></textarea>

          <div className="flex  pt-10 justify-end w-full ">
            <button
              onClick={onClose}
              className="font-bold rounded-lg mr-4 text-lg text-black bg-white px-3 py-2">
              CANCEL
            </button>
            <button
              type="submit"
              className="font-bold text-lg rounded-xl px-5  bg-blue-650 text-white  py-2">
              {isLoading || updateLoading ? <Spinner /> : 'SAVE'}{' '}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddQuestionForm;
