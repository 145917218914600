import React, {useState} from 'react';
import {Controller, useForm, FieldError} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {Input, Spinner} from 'components';

import gate from 'gate';
import toasts from 'providers/toast-provider/toasts';

import {logoSVG} from 'assets/svg';

interface ForgetPassProps {}
interface forgetPassForm {
  email: string;
}

const ForgetPass: React.FC<ForgetPassProps> = () => {
  const [loading, setLoading] = useState(false);
  const [successSendEmail, setSuccessSendEmail] = useState({
    message: '',
    success: false,
  });

  const history = useHistory();

  const schema = yup.object().shape({
    email: yup
      .string()
      .required('* Email is required')
      .min(6, 'Email is 6 or more characters')
      .email('It is not a valid email address'),
  });

  const {handleSubmit, control, errors} = useForm<forgetPassForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: forgetPassForm) => {
    setLoading(true);
    const forgetPassData = {
      email: data.email,
    };

    try {
      const result = await gate.forgetPassword(forgetPassData);
      if (result?.status === 200 || result?.status === 201) {
        setSuccessSendEmail(result.data);
      }
    } catch (e) {
      toasts.forgetPassFailure();
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    history.push('/login');
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-primary py-12">
      <img
        src={logoSVG}
        alt="headstart"
        className="absolute left-0 top-0 w-64 mt-8 ml-16"
      />

      {successSendEmail.success ? (
        <>
          <p className="text-xl w-3/5 mt-12 mb-8 px-4">
            {successSendEmail.message}
          </p>
          <div className="flex flex-col justify-center items-center mt-8 h-16">
            <button
              onClick={handleGoBack}
              className="rounded-full bg-loginButton text-black text-xl px-18 py-3
                      shadow-loginButton transform focus:translate-y-1 focus:shadow-loginButtonPressed cursor-pointer
                      ">
              Go back
            </button>
          </div>
        </>
      ) : (
        <>
          <p className="text-xxl font-semibold text-center">
            Forgot your password?
          </p>
          <p className="text-xl w-3/5 mt-12 mb-8 pl-12">
            Enter your email address and click on 'request'. You will receive an
            e-mail within a few minutes with a link to set a new password.
          </p>

          <form
            className="w-100 xl:w-136"
            autoComplete="on"
            onSubmit={handleSubmit(onSubmit)}>
            <Controller
              as={Input}
              title="Email"
              name="email"
              type="email"
              autoComplete="email"
              autoFocus={true}
              control={control}
              error={((errors?.email as unknown) as FieldError)?.message}
              inputClassName="rounded-full h-24 text-lg px-8"
              labelClassName="text-xl mb-1"
            />
            <div className="flex flex-col justify-center items-center mt-8 h-16">
              <button
                type="submit"
                disabled={loading}
                className={`rounded-full bg-loginButton text-xl px-18 py-3
                      shadow-loginButton transform focus:translate-y-1 focus:shadow-loginButtonPressed 
                      ${loading ? 'cursor-not-allowed' : 'cursor-pointer'}
                      `}>
                Request
              </button>
            </div>
          </form>

          <div className="mt-6 h-8">
            {loading && <Spinner className="h-8 w-8" />}
          </div>
        </>
      )}
    </div>
  );
};

export default ForgetPass;
