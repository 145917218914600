import React, {useRef, useEffect, useState} from 'react';

import {Cropper, Spinner, Tooltips} from 'components';

import useUpload from 'hooks/useImage';

import {trashSVG, UploadSVG} from 'assets/svg';
import {getDownloadAddress} from 'utils';

const Loading = () => (
  <div className="w-32 h-20 flex justify-center items-center">
    <Spinner className="w-10 h-10" />
  </div>
);

interface FileUploaderProps {
  title: string;
  defaultValue?: string;
  acceptFilePattern?: string;
  setValue: Function;
  thumbnail_caption?: string;
  tooltip?: string;
  type?: 'img' | 'file';
  editMode: boolean;
  ref?: VoidFunction;
}

const renderTitle = (title: string) => {
  return <p className="text-md -mb-3 w-auto ">{title}</p>;
};

const renderTooltip = (tooltip: string) => {
  return (
    <div className="m-auto">
      <Tooltips text={tooltip} />
    </div>
  );
};

const FileUploader: React.FC<FileUploaderProps> = (props) => {
  const {
    title,
    editMode,
    setValue,
    defaultValue,
    ref,
    thumbnail_caption,
    type,
    tooltip,
    acceptFilePattern,
  } = props;
  const [{filename, src}, fileSrcSet] = useState({
    filename: '',
    src: defaultValue,
  });
  const [file, setFile] = useState<any>();
  useEffect(() => {
    fileSrcSet({src: defaultValue, filename: ''});
  }, [defaultValue]);

  const {loading, uploadFile, data} = useUpload();
  const inputRef = useRef(null);

  useEffect(() => {
    if (data.filename && data.url)
      fileSrcSet({filename: data.filename, src: data.url});
  }, [data]);

  useEffect(() => {
    setValue({src, filename: src});
  }, [src]);

  const removeFile = () => {
    ((inputRef?.current as never) as HTMLInputElement).value = '';
  };
  const handleDeleteImage = () => {
    setFile(null);
    fileSrcSet({filename: '', src: ''});
  };

  const handleOpenFileSelector = () => {
    if (editMode && !src) {
      // remove previous file (if exist)
      removeFile();
      // click on th input file to open file uploader
      ((inputRef?.current as never) as HTMLInputElement).click();
    }
  };

  const renderThumbnail = () => {
    if (type === 'img')
      if (src || filename)
        return (
          <img
            src={src ? getDownloadAddress(src) : getDownloadAddress(UploadSVG)}
            alt={title}
            height="100%"
            ref={ref}
            onClick={handleOpenFileSelector}
            className={'w-full h-full object-cover'}
          />
        );
      else
        return (
          <div
            onClick={handleOpenFileSelector}
            className="px-3 select-none flex cursor-pointer justify-center items-center flex-col overflow-hidden whitespace-no-wrap">
            <img
              className="pointer-events-none text-xs m-1 h-10"
              src={
                src ? getDownloadAddress(src) : getDownloadAddress(UploadSVG)
              }
              alt={title}
              height="100%"
              ref={ref}
            />
            <p className="uppercase text-xs">
              {thumbnail_caption ? thumbnail_caption : `Upload ${title}`}
            </p>
          </div>
        );
    if (type === 'file')
      return (
        <div
          onClick={handleOpenFileSelector}
          className="px-3 select-none flex cursor-pointer justify-center items-center flex-col overflow-hidden whitespace-no-wrap">
          {filename || src ? (
            <p className="uppercase whitespace-pre-wrap">{`Some ${title} Uploaded!`}</p>
          ) : (
            <>
              <img
                className="pointer-events-none m-1 h-10"
                alt={title}
                src={getDownloadAddress(UploadSVG)}
              />
              <p className="uppercase text-xs">
                {thumbnail_caption ? thumbnail_caption : `Upload ${title}`}
              </p>
            </>
          )}
        </div>
      );

    return (
      <div
        onClick={handleOpenFileSelector}
        className="px-3 select-none flex cursor-pointer justify-center items-center flex-col overflow-hidden whitespace-no-wrap">
        <img
          className="pointer-events-none m-1 h-10"
          alt={title}
          src={getDownloadAddress(UploadSVG)}
        />
        <p className="uppercase text-xs">
          {thumbnail_caption ? thumbnail_caption : `Upload ${title}`}
        </p>
      </div>
    );
  };

  return (
    <>
      <div className="flex justify-start py-3 items-center">
        {renderTitle(title)}
        {tooltip && renderTooltip(tooltip)}
      </div>
      <div className="flex justify-start h-24 transition duration-100 ease-in-out transform scale-100 hover:scale-105">
        <div className="w-32 border-2 border-gray-700 rounded-xl flex justify-center items-center h-full">
          {loading ? <Loading /> : renderThumbnail()}
          <input
            type="file"
            name={title}
            ref={inputRef}
            onChange={(e) => {
              if (type === 'file') {
                return uploadFile(e);
              }
              setFile(e as any);
            }}
            accept={acceptFilePattern || '*'}
            className="hidden"
          />
        </div>
        {(src || filename) && editMode && (
          <div className="p-2 cursor-pointer" onClick={handleDeleteImage}>
            <img
              src={getDownloadAddress(trashSVG)}
              alt="trash"
              className="w-3"
            />
          </div>
        )}
      </div>

      <Cropper
        file={file}
        setResponse={(res) => {
          fileSrcSet({filename: res.data.filename, src: res.data.url});
        }}
      />
    </>
  );
};
export default FileUploader;
