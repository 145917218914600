import React, {Children, FC, ReactNode, useState} from 'react';
interface TabsProps {
  children?: ReactNode;
  tabsNames: string[];
  onChange?: (index: number) => void;
  defaultIndex?: number;
  activeTabClassName?: string;
  tabClassName?: string;
  tabsClassName?: string;
}

const Tabs: FC<TabsProps> = ({
  children,
  tabsNames = [],
  activeTabClassName,
  defaultIndex = 0,
  onChange,
  tabClassName,
  tabsClassName,
}) => {
  const [openTab, setOpenTab] = useState<number>(defaultIndex);
  return (
    <div className=" w-full my-4 ">
      <div className={`flex  justify-around ${tabsClassName}`}>
        {tabsNames.map((tab, key) => {
          return (
            <div
              key={key}
              onClick={() => {
                setOpenTab(key);
                onChange && onChange(key);
              }}
              className={` ${tabClassName}  ${
                key === openTab && activeTabClassName
              } `}>
              {tab}
            </div>
          );
        })}
      </div>

      <div className="w-full pt-5">
        {Children.map(children, (Child, key) => {
          if (openTab === key) return <>{Child}</>;
        })}
      </div>
    </div>
  );
};
export default Tabs;
