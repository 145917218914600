import FAQPage from './faq';
import GeneralPage from './general';
import LayoutPage from './layout';
import WidgetsPage from './widgets';
import UsersPage from './users';
import Login from './login';
import ForgetPass from './login/forgetPass';
import NewPass from './login/newPass';
import User from './user';

const pages = {
  FAQPage,
  GeneralPage,
  LayoutPage,
  UsersPage,
  Login,
  User,
  ForgetPass,
  NewPass,
  WidgetsPage,
};

export default pages;
