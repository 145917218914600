import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useQueryClient} from 'react-query';

import TodoItem from './TodoItem';

export interface RequestDoneTodo {
  taskId: number;
  done: boolean;
}
interface TodoWidgetProps {
  data: any;
}

const TodoWidget: React.FC<TodoWidgetProps> = ({data}) => {
  return (
    <div className="full pt-4 min-h-screen flex flex-col justify-start items-center ">
      <div className="text-black  font-bold text-5xl">To-Do</div>

      <div className="w-full pt-20 px-6">
        {data?.map((todo, index) => {
          return (
            <TodoItem
              key={todo.id}
              task={todo.task}
              index={index}
              handleClick={() => {}}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TodoWidget;
