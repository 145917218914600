import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {getDownloadAddress} from 'utils';

interface SidebarItemProps {
  icon: string;
  title: string;
  link: string;
  style?: object;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  icon,
  title,
  link,
  style,
}) => {
  const location = useLocation();
  const isActive = location.pathname.includes(title);

  return (
    <li
      style={style}
      className={`py-4 pl-4 w-full absolute ${
        isActive &&
        'bg-white rounded-tl-4xl rounded-bl-4xl border-transparent sidebarShape'
      }`}>
      <Link to={link} className="flex items-center">
        <div className="flex justify-center w-12 h-8">
          <img src={getDownloadAddress(icon)} alt={`${title} Tab`} />
        </div>
        <span className="ml-4 text-md font-semibold">
          {title.toUpperCase()}
        </span>
      </Link>
    </li>
  );
};

export default SidebarItem;
