import React, {useReducer} from 'react';

import {Spinner} from 'components';
import {SidebarItem, SidebarDropdown} from './sidebar';

import {
  tuneSVG,
  bulletListSVG,
  infoSVG,
  userAccountSVG,
  logoSVG,
} from 'assets/svg';
import {logout} from 'store/reducers/user';
import {useDispatch} from 'react-redux';

interface MainLayoutProps {
  children: React.ReactNode;
  isLoading?: boolean;
}
const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  isLoading = false, // TODO change to true after implement in all pages
}) => {
  const dispatch = useDispatch();

  const handleOnLogoutClick = () => {
    if (window.confirm('Are you sure?')) {
      dispatch(logout());
    }
  };

  return (
    <div className="flex min-h-screen w-full overflow-x-hidden bg-primary pl-72">
      <nav className="fixed top-0 bottom-0 left-0 right-0 z-10 w-72">
        <div className="items-start shadow-sidebar rounded-5xl pl-8 m-5 mr-8 absolute top-0 bottom-0 right-0 left-0">
          <ul>
            <SidebarItem
              icon={tuneSVG}
              title="general"
              link="/general"
              style={{top: '7.5rem'}}
            />
            <SidebarItem
              icon={bulletListSVG}
              title="layout"
              link="/layout"
              style={{top: '12.5rem'}}
            />
            <SidebarItem
              icon={infoSVG}
              title="faq"
              link="/faq"
              style={{top: '17.5rem'}}
            />
            <SidebarItem
              icon={userAccountSVG}
              title="users"
              link="/users"
              style={{top: '22.5rem'}}
            />
          </ul>
          <div className="relative mr-8" style={{top: '28rem'}}>
            <SidebarDropdown />
          </div>
          <div className="flex flex-col items-center absolute bottom-0 mb-10 mr-8">
            <div
              onClick={handleOnLogoutClick}
              className="mb-8 cursor-pointer bg-gray-800 rounded-3xl text-white px-8 py-2">
              Logout
            </div>
            <img src={logoSVG} alt="Headstart Logo" />
          </div>
        </div>
      </nav>
      <main className="w-full overflow-x-hidden flex my-5 mr-6 bg-white rounded-5xl shadow-main h-auto p-6 xl:p-10 2xl:p-16">
        {isLoading ? (
          <div className="flex items-center justify-center h-screen w-full">
            <Spinner className="w-32 h-32" />
          </div>
        ) : (
          <div className="w-full scrollbar-none h-max-content overflow-y-scroll overflow-x-hidden font-light p-2">
            {children}
          </div>
        )}
      </main>
      <nav id="right-navbar" />
    </div>
  );
};

export default MainLayout;
