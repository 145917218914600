import React, {RefObject, useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';

import {MainLayout, InputSection, Spinner, Preview} from 'components';

import FileUploader from './file-uploader';

import {ArrowLeft} from 'assets/svg';

import gate from 'gate';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useSelector} from 'react-redux';
import {SelectedCategory} from 'store/selectors/companySelector';
import toasts from 'providers/toast-provider/toasts';
import urls from 'providers/routes-provider/routes/urls';
import {toast} from 'react-toastify';
import {taskType} from '../ToDo';
import {useQuery, useQueryClient} from 'react-query';
import {initialData} from '../Quiz';

type pagesType = 'THUMBNAILS' | 'CONTENT';
interface metaDataType {
  title: string;
  text: string;
  thumbnail: string;
}
export interface QuizWidgetType {
  question: string;
  type: QuizType;
  choices: [];
  id?: number;
  answer: string | boolean;
  description: string;
}
export interface EditorWidgetType {
  editableText: string;
  id?: number;
  viewableText: string;
}

export type QuizType = 'TrueAndFalse' | 'MultipleChoice';
export type widgetType = {
  Media: {filename: string; url: string};
  Pdf: {filename: string};
  Quiz: QuizWidgetType[];
  Gallery: {filename: string; description: string}[];
  Url: {url: string};
  ToDo: taskType[];
  Text: EditorWidgetType;
  Audio: {filename: string};
  // Feedback: {test3: string};
  // Text: {test4: string};
};

type widgetWrapperType<T extends keyof widgetType> = {
  children: React.ReactNode;
  widgetType: T;
  skipSaveOnContent?: boolean;
  page?: number;
  QuizFormRef?: RefObject<HTMLFormElement>;
  widgetData: widgetType[T];
  isSubmitDisable?: boolean;
  initialDataSet?:
    | React.Dispatch<React.SetStateAction<widgetType[T]>>
    | Function;
};

type paramsType = {
  widgetName: string;
  action: 'create' | 'edit';
  id?: string;
};

const translate = {
  title: 'Title',
  'title-tooltip': 'Title Name For Showing In Section',
  text: 'Text',
  'text-tooltip': 'Text (Alt) For Showing In Section',
  Content: 'Content',
  Thumbnails: 'Thumbnails',
  'fill the below':
    'Fill in the below fields. You can either use text, add a photo or pick an icon to display in your widget ',
};

function WidgetWrapper<T extends keyof widgetType>(
  props: widgetWrapperType<T>,
): React.ReactElement | null {
  const location = useLocation();
  const categoryId = new URLSearchParams(location.search).get('categoryId');

  const {widgetName, action, id} = useParams<paramsType>();
  const [isLoading, setIsLoading] = useState(false);
  const [page, pageSet] = React.useState<pagesType>('THUMBNAILS');
  const {data: _data, isLoading: widgetLoading} = useQuery(
    `widget/${id}`,
    async () => gate.viewWidget(id),
    {enabled: action === 'edit'},
  );
  console.log('🚀 ~ file: index.tsx ~ line 97 ~ _data', _data);
  const [metaData, metaDataSet] = React.useState<metaDataType>({
    title: '',
    thumbnail: '',
    text: '',
  });
  const history = useHistory();
  const cache = useQueryClient();
  const selectedCategory = useSelector(SelectedCategory);
  useEffect(() => {
    if (!selectedCategory && action !== 'edit') {
      return history.push('/layout');
    }
  }, []);
  const schema = yup.object().shape({
    title: yup.string().required('* Title is required'),
    // text: yup.string().required('* Text is required'),
    thumbnail: yup.string(),
    icon: yup.string(),
  });

  const {register, handleSubmit, getValues, errors, setValue, watch} = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });
  useEffect(() => {
    if (_data?.data) {
      const {text, title, thumbnail} = _data?.data ?? {};

      metaDataSet({
        text,
        title,
        thumbnail,
      });
      setValue('text', text);
      setValue('title', title);
    }
  }, [widgetLoading, _data]);
  const watchFields = watch(['title', 'text', 'icon', 'thumbnail']);
  const changePage = (page: pagesType) => {
    pageSet(page);
  };

  const handleBack = () => history.goBack();

  const pagerClass = (_page: pagesType) =>
    `w-auto transition duration-200 ease-in-out transform scale-95 m-0 cursor-pointer ${
      page === _page ? 'font-bold transform scale-100' : ''
    }`;

  const renderPageChanger = () => (
    <div className="w-64 my-5 flex justify-evenly items-center select-none bg-gray-300 rounded-3xl px-5 py-2">
      <p
        onClick={() => changePage('THUMBNAILS')}
        className={pagerClass('THUMBNAILS')}>
        {translate.Thumbnails}
      </p>
      <p
        onClick={() => changePage('CONTENT')}
        className={pagerClass('CONTENT')}>
        {translate.Content}
      </p>
    </div>
  );

  const renderTitle = () => (
    <div className="flex">
      <p className="font-bold text-5xl  capitalize">
        {widgetName === 'todo' ? 'to-do' : widgetName}
      </p>
      <p className="font-bold mx-3 text-5xl">Widget</p>
    </div>
  );

  const handleOnMetaPhotoChange = ({
    filename,
    url,
  }: {
    filename: string;
    url: string;
  }) => {
    if (filename || filename === '') {
      metaDataSet((lastMeta) => ({
        ...lastMeta,
        thumbnail: filename,
      }));
    }

    setValue('thumbnail', filename);
  };

  const postData = async ({modifyData}: any) => {
    setIsLoading(true);
    try {
      if (action === 'create') {
        await gate.createWidget({
          value: {...modifyData, categoryId: +selectedCategory},
          url: widgetName === 'todo' ? 'to-do' : widgetName,
        });
        toasts.createWidgetSuccessfull();
      } else {
        const oldContent = Array.isArray(_data?.data.content)
          ? _data?.data.content
          : undefined;
        const newContent = Array.isArray(modifyData.content)
          ? modifyData.content
          : undefined;
        await gate.updateWidget(
          {
            value: {...modifyData, categoryId: Number(categoryId)},
            url: widgetName === 'todo' ? 'to-do' : widgetName,
          },
          _data?.data?.id,
          {
            oldContent,
            newContent,
          },
        );
        cache.invalidateQueries('widget/' + _data?.data.id);
        toast.success('Successfully updated');
      }
      history.push(urls.ROUTE_LAYOUT);
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleOnValidData = () => {
    const type = props.widgetType;
    const singleFileContent =
      type === 'Audio' || type === 'Pdf' || type === 'Media';

    let content: any = props.widgetData;
    if (type === 'Gallery') {
      content = (props as any)?.widgetData?.map((data: any) => {
        return {
          ...data,
          description: data?.description,
          filename: data?.filename?.slice(54),
        };
      });
    }
    if (singleFileContent) {
      content = {
        url: (props as any)?.widgetData?.url,
        filename: (props as any)?.widgetData?.filename?.slice(54),
      };
    }

    const modifyData = {
      thumbnail: (metaData.thumbnail as string).includes('http')
        ? metaData?.thumbnail?.slice(54)
        : metaData?.thumbnail,
      ...getValues(),
      content,
    };

    if (props.widgetType === 'Quiz') {
      props.QuizFormRef?.current?.submit();
      if (
        (props as any).widgetData[(props as any).widgetData.length - 1] ===
        initialData
      ) {
        pageSet('CONTENT');
        return;
      }
    }
    postData({modifyData});
  };
  const handleOnInValidData = () => {
    pageSet('THUMBNAILS');
  };

  return (
    <>
      <MainLayout isLoading={widgetLoading}>
        <div className="relative h-full">
          <img
            className="cursor-pointer"
            onClick={handleBack}
            src={ArrowLeft}
            alt="Back"
          />
          <div className="pl-20 flex">
            <div className="flex-1">
              {renderTitle()}
              {renderPageChanger()}
              <div className={`${page === 'THUMBNAILS' ? '' : 'hidden'}`}>
                <p className="w-72">{translate['fill the below']}</p>
                <div className="mt-10 w-100">
                  <InputSection
                    title={translate.title}
                    tooltips={translate['title-tooltip']}
                    name="title"
                    editMode
                    error={errors?.title?.message}
                    watch={watchFields.title}
                    forwardRef={register}
                  />
                </div>
                <div className="flex">
                  {/* <div className="m-3">
                    <FileUploader
                      defaultValue={metaData?.icon.src}
                      title="ICON"
                      editMode
                      type="img"
                      acceptFilePattern="image/*"
                      tooltip="Photo"
                      setValue={handleOnMetaIconChange}
                    />
                  </div> */}
                  <div className="col-span-1 m-3 ml-0">
                    <FileUploader
                      defaultValue={metaData?.thumbnail}
                      title="Photo"
                      tooltip="Photo"
                      type="img"
                      acceptFilePattern="image/*"
                      setValue={handleOnMetaPhotoChange}
                      editMode
                    />
                  </div>
                </div>
                <div className="mt-10 w-100">
                  <InputSection
                    title={translate.text}
                    watch={watchFields.text}
                    tooltips={translate['text-tooltip']}
                    name="text"
                    error={errors?.text?.message}
                    forwardRef={register}
                    editMode={true}
                  />
                </div>
              </div>

              <div className={`${page === 'CONTENT' ? '' : 'hidden'}`}>
                {props.children}
              </div>

              {/* {(!props.skipSaveOnContent) ||
              (props.skipSaveOnContent && page === 'THUMBNAILS') && ( */}
              <button
                onClick={handleSubmit(handleOnValidData, handleOnInValidData)}
                className="bg-blue-650 mt-16 whitespace-no-wrap  py-3 px-5 my-5 cursor-pointer font-bold text-white w-min-content  rounded-lg">
                {isLoading ? <Spinner /> : 'Save Changes'}
              </button>
              {/* )} */}
            </div>
            <div className="flex-1">
              <Preview
                page={props.page}
                widgetType={props.widgetType}
                widgetData={props.widgetData}
              />
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
}
export default WidgetWrapper;
