import React from 'react';
import {convertToHTML} from 'draft-convert';
// draft-js-export-html
import createImagePlugin from 'draft-js-image-plugin';
import Editor, {composeDecorators} from '@draft-js-plugins/editor';
import createBlockBreakoutPlugin from 'draft-js-block-breakout-plugin';
import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  RichUtils,
  Modifier,
} from 'draft-js';
import {getSelectedBlocksMetadata} from 'draftjs-utils';

import Footer from './Footer';
import InlineStyleControls from './InlineStyleControls';

import createFocusPlugin from 'draft-js-focus-plugin';
// import createAlignmentPlugin from 'draft-js-alignment-plugin';
import createBlockDndPlugin from 'draft-js-drag-n-drop-plugin';
import createResizeablePlugin from 'draft-js-resizeable-plugin';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';

import useImage from 'hooks/useImage';

import 'draft-js-image-plugin/lib/plugin.css';
// import 'draft-js-alignment-plugin/lib/plugin.css';

import 'draft-js/dist/Draft.css';
import './editor-style.css';
import {getDownloadAddress} from 'utils';

const focusPlugin = createFocusPlugin();
const toolbarPlugin = createToolbarPlugin();
const blockDndPlugin = createBlockDndPlugin();
// const alignmentPlugin = createAlignmentPlugin();
const resizeablePlugin = createResizeablePlugin();
const blockBreakoutPlugin = createBlockBreakoutPlugin();

// const {AlignmentTool} = alignmentPlugin;

const rules = {
  urlInputContainer: {
    marginBottom: '10px',
  },

  urlInput: {
    marginRight: '10px',
    padding: '3px',
  },
  loading: {
    width: '100%',
  },
};

const imageDecorator = composeDecorators(
  resizeablePlugin.decorator,
  // alignmentPlugin.decorator,
  focusPlugin.decorator,
  blockDndPlugin.decorator,
);

const imagePlugin = createImagePlugin({decorator: imageDecorator});

const plugins = [
  focusPlugin,
  imagePlugin,
  toolbarPlugin,
  blockDndPlugin,
  // alignmentPlugin,
  resizeablePlugin,
  blockBreakoutPlugin,
  // dividerPlugin,
  // sideToolbarPlugin,
  // linkPlugin,
];

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const EditorComponent = ({widgetMeta, widgetMetaDispatch, page, id}) => {
  const editor = React.useRef(null);
  const inputRef = React.useRef(null);
  const [page_, setPage_] = React.useState(null);
  const [loaded, setLoaded] = React.useState(true);
  const [uploadedFile, setUploadedFile] = React.useState(page);
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty(),
  );

  const {loading: fileUploadLoading, uploadFile, data: image} = useImage();

  React.useEffect(() => {
    if (image.url && image.url !== uploadedFile) {
      _onChange(imagePlugin.addImage(editorState, image.url));

      setUploadedFile(image.url);

      inputRef.current.value = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  React.useEffect(() => {
    if (page !== page_) {
      _setEditorContent();
      if (widgetMeta?.[page]?.editableText?.length) {
        setPage_(page);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetMeta, page]);

  const _setEditorContent = (page__ = false) => {
    const meta = [...widgetMeta];

    if (page__ !== false) {
      meta.splice(page__, 1);
    }

    const p_ = meta?.[page]?.editableText || '';
    const value = p_ !== '' ? JSON.parse(p_) : false;

    if (value) {
      setEditorState(EditorState.createWithContent(convertFromRaw(value)));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  };

  const _focus = () => {
    editor?.current.focus();
  };

  const _onChange = (newState) => {
    const editableText = JSON.stringify(
      convertToRaw(newState.getCurrentContent()),
    );
    const viewableText = convertToHTML(exporterConfig)(
      newState.getCurrentContent(),
    );

    // console.log(8, newState, page, editableText, viewableText, id);

    // const inlineStyles = exporter(this.state.editorState);
    // const html = stateToHTML(this.state.editorState.getCurrentContent(), { inlineStyles });
    // const options = x => x.map(fontSize => {
    //   return <option key={fontSize} value={fontSize}>{fontSize}</option>;
    // });
    // https://github.com/webdeveloperpr/draft-js-custom-styles
    // console.log(page, editableText, viewableText);

    widgetMetaDispatch({
      type: 'onChangeText',
      payload: {page, editableText, viewableText, id},
    });

    // setEditorState(newState);
    setEditorState(
      EditorState.forceSelection(newState, newState.getSelection()),
    );
    // setEditorState(EditorState.moveFocusToEnd(newState));
  };

  let className = 'RichEditor-editor';
  const contentState = editorState.getCurrentContent();

  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder';
    }
  }

  const mediaBlockRenderer = (block) => {
    if (block.getType() === 'atomic') {
      return {
        component: Media,
        editable: false,
      };
    }
    return null;
  };

  const Media = (props) => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const {src} = entity.getData();

    //console.log(8, entity, entity.getData());

    // const type = entity.getType();

    // let media;
    // if (type === 'audio') {
    //   media = <Audio src={src} />;
    // } else if (type === 'image') {
    //   media = <Image src={src} />;
    // } else if (type === 'video') {
    //   media = <Video src={src} />;
    // }

    // return media;

    return <img src={getDownloadAddress(src)} style={rules.media} />;
  };

  const exporterConfig = {
    // styleToHTML: (style) => {
    //   console.log(2, style);

    //   if (style === 'BOLD') {
    //     return <span style={{color: 'blue'}} />;
    //   }
    // },
    blockToHTML: (block) => {
      const class_ = block?.data['text-align']
        ? {textAlign: block?.data['text-align']}
        : {};

      if (block.type === 'header-one') {
        return <h1 style={class_} />;
      } else if (block.type === 'header-two') {
        return <h2 style={class_} />;
      } else if (block.type === 'header-three') {
        return <h3 style={class_} />;
      } else if (block.type === 'unstyled') {
        return <p style={class_} />;
      }
    },
    entityToHTML: (entity, originalText) => {
      if (entity.type === 'IMAGE') {
        let style = null;
        let className = '';

        if (entity.data.alignment && entity.data.alignment !== 'default') {
          className = 'img_align_' + entity.data.alignment;
        }

        // if (entity.data.width) {
        // style = {width: '100%'};
        style = {
          width: (entity.data.width ? entity.data.width : 50) + '%',
          margin: 'auto',
        };
        // }

        // console.log(style);
        return (
          <img
            src={getDownloadAddress(entity.data.src)}
            alt={entity.data.alt}
            style={style}
            className={className}
          />
        );
      }

      return originalText;
    },
  };

  const toggleStyle = (value, type) => {
    if (type === 'inline') {
      _onChange(RichUtils.toggleInlineStyle(editorState, value));
    } else if (type === 'block') {
      _onChange(RichUtils.toggleBlockType(editorState, value));
    } else if (type === 'align') {
      const hasAlign = getSelectedBlocksMetadata(editorState).get('text-align');

      const alignValue = value.split('align-')[1] || 'left';
      const align = alignValue === hasAlign ? undefined : alignValue;

      const newContentState = Modifier.setBlockData(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        {'text-align': align},
      );

      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        'change-block-data',
      );

      _onChange(newEditorState);
    } else if (type === 'image') {
      if (inputRef?.current) {
        inputRef?.current?.click();
      }
    }
  };

  const _addPage = () => {
    widgetMetaDispatch({
      type: 'add',
    });

    setTimeout(() => {
      _setPage(widgetMeta.length);
    }, 100);
  };

  const _removePage = () => {
    if (window.confirm('Are you sure you want to remove this page?')) {
      widgetMetaDispatch({
        type: 'remove',
        payload: {index: page},
      });

      _setEditorContent(page);
    }
  };

  const _setPage = (index) => {
    widgetMetaDispatch({
      type: 'page',
      payload: {page: index},
    });
  };

  const handleKeyCommand = (command) => {
    let newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      _onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  function blockStyleFn(contentBlock) {
    const textAlign = contentBlock.getData().get('text-align');

    if (textAlign) {
      const className = `textAlign${capitalize(textAlign)}`;

      return className;
    }
  }

  if (!loaded) {
    return (
      <div style={rules.loading}>
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <div className="flex justify-center flex-col h-full">
      <div className="RichEditor-root" style={{height: '90%'}}>
        <InlineStyleControls editorState={editorState} onToggle={toggleStyle} />

        <div className={className} onClick={_focus}>
          <Editor
            ref={editor}
            plugins={plugins}
            onChange={_onChange}
            editorState={editorState}
            placeholder="here we go..."
            blockStyleFn={blockStyleFn}
            handleKeyCommand={handleKeyCommand}
            blockRendererFn={mediaBlockRenderer}
          />
          {/* <AlignmentTool /> */}

          <input
            type="file"
            name="image"
            ref={inputRef}
            accept="image/*"
            className="hidden"
            onChange={uploadFile}
          />
        </div>
      </div>

      <Footer
        page={page}
        addPage={_addPage}
        selectPage={_setPage}
        widgetMeta={widgetMeta}
        removePage={_removePage}
        loading={fileUploadLoading}
      />
    </div>
  );
};

export default EditorComponent;
