import React, {useState, useRef} from 'react';
import {Controller, useForm, FieldError} from 'react-hook-form';
import useQueryParam from 'hooks/useQueryParam';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {Input, Spinner} from 'components';

import gate from 'gate';
import toasts from 'providers/toast-provider/toasts';

import {logoSVG} from 'assets/svg';

interface NewPassProps {}
interface newPassForm {
  password: string;
  confirmPass: string;
}

const NewPass: React.FC<NewPassProps> = () => {
  const [loading, setLoading] = useState(false);

  const token = useRef(useQueryParam('token'));

  const schema = yup.object().shape({
    password: yup
      .string()
      .required('* Password is required')
      .min(6, 'Password is 6 or more characters'),
    confirmPass: yup
      .string()
      .required('* Confirm password is required')
      .min(6, 'Password is 6 or more characters'),
  });

  const {handleSubmit, control, errors, setError} = useForm<newPassForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      confirmPass: '',
    },
  });

  const onSubmit = async (data: newPassForm) => {
    // checking the new password and confirm password are the same
    if (data.password !== data.confirmPass) {
      setError('password', {
        type: 'manual',
        message: 'password and confirm password are not equal !',
      });
      setError('confirmPass', {
        type: 'manual',
        message: 'password and confirm password are not equal !',
      });
      return undefined; // for break the onSubmit function and ignore the below lines
    }

    setLoading(true);

    const newPassData = {
      token: token.current,
      password: data.password,
    };

    try {
      const result = await gate.newPassword(newPassData);
      if (result?.status === 200 || result?.status === 201) {
        setLoading(false);
        window.location.replace('/login'); // its prevent the user from "back" on the "newpass" (current) page again.
      }
    } catch (e) {
      setLoading(false);
      toasts.newpassFailure();
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-primary py-12">
      <img
        src={logoSVG}
        alt="headstart"
        className="absolute left-0 top-0 w-64 mt-8 ml-16"
      />

      <p className="text-xxl font-semibold text-center">Set new password</p>

      <form className="mt-16 w-100 xl:w-136" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={Input}
          title="New password"
          name="password"
          type="password"
          autoFocus={true}
          control={control}
          error={((errors?.password as unknown) as FieldError)?.message}
          inputClassName="rounded-full h-24 text-xxl px-8"
          labelClassName="text-xl mb-1"
        />
        <Controller
          as={Input}
          title="Confirm password"
          name="confirmPass"
          type="password"
          control={control}
          error={((errors?.confirmPass as unknown) as FieldError)?.message}
          inputClassName="rounded-full h-24 text-xxl px-8"
          labelClassName="text-xl mb-1 mt-4"
        />

        <div className="flex flex-col justify-center items-center mt-8 h-16">
          <button
            type="submit"
            disabled={loading}
            className={`rounded-full bg-loginButton text-xl px-18 py-3
                      shadow-loginButton transform focus:translate-y-1 focus:shadow-loginButtonPressed 
                      ${loading ? 'cursor-not-allowed' : 'cursor-pointer'}
                      `}>
            Confirm
          </button>
        </div>
      </form>

      <div className="mt-6 h-8">
        {loading && <Spinner className="h-8 w-8" />}
      </div>
    </div>
  );
};

export default NewPass;
