import React from 'react';
import {isEmpty} from 'lodash';

export interface RequestAnswer {
  questionId: number;
  answer: string;
}
export interface ResponseAnswer {
  description: string;
  success: boolean;
}
export interface QuizItemProps {
  data: any;
  setQuestion: (data?: number) => void;
}

const QuizItem: React.FC<QuizItemProps> = ({data, setQuestion}) => {
  const {question, choices} = data ?? {};

  return (
    <>
      <div className="text-black font-bold text-2xl w-full text-center py-12"></div>
      <div className="w-full h-64 mb-10 relative flex justify-center items-center">
        <div className="bg-white w-64 h-64 transform -rotate-6 top-0 shadow-xs rounded-lg   absolute z-10 " />
        <div className="bg-white h-64 w-64  p-5 absolute top-0 z-20 text-18  shadow-xs rounded-lg text-gray-450">
          {question}
        </div>
      </div>
      {/* {isEmpty(choices ?? {}) && (
        <div className=" flex pt-32 justify-around w-full px-16">
          <div
            onClick={() => setQuestion(-1)}
            className="flex justify-center transition-all duration-300  items-center w-90 h-90 rounded-full hover:opacity-50">
            Prev
          </div>
          <div
            onClick={() => setQuestion(1)}
            className="flex justify-center transition-all duration-300 items-center hover:opacity-50 w-90 h-90 rounded-full ">
            Next
          </div>
        </div>
      )} */}
      {Object.values(choices ?? {}).map((option: unknown, index: number) => (
        <div key={index} className="px-10  py-2 w-full ">
          <div
            onClick={() => setQuestion(1)}
            className="w-full flex justify-center items-center transition-all duration-300 hover:bg-white hover:text-black  py-3 bg-black text-white text-center rounded-md ">
            {option as string}
          </div>
        </div>
      ))}
    </>
  );
};

export default QuizItem;
