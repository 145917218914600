import Player from 'react-player';
import React from 'react';

export interface AudioWidgetProps {
  data: {
    file: string;
    url: string;
  };
}

const AudioWidget: React.FC<AudioWidgetProps> = ({data}) => {
  const url = data.url !== '' ? data.url : data.file;

  if (url && url !== '') {
    return (
      <div className="flex min-h-full justify-center items-center">
        <Player
          url={url}
          controls
          config={{
            youtube: {
              playerVars: {modestbranding: 1},
            },
          }}
        />
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center text-lg pt-10">
      Select the Media file
    </div>
  );
};

export default AudioWidget;
