import gate from 'gate';
import {taskType} from 'pages/widgets/ToDo';
import React, {FC, useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import TodoItem from './TodoItem';
interface Props {
  setData: Function;
  data: taskType[];
}
const Content: FC<Props> = ({setData, data}) => {
  const {id, action} = useParams<{id: string; action: string}>();
  const {data: _data, isLoading} = useQuery(
    `widget/${id}`,
    async () => gate.viewWidget(id),
    {
      enabled: action === 'edit',
    },
  );
  useEffect(() => {
    if (_data?.data) {
      setTasks(_data.data.content);
    }
  }, [isLoading, _data]);

  const [text, setText] = useState<string>('');
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);
  const [tasks, setTasks] = useState<taskType[]>(data);

  let key: number = 0;
  const onDelete = (text: string) => {
    const newTask = tasks.filter((task) => task.task !== text);
    setTasks(newTask);
  };
  const onChange = (text: string, index: number, id?: number) => {
    setText(text);
    const newTask = tasks;
    newTask[index] = {id, task: text};
    setTasks(newTask);
    setForceUpdate(!forceUpdate);
  };
  useEffect(() => {
    if (action === 'create') {
      setData(
        tasks.map((task) => {
          return {task: task.task};
        }),
      );
    } else {
      setData(tasks);
    }
  }, [forceUpdate, tasks, text]);

  return (
    <div className="w-2/3">
      <div className=" w-full py-4 ">
        Create a checklist for your employees. <br />
        Fill the below fields and add more if needed
      </div>
      {tasks.map(({task: text, id}, index: number) => {
        key++;
        return (
          <TodoItem
            onDelete={onDelete}
            key={key}
            id={id}
            onBlur={() => setForceUpdate(!forceUpdate)}
            index={index}
            onChange={onChange}
            text={text}
            edit={action === 'edit'}
          />
        );
      })}

      <button
        onClick={(e) => {
          e.preventDefault();
          setTasks((prev) => {
            return [...prev, {task: ''}];
          });
        }}
        className="w-full py-8 flex justify-start items-center">
        <div className="border text-4xl w-16 h-16 font-semibold justify-center flex items-center border-gray-800 rounded-full">
          +
        </div>
        <div className="text-lg p-2">ADD TASK</div>
      </button>
    </div>
  );
};

export default Content;
