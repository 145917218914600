import React from 'react';
import cn from 'classnames';

import {getContrastYIQ, getDownloadAddress} from 'utils';

import classes from './scrollable.module.css';

const SectionItemText = (props: {
  text: string;
  type?: string;
  lastItemColor?: string;
  mainColor: string;
}) => {
  const isBlack = props?.lastItemColor === 'black';

  return (
    <div
      className={cn(
        classes['section-item__text'],
        'flex justify-center items-center  font-bold p-3 ',
        props.text.length < 20 ? 'text-lg' : 'text-md',
      )}
      style={{
        backgroundColor: isBlack ? 'white' : props.mainColor,
        color: isBlack ? 'black' : getContrastYIQ(props.mainColor),
      }}>
      {props?.text?.length > 30
        ? props?.text?.slice(0, 30) + '...'
        : props?.text}
    </div>
  );
};
const SectionItemImage = (props: {src: string; alt?: string; text: string}) => {
  return (
    <div
      className={cn(classes['section-item__img'], 'relative overflow-hidden')}>
      <img
        width="100%"
        className="rounded-xl z-0  object-cover absolute inset-0"
        src={getDownloadAddress(props?.src)}
        alt={props?.alt || ''}
      />

      <div
        className={cn(
          classes['section-item__text'],
          'flex justify-center items-center  font-bold p-3 ',
          props.text.length < 20 ? 'text-lg' : 'text-md',
        )}
        style={{
          background: 'transparent',
        }}>
        {props?.text?.length > 30
          ? props?.text?.slice(0, 30) + '...'
          : props?.text}
      </div>
    </div>
  );
};
export {SectionItemImage, SectionItemText};
