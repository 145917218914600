import React from 'react';

import {infoSVG} from 'assets/svg';

interface TooltipsProps {
  text: string;
  isLeft?: boolean;
}

// TODO: replace css class name with inline tailwinds (remove tooltips.css)
const Tooltips: React.FC<TooltipsProps> = ({text, isLeft = false}) => {
  return (
    <div className="tooltip">
      <img src={infoSVG} alt="info" className="inline mx-2 w-3 h-4 cursor-help" />
      <span
        className={`tooltipText w-72 h-16 overflow-hidden text-base ${
          isLeft ? 'right-0 mr-10' : 'left-0 ml-16'
        }`}>
        {text}
      </span>
    </div>
  );
};

export default Tooltips;
