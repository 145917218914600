import React from 'react';

import SectionTitle from './sectionTitle';

const borderColor = (isEdit: boolean, hasError: string | undefined) => {
  let color = '';
  if (isEdit) {
    color = 'border-yellow-500 border-b-2';
  }
  if (hasError) {
    color = 'border-red-700 border-b-4';
  }
  return color;
};

interface InputSectionProps {
  title: string;
  tooltips?: string;
  name: string;
  forwardRef: VoidFunction;
  editMode: boolean;
  error?: string | undefined;
  watch?: string;
}

const InputSection: React.FC<InputSectionProps> = ({
  title,
  tooltips,
  name,
  forwardRef,
  editMode,
  error,
  watch,
}) => {
  return (
    <div>
      <SectionTitle title={title} tooltips={tooltips} />
      <div className="">
        <input
          type="text"
          name={name}
          ref={forwardRef}
          disabled={!editMode}
          size={watch?.length}
          className={`text-xl 2xl:text-xxl font-bold w-full -mt-2 mb-1 bg-transparent overflow-hidden ${borderColor(
            editMode,
            error,
          )}`}
        />
        <p className="text-sm text-left text-red-600 h-4 px-2 -mt-10">
          {error && error}
        </p>
      </div>
    </div>
  );
};

export default InputSection;
