import React from 'react';

import {Dropdown} from 'components';
import ColorCircle from './colorCircle';

import {paintPaletteSVG} from 'assets/svg';

interface ColorPaletteProps {
  visible: boolean;
  onClose: VoidFunction;
  colors: Array<{id: number; name: string}>;
  onSelectColor: Function;
}

const ColorPalette: React.FC<ColorPaletteProps> = ({
  visible,
  onClose,
  colors,
  onSelectColor,
}) => {
  return (
    <Dropdown
      visible={visible}
      onClose={onClose}
      className="rounded-5xl shadow-colorPalette bg-white flex items-center z-10 p-3 h-16 max-w-2/3 overflow-x-scroll">
      {colors.map((color) => (
        <ColorCircle
          key={color.id}
          color={color.name}
          action={() => onSelectColor(color.name)}
        />
      ))}
      <img
        src={paintPaletteSVG}
        alt="paint palette"
        className="w-12 h-10 ml-2 right-0"
      />
    </Dropdown>
  );
};

export default ColorPalette;
