import React, {useMemo} from 'react';

import {getContrastYIQ} from 'utils';

import {ReactComponent as InfoIcon} from 'assets/svg/infoIcon.svg';
import {ReactComponent as CustomerIcon} from 'assets/svg/customerIcon.svg';
import HomeIcon from './HomeIcon';

interface HomeWidgetFooter {
  mainColor: string;
  accentColor?: string;
}

const Footer: React.FC<HomeWidgetFooter> = (props) => {
  const accentColor = props.accentColor;
  const color = useMemo(() => {
    return getContrastYIQ(accentColor || '#000000') === 'black'
      ? 'black'
      : 'white';
  }, [accentColor]);

  return (
    <div
      style={{
        backgroundColor: props.mainColor,
        borderBottomRightRadius: 35,
        borderBottomLeftRadius: 35,
        width: 414,
      }}
      className="bottom-0 z-999 absolute h-24">
      <div className="w-full flex justify-around items-center h-full">
        <div className="flex justify-center ou items-center    ">
          <HomeIcon fill={accentColor} />
        </div>

        <div className="flex justify-center items-center  cursor-pointer ">
          <InfoIcon fill="white" stroke="white" className="w-6 h-6" />
        </div>

        <div className="flex justify-center items-center  cursor-pointer ">
          <CustomerIcon fill="white" stroke="white" className="w-6 h-6" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
