import React from 'react';

import {social, email} from 'assets/svg';
import {getDownloadAddress} from 'utils';

export interface ColleagueProps {
  item: COLLEAGUE_TYPE;
}
type COLLEAGUE_TYPE = {
  imageUrl: string;
  linkedin: string;
  email: string;
  role: string;
  firstName: string;
  lastName: string;
};

const ColleagueItem: React.FC<ColleagueProps> = ({item}) => {
  return (
    <div
      className="flex flex-col   border-gray-300 border  overflow-hidden "
      style={{width: 320, padding: 25, borderRadius: 8}}>
      <div className="flex mb-2 ">
        <img
          src={getDownloadAddress(item?.avatar)}
          style={{height: 85, width: 85}}
          className=" mr-4 rounded-full border-none bg-gray-400 "
          alt="..."
        />
        <div className="flex flex-col overflow-hidden">
          <p
            className="font-bold text-24 overflow-ellipsis"
            style={{marginBottom: 2}}>
            {item?.name}
          </p>
          <div className="font-bold text-24">{item.lastName}</div>
          <div className=" text-24 to-gray-500 opacity-50">{item.role}</div>
        </div>
      </div>

      <div className="flex text-20 " style={{marginBottom: 5}}>
        <img className="w-5 mr-5" src={getDownloadAddress(email)} alt="..." />
        <p className=" text-20">{item.email}</p>
      </div>
      <div className="flex ">
        <img className="w-5 mr-5" src={getDownloadAddress(social)} alt="..." />
        {item.linkedin}
      </div>
    </div>
  );
};

export default ColleagueItem;
