import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';

const findElement = () => document.getElementById('right-navbar');

const RightNavbar = ({children}: {children: React.ReactNode}) => {
  const [container, containerSet] = useState(findElement());

  useEffect(() => {
    containerSet(findElement());
  }, []);

  if (container) return ReactDOM.createPortal(children, container!);
  return null;
};

export default RightNavbar;
