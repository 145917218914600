import React, {useState} from 'react';
import {useMutation, useQueryClient} from 'react-query';
import {useForm, Controller, useFieldArray} from 'react-hook-form';
import * as yup from 'yup';
import {toast} from 'react-toastify';
import classNames from 'classnames';

import Spinner from 'components/spinner';
import {yupResolver} from '@hookform/resolvers/yup';

import {Cropper, Input} from 'components';
import {trashSVG, infoSVG} from 'assets/svg';
import gate from 'gate';
import FileUploader from 'pages/widgets/withWidget/file-uploader';
import useImage from 'hooks/useImage';
import {getDownloadAddress} from 'utils';
import {useSelector} from 'react-redux';
import {selectCompany} from 'store/selectors/companySelector';
//TODO refactor

const NOT_FOUND =
  'https://safetyaustraliagroup.com.au/wp-content/uploads/2019/05/image-not-found.png';
export interface AddUserProps {
  onDone: VoidFunction;
}

interface IFormInputs {
  name: string;
  email: string;
  linkedIn: string;
  role: string;
  avatar: string;
}
const schema = yup.object().shape({
  role: yup.string().required(),
  name: yup.string().required(),
  email: yup.string().required(),
  linkedIn: yup.string().required(),
  // avatar: yup.object().required(),
});

const ColleagueDropDown: React.FC<AddUserProps> = ({onDone}) => {
  const {
    control,
    handleSubmit,
    register,
    errors,
    setValue,
    getValues,
    reset,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      email: '',
      role: '',
      linkedIn: '',
      avatar: '',
    },
  });
  const [avatar, setAvatar] = useState<
    {url: string; filename: string} | undefined
  >();
  const {id} = useSelector(selectCompany);
  const {mutate: addUser, isLoading} = useMutation(
    async (data: any) => {
      try {
        // alert(id);
        return await gate.createCompanyUserColleague({
          ...data,
          companyId: String(id),
          avatar: avatar?.filename ?? '',
          linkedin: data.linkedIn,
        });
      } catch (e) {
        // alert(JSON.stringify(e, null, 2));
        console.log(e);
      }
    },
    {
      onSuccess: () => {
        toast.success('User Added Successfully');
        cache.invalidateQueries('getCompanyUserColleague');
        reset();
        onDone();
        setAvatar(undefined);
        setFile(undefined);
      },
    },
  );

  const [file, setFile] = useState<any>();
  const [loading, setLoading] = useState(false);
  const cache = useQueryClient();

  const onSubmit = async (data: any) => {
    setLoading(true);

    addUser(data);
  };
  return (
    <div className="w-full  text-md medium:text-md large:text-lg xlarge:text-xl font-bold">
      <form
        className="w-full flex flex-col justify-center"
        onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col justify-between">
          <div className="flex flex-col " style={{minWidth: 700}}>
            {
              <fieldset>
                <div className="flex">
                  <div
                    className=" flex  border  p-8 flex-grow border-opacity-30 "
                    style={{
                      borderRadius: 8,
                      marginBottom: 5,
                      borderColor: '#979797',
                    }}>
                    <label
                      className="relative flex items-center justify-center "
                      style={{height: 100, width: 100, minWidth: 100}}>
                      <img
                        ref={register}
                        src={
                          avatar?.url
                            ? getDownloadAddress(avatar.url)
                            : NOT_FOUND
                        }
                        alt="..."
                        style={{minHeight: 80, minWidth: 80}}
                        className="h-20   rounded-full bg-gray-400"
                      />

                      <input
                        type="file"
                        name="image"
                        className="hidden"
                        onChange={(e) => setFile(e)}
                      />
                    </label>
                    <div className="flex flex-col flex-grow">
                      <Controller
                        as={Input}
                        name={`name`}
                        title=" Name"
                        control={control}
                        error={errors?.name?.message}
                        labelClassName="text-15 pb-2 font-normal"
                        inputClassName="border-gray-200 border  py-4 rounded-lg"
                      />
                      {/* <div className="flex justify-between "></div> */}

                      <Controller
                        as={Input}
                        name={`email`}
                        title="Email"
                        control={control}
                        labelClassName="text-15 pb-2 font-normal"
                        inputClassName="border-gray-200 border  py-4 rounded-lg"
                        error={errors?.email?.message}
                      />
                      <Controller
                        as={Input}
                        name={`role`}
                        title="Role"
                        control={control}
                        labelClassName="text-15 pb-2 font-normal"
                        inputClassName="border-gray-200 border  py-4 rounded-lg"
                        error={errors?.email?.message}
                      />
                      <Controller
                        as={Input}
                        name={`linkedIn`}
                        title="LinkedIn"
                        control={control}
                        labelClassName="text-15 pb-2 font-normal"
                        inputClassName="border-gray-200 border  py-4 rounded-lg"
                        error={errors?.linkedIn?.message}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
            }
          </div>

          <div className="w-full flex justify-center items-center">
            <button
              type="submit"
              className=" mt-5 flex justify-center items-center text-24 text-white rounded-full w-40 h-15 transition-all duration-300 hover:shadow-xl px-5 py-3"
              style={{backgroundColor: '#50BE64'}}
              // onClick={handleChangeFeedback}
            >
              Save
              {isLoading && <Spinner className="w-6 h-6" />}
            </button>
          </div>
        </div>
      </form>
      <Cropper
        file={file}
        setResponse={(res) => {
          setAvatar(res.data);

          setValue('avatar', res.data.filename);
        }}
      />
    </div>
  );
};

export default ColleagueDropDown;
