import {useState} from 'react';

import gate from 'gate';
import toasts from 'providers/toast-provider/toasts';

interface imageData {
  url: string;
  filename: string;
}
// TODO: complete data type and e type (remove type any)
/**
 * useImage is a custom hook for upload images and manage it process.
 */
function useImage(onSuccess = () => {}) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<imageData>({url: '', filename: ''});
  const [error, setError] = useState('');

  async function uploadImage(data: any) {
    try {
      const result = await gate.uploadFile(data);
      if (result) {
        setData(result.data);
        onSuccess?.(result?.data);
        toasts.uploadImageSuccess();
      }
    } catch (e) {
      setError(e);
      toasts.uploadImageFailure();
    } finally {
      setLoading(false);
    }
  }

  function createFormData(file: any) {
    const formData = new FormData();
    formData.append('file', file);
    return formData;
  }

  async function uploadFile(e: any) {
    setLoading(true);
    const file = e.target.files[0];
    const formData = createFormData(file);
    await uploadImage(formData);
  }

  return {loading, data, error, uploadFile};
}

export default useImage;
