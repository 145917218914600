import {call, all, put, takeLatest} from 'redux-saga/effects';

import {login, logout} from 'store/reducers/user';
import token from 'helpers/token';

function* watchLogout() {
  yield token.clear();
  yield window.location.reload(true);
}

function* userSaga() {
  yield all([
    takeLatest(logout.type, watchLogout),
    // takeLatest(login.type, () => void),
  ]);
}

export default userSaga;
