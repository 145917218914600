import React, {CSSProperties} from 'react';

interface DropdownProps {
  visible: boolean;
  onClose: VoidFunction;
  style?: CSSProperties;
  className?: string;
  heightClassName?: string;
  children: React.ReactNode;
  offsetY?: string;
  full?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  visible,
  onClose,
  className,
  heightClassName, // we need height for display correct drop down animation
  offsetY = 0, // push down drop down from it parent
  children,
  style,
  full,
}) => {
  const handleClose = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    onClose && onClose();
  };

  return (
    <>
      {visible && (
        <div
          onClick={handleClose}
          className="fixed z-30 w-screen h-screen top-0 left-0 bg-transparent"
        />
      )}
      <div
        className={`relative ${full ? 'w-screen' : ''}`}
        style={style ?? {top: offsetY}}>
        <div
          className={`absolute ${className} transform transition-all duration-300 overflow-hidden z-30 ${
            visible
              ? `opacity-100 visible ${heightClassName}`
              : 'opacity-0 invisible h-0'
          }`}>
          {children}
        </div>
      </div>
    </>
  );
};

export default Dropdown;
