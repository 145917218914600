import gate from 'gate';
import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';

import WidgetWrapper from './withWidget';

const translate = {
  'URL Caption':
    'Enter the link you want to direct to here. When the widget is pressed by an user in the web app, they will be redirected to your link',
};

const UrlWidget = () => {
  const [widgetMeta, widgetMetaSet] = useState({url: ''});

  const {action, id} = useParams<{action: string; id: string}>();
  const {data: _data, isLoading} = useQuery(
    `widget/${id}`,
    async () => gate.viewWidget(id),
    {enabled: action === 'edit'},
  );
  useEffect(() => {
    if (_data?.data) {
      return widgetMetaSet(_data?.data?.content);
    }
  }, [isLoading]);
  const handleOnValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) widgetMetaSet({url: e.target.value});
  };

  return (
    <WidgetWrapper
      initialDataSet={widgetMetaSet}
      widgetData={widgetMeta}
      widgetType={'Url'}>
      <div className="w-full">
        <div>{translate['URL Caption']}</div>
        <div className="mt-4 w-min-content">
          <h2 className="text-2xl font-bold">External Link</h2>
          <input
            defaultValue={widgetMeta.url}
            className="border-b-2 text-2xl font-bold"
            onChange={handleOnValueChange}
          />
        </div>
      </div>
    </WidgetWrapper>
  );
};
export default UrlWidget;
