import gate from 'gate';
import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';

import WidgetWrapper from './withWidget';
import FileUploader from './withWidget/file-uploader';

const translate = {
  'Media URL': 'Media URL',
  'Media Caption':
    'Set the media url or upload a file here. When theis widget is pressed by an user in the web app, your media will instantly start to play',
};

const MediaWidget = () => {
  const {action, id} = useParams<{action: string; id: string}>();
  const [state, setState] = useState<{
    filename: string;
    url: string;
    id?: number;
  }>({
    filename: '',
    url: '',
  });
  const {data: _data, isLoading} = useQuery(
    `widget/${id}`,
    async () => gate.viewWidget(id),
    {enabled: action === 'edit'},
  );

  useEffect(() => {
    if (_data?.data) {
      let filename = '';

      if (
        ![
          'https://headstartatwork.s3.eu-central-1.amazonaws.com/',
          undefined,
          '',
        ].includes(_data?.data.content.filename)
      ) {
        filename = _data?.data.content.filename;
      }

      setState({
        filename: filename,
        id: _data?.data.content.id,
        url: _data?.data.content.url,
      });
    }
  }, [isLoading]);

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((lastWidget) => ({...lastWidget, url: e.target.value}));
  };

  const handleOnMediaChange = (e: {src: string; filename: string}) => {
    // if (e.filename)
    setState((lastWidget) => ({
      ...lastWidget,
      filename: e.filename || '',
      // url: e.src,
    }));
  };

  return (
    <WidgetWrapper
      initialDataSet={setState}
      widgetData={state}
      widgetType={'Media'}>
      <div className="w-full">
        <div>{translate['Media Caption']}</div>
      </div>
      <div className="w-2/12">
        <div className="mt-2 w-min-content">
          <div className="mt-5">
            <p className="uppercase text-sm font-bold">Media URL</p>
            <div className="border-b-2 mt-2 py-2 border-primary">
              <input
                className="text-2xl"
                defaultValue={state.url}
                onChange={handleUrlChange}
              />
            </div>
          </div>
          <FileUploader
            editMode
            defaultValue={state.filename}
            acceptFilePattern="video/*"
            type="file"
            setValue={handleOnMediaChange}
            title="Media file"
          />
        </div>
      </div>
    </WidgetWrapper>
  );
};

export default MediaWidget;
