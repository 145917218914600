import gate from 'gate';
import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';

import WidgetWrapper from './withWidget';
import FileUploader from './withWidget/file-uploader';

const translate = {
  'Image File': 'Image File',
  'Gallery Caption':
    'Upload multiple images here. When the widget is pressed by an user in the web app, they can sroll through the gallery of images',
  Description: 'Description',
  'Add Another Image': 'Add Another Image',
};

type GalleryWidgetType = {filename: string; description: string};

const GalleryWidget = () => {
  const [state, setState] = useState<GalleryWidgetType[]>([
    {filename: '', description: ''},
  ]);

  const handleAddSection = () => {
    setState((lastState) => [...lastState, {filename: '', description: ''}]);
  };

  const setStateAction = (e: {gallery: GalleryWidgetType[]}) => {
    setState(e.gallery);
  };
  const {action, id} = useParams<{action: string; id: string}>();
  const {data: _data, isLoading} = useQuery(
    `widget/${id}`,
    async () => gate.viewWidget(id),
    {enabled: action === 'edit'},
  );
  useEffect(() => {
    if (_data?.data) {
      return setState(_data?.data?.content);
    }
  }, [_data?.data, isLoading]);

  return (
    <WidgetWrapper
      widgetData={state}
      initialDataSet={setStateAction}
      widgetType={'Gallery'}>
      <div className="w-full">
        <div>{translate['Gallery Caption']}</div>
      </div>
      <div className="w-2/12">
        <div className="mt-2 w-min-content">
          <div className="mt-5">
            {state.map(({filename, description, ...rest}, i) => {
              console.log('rest:', rest);
              const handleOnMediaChange = (e: {
                url?: string;
                filename?: string;
              }) => {
                setState((lastWidget) => {
                  lastWidget[i].filename = e.filename as string;
                  return lastWidget;
                });
              };
              const handleDescriptionChange = (
                e: React.ChangeEvent<HTMLInputElement>,
              ) => {
                setState((lastWidget) => {
                  lastWidget[i].description = e.target.value as string;
                  return lastWidget;
                });
              };
              return (
                <div className="mb-10">
                  <FileUploader
                    key={i}
                    defaultValue={
                      filename !== ''
                        ? filename.includes('https')
                          ? filename
                          : 'https://headstartatwork.s3.eu-central-1.amazonaws.com/' +
                            filename
                        : ''
                    }
                    editMode
                    acceptFilePattern="image/*"
                    type="img"
                    setValue={(e: {filename?: string; src?: string}) => {
                      handleOnMediaChange({
                        filename: e.filename || '',
                        url: e.src || '',
                      });
                    }}
                    title="Media file"
                  />
                  <div className="mt-5">
                    <p className="uppercase text-sm font-bold">
                      {translate['Description']}
                    </p>
                    <div className="border-b-2 mt-2 py-2 border-primary">
                      <input
                        defaultValue={description}
                        className="text-2xl"
                        onChange={handleDescriptionChange}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            onClick={handleAddSection}
            className="flex scale-100 justify-start cursor-pointer items-center mt-10 transition duration-500 ease-in-out hover:scale-110">
            <div
              className={
                'm-0 mx-2 text-base rounded-full bg-white border-2 border-black w-10 h-10 flex justify-center items-center'
              }>
              +
            </div>
            <p>{translate['Add Another Image']}</p>
          </div>
        </div>
      </div>
    </WidgetWrapper>
  );
};

export default GalleryWidget;
