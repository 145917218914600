import {Spinner} from 'components';
import gate from 'gate';
import {CategoryType} from 'interfaces/api';
import React, {useEffect, useState} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import {useSelector} from 'react-redux';
import Switch from 'react-switch';
import {selectDepartmentsStatus} from 'store/selectors/companySelector';

import BaseModal from './BaseModal';

interface ModifyCategporyModalPropType {
  onClose: VoidFunction;
  active: boolean;
  id?: string | number | boolean;
}

type CategoryPropType = {
  name?: string;
  depend?: boolean;
  deadline?: number;
  departmentId?: number;
  startDate?: string;
  id?: number | string;
  order?: number;
};

const hanldePreventDefault = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e.preventDefault();
  e.stopPropagation();
};

const ModifyCategporyModal: React.FC<ModifyCategporyModalPropType> = ({
  onClose,
  active,
  id,
}) => {
  const isEdit = !!(active && id);
  const {isLoading, data, refetch} = useQuery(
    'category/' + id,
    () => gate.viewCategory({id}),
    {
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (id) refetch();
  }, []);
  return (
    <BaseModal active={active} onClose={onClose}>
      <div
        onClick={hanldePreventDefault}
        className="flex flex-col justify-center items-center text-center
          font-semibold overflow-hidden w-136 bg-white
          rounded-2xl shadow-modal border-1 pt-6 pb-6">
        {!isEdit ? (
          <ModifyCategoryComponent close={onClose} key="new" />
        ) : isLoading ? (
          <Spinner />
        ) : (
          <ModifyCategoryComponent key="edit" close={onClose} {...data?.data} />
        )}
      </div>
    </BaseModal>
  );
};

const ModifyCategoryComponent = ({
  accessible,
  name,
  id,
  close,
  deadline,
  departmentId,
}: CategoryType & {close: Function}) => {
  const cache = useQueryClient();
  const [category, setCategory] = useState({
    name,
    departmentId: departmentId && departmentId,
    deadline: deadline || 0,
    isAccecisbleAfterCMP:
      accessible && accessible === 'COMPLETION_PREVIOUS_PHASE',
    isAccecisbleOnFirst: accessible && accessible === 'FIRST_DAY', //props?.startDate == '0' ? true : false
    isAccecisble: accessible === 'ALWAYS',
    isActiveDaysbeforStart: deadline && deadline < 0 ? Math.abs(deadline) : 0,
    isActiveOnFirstDay: 0,
    isActiveDaysAfterStart: deadline && deadline > 0 ? deadline : 0,
    isActiveNoDueDate: 0,
  });
  const [
    {
      isActiveDaysAfterStart,
      isActiveDaysbeforStart,
      isActiveNoDueDate,
      isActiveOnFirstDay,
    },
    setRadios,
  ] = useState({
    isActiveDaysbeforStart: false,
    isActiveOnFirstDay: false,
    isActiveDaysAfterStart: false,
    isActiveNoDueDate: false,
  });
  const {activeDepartmentId} = useSelector(selectDepartmentsStatus);
  const handleChangeInput = (e: {name: string; value: string}) => {
    setCategory((lastCat) => ({...lastCat, [e.name]: e.value}));
  };

  const makeAllFalse = () => {
    setRadios({
      isActiveDaysAfterStart: false,
      isActiveDaysbeforStart: false,
      isActiveNoDueDate: false,
      isActiveOnFirstDay: false,
    });
  };

  const [loading, setLoading] = useState({delete: false, submit: false});

  const handleOnDeleteCategory = () => {
    setLoading({delete: true, submit: true});
    if (id)
      gate.deleteCategory({id}).finally(() => {
        close?.();
        setLoading({delete: false, submit: false});
      });
  };
  const hanldeOnSaveCategory = () => {
    setLoading({delete: true, submit: true});
    const value: CategoryType = {
      departmentId: activeDepartmentId,
      name: category.name,
      accessible: category.isAccecisble
        ? 'ALWAYS'
        : category.isAccecisbleAfterCMP
        ? 'COMPLETION_PREVIOUS_PHASE'
        : 'FIRST_DAY',
      deadline:
        isActiveNoDueDate || isActiveOnFirstDay
          ? 0
          : isActiveDaysbeforStart
          ? -Math.abs(Number(category.isActiveDaysbeforStart))
          : isActiveDaysAfterStart
          ? Number(category.isActiveDaysAfterStart)
          : 0,
    };

    if (id) {
      if (!loading.delete && !loading.submit) {
        gate
          .updateCategories({
            id,
            value,
          })
          .finally(() => {
            cache.invalidateQueries('category/' + id);
            close?.();
            setLoading({delete: false, submit: false});
          });
      }
    } else {
      if (!loading.delete && !loading.submit)
        gate
          .createCategory({
            value,
          })
          .finally(() => {
            close?.();
            setLoading({delete: false, submit: false});
          });
    }
  };

  useEffect(() => {
    setRadios({
      isActiveDaysAfterStart: !!category.isActiveDaysAfterStart,
      isActiveDaysbeforStart: !!category.isActiveDaysbeforStart,
      isActiveNoDueDate: deadline === 0,
      isActiveOnFirstDay: false,
    });
  }, []);
  return (
    <div className="w-full px-5 text-left">
      <p className="text-3xl font-bold">TITLE</p>
      <Input name="name" value={category?.name} onChange={handleChangeInput} />
      <div className="mt-12">
        <Checkable
          onChange={(e: boolean) => {
            setCategory((lastCat) => ({...lastCat, isAccecisble: e}));
          }}
          disable={
            category.isAccecisbleAfterCMP || category.isAccecisbleOnFirst
          }
          checked={category.isAccecisble}
          title="ALWAYS ACCESSIBLE"
        />
        <Checkable
          onChange={(e: boolean) => {
            setCategory((lastCat) => ({
              ...lastCat,
              isAccecisbleOnFirst: e,
            }));
          }}
          disable={category.isAccecisbleAfterCMP || category.isAccecisble}
          checked={category.isAccecisbleOnFirst}
          title="ACCESSIBLE ON FIRST DAY OF JOB"
        />
        <Checkable
          onChange={(e: boolean) => {
            setCategory((lastCat) => ({...lastCat, isAccecisbleAfterCMP: e}));
          }}
          checked={category.isAccecisbleAfterCMP}
          disable={category.isAccecisble || category.isAccecisbleOnFirst}
          title="ACCESSIBLE AFTER COMPLETION PREVIOUS PHASE"
        />
        <p className="text-2xl font-light">CONTENT COMPLETED BY:</p>
        <Range
          active={isActiveDaysbeforStart}
          hasNumber
          onActiveChange={(e: boolean) => {
            makeAllFalse();
            setRadios((lastRadios) => ({
              ...lastRadios,
              isActiveDaysbeforStart: e,
            }));
          }}
          onValueChange={(e: number) =>
            setCategory((lastCat) => ({...lastCat, isActiveDaysbeforStart: e}))
          }
          defaultValue={category.isActiveDaysbeforStart}
          name="DAYS BEFORE FIRST DAY"
        />
        <Range
          active={isActiveOnFirstDay}
          onActiveChange={(e: boolean) => {
            makeAllFalse();
            setRadios((lastRadios) => ({...lastRadios, isActiveOnFirstDay: e}));
          }}
          defaultValue={0}
          name="ON FIRST DAY"
        />
        <Range
          active={isActiveDaysAfterStart}
          hasNumber
          onActiveChange={(e: boolean) => {
            makeAllFalse();
            setRadios((lastRadios) => ({
              ...lastRadios,
              isActiveDaysAfterStart: e,
            }));
          }}
          onValueChange={(e: number) =>
            setCategory((lastCat) => ({...lastCat, isActiveDaysAfterStart: e}))
          }
          defaultValue={category.isActiveDaysAfterStart}
          name="DAYS AFTER FIRST DAY"
        />
        <Range
          active={isActiveNoDueDate}
          onActiveChange={(e: boolean) => {
            makeAllFalse();
            setRadios((lastRadios) => ({...lastRadios, isActiveNoDueDate: e}));
          }}
          defaultValue={0}
          name="NO DUE DATE"
        />
      </div>
      <div className="flex justify-center py-2 items-center">
        {loading.delete || loading.submit ? (
          <Spinner />
        ) : (
          <>
            {id && (
              <div
                onClick={handleOnDeleteCategory}
                className="w-auto cursor-pointer mx-2 bg-red-600 rounded-3xl text-white px-8 py-2">
                Delete Category
              </div>
            )}
            <div
              onClick={hanldeOnSaveCategory}
              className="w-auto cursor-pointer mx-2 bg-primary rounded-3xl text-white px-8 py-2">
              Save
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const Range = (props: {
  name: string;
  defaultValue: number | string;
  onActiveChange: Function;
  onValueChange?: Function;
  hasNumber?: boolean;
  active: boolean;
}) => {
  return (
    <div className="flex items-center my-4">
      <RadioInput active={props.active} onChange={props.onActiveChange} />
      {props.hasNumber && (
        <input
          className="mx-4 border-2"
          defaultValue={props.defaultValue}
          type="number"
          onChange={(e) => {
            props.onValueChange?.(e.target.value);
            props.onActiveChange(true);
          }}
          min={0}
          max={30}
        />
      )}
      <p className="text-2xl font-light">{props.name}</p>
    </div>
  );
};

const RadioInput = (props: {active: boolean; onChange: Function}) => {
  return (
    <div
      onClick={() => props.onChange(!props.active)}
      className="w-5 h-5 bg-white overflow-hidden mr-4 cursor-pointer flex justify-center items-center rounded-full border-2 border-gray-500">
      {props.active && <div className="w-5 h-5 bg-green-500"></div>}
    </div>
  );
};

const Checkable = (props: {
  checked?: boolean;
  title: string;
  disable?: boolean;
  onChange: (param: boolean) => void;
}) => {
  const handleOnChange = (e: boolean) => {
    props.onChange(e);
  };

  return (
    <div className="flex justify-between my-6 items-center">
      <p className="text-2xl font-light">{props.title}</p>
      <Switch
        onChange={handleOnChange}
        checkedIcon={false}
        uncheckedIcon={false}
        onColor="#50BE64"
        checked={!!props.checked}
        disabled={props.disable}
      />
    </div>
  );
};

const Input = (props: {value?: string; name: string; onChange: Function}) => {
  return (
    <div className="border-b-2 border-gray pt-4 pb-2">
      <input
        className="w-full text-2xl"
        value={props.value}
        maxLength={20}
        onChange={(e) =>
          props?.onChange({name: props.name, value: e.target.value})
        }
      />
    </div>
  );
};

export default ModifyCategporyModal;
