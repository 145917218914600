import React from 'react';
import {useDrag} from 'react-dnd';
import {ItemTypes} from './ItemTypes';

const Icon = ({name, children, onAdd, className, onClick}) => {
  const [{isDragging}, drag] = useDrag({
    item: {name, type: ItemTypes[name]},
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onAdd(item.name, dropResult.name);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.4 : 1;
  return (
    <div
      ref={drag}
      style={{opacity}}
      className={className}
      onClick={() => onClick(name)}>
      {children}
    </div>
  );
};

export default Icon;
