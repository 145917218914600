import React from 'react';
// import {ReactComponent as Tick} from 'assets/svg/tick.svg';

import {getContrastYIQ, getDownloadAddress} from 'utils';

export interface WidgetProps {
  tick?: boolean;
  title?: string;
  imgUrl?: string;
  className?: string;
  type?: string;
  text?: string | null;
  lastItemColor?: string;
  mainColor: string;
  backgroundColor: string;
}

const Widget: React.FC<WidgetProps> = ({
  tick,
  title,
  lastItemColor,
  imgUrl,
  type,
  text,
  mainColor,
  backgroundColor,
  className,
}) => {
  const isBlack = lastItemColor === 'black';

  return (
    <div
      className={`flex w-full  relative flex-col justify-center items-center ${className}`}>
      {/* {tick && (
        <span
          style={{ border: `2px solid ${backgroundColor}` }}
          className="w-7 h-7 absolute -top-10 -right-10 flex justify-center items-center  bg-black  rounded-full"
        >
          <Tick />
        </span>
      )} */}
      {imgUrl && imgUrl !== '' ? (
        <img
          src={getDownloadAddress(imgUrl)}
          alt="..."
          style={{width: 150, height: 150, minWidth: 150}}
          className="rounded-lg object-cover shadow-md"
        />
      ) : (
        <div
          style={{
            backgroundColor: isBlack ? 'white' : mainColor,
            width: 150,
            height: 150,
            minWidth: 150,
          }}
          className={`flex ${isBlack ? 'text-black' : 'text-white'} ${
            isBlack ? 'black' : getContrastYIQ(mainColor)
          } shadow-md rounded-md justify-center overflow-hidden  whitespace-normal   items-center text-24 font-bold`}>
          {text}
        </div>
      )}
      <div
        className="mt-2 w-full  text-14 text-left"
        style={{color: mainColor}}>
        {title}
      </div>
    </div>
  );
};

export default Widget;
