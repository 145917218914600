import React from 'react';

import classes from './text.module.css';

const TextWidget = ({data, page}) => {
  return (
    <>
      {data.map((_page, index) => {
        if (page === index)
          return (
            <div
              key={index}
              className={`${classes.editor_container} w-full pt-5`}>
              <div
                className="animate-opacity"
                dangerouslySetInnerHTML={{__html: _page.viewableText}}
              />
            </div>
          );
      })}
      <div className="w-full my-20 flex-col flex justify-center items-center">
        <div className="my-4 ">{`${page + 1} / ${data.length}`}</div>
      </div>
    </>
  );
};

export default TextWidget;
