import React from 'react';

interface SpinnerProps {
  className?: string;
}

const Spinner: React.FC<SpinnerProps> = ({className}) => {
  return (
    <div className="flex justify-center items-center">
      <span className={`spinner w-6 h-6 ${className}`} />
    </div>
  );
};

export default Spinner;
