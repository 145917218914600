import React, {useEffect, useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';

import {Dropdown, DeleteModal, Spinner, Tooltips} from 'components';
import DepartmentCard from './DepartmentCard';

import {
  selectDepartments,
  selectActiveDepartment,
  selectDepartmentsStatus,
} from 'store/selectors/companySelector';
import {
  createDepartment,
  deleteDepartment,
  cloneDepartment,
  setActiveDepartment,
} from 'store/reducers/company';
import {cutString} from 'helpers/cutString';
import {DepartmentType} from 'interfaces/departmentType';

import {smartphoneSVG, pathDownSVG} from 'assets/svg';

interface SidebarDropdownProps {}

interface flaggedDepartmentType {
  id: number;
  name: string;
}

const SidebarDropdown: React.FC<SidebarDropdownProps> = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const flaggedDepartment = useRef<flaggedDepartmentType>({id: NaN, name: ''}); // flagged department for delete or duplicate modal and actions

  const dispatch = useDispatch();

  const {register, handleSubmit, setValue} = useForm();

  const status = useSelector(selectDepartmentsStatus);
  const departments = useSelector(selectDepartments);

  useEffect(() => {
    let activeDepartmentId =
      window.localStorage.getItem('activeDepartmentId') || '';

    if (status.activeDepartmentId) {
      activeDepartmentId = status.activeDepartmentId;
    } else if (activeDepartmentId === '') {
      if (status.activeDepartmentId === 0 && departments?.length) {
        activeDepartmentId = departments[0].id;
      }
    }

    let dep = departments.find(
      (d: any) => d.id === parseInt(activeDepartmentId),
    );

    if (!dep && departments.length) {
      dep = departments[0];
    }

    if (dep) {
      window.localStorage.setItem('activeDepartmentId', dep.id);

      dispatch(setActiveDepartment(dep));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status.activeDepartmentId, departments]);

  // We need the active department's name to display it on the sidebar panel (before dropdown) :(
  const activeDepartment = useSelector(selectActiveDepartment);

  const handleShowDropDown = () => {
    setShowDropDown((prevState) => !prevState);
  };

  const handleSelectDepartment = (department: any) => {
    dispatch(setActiveDepartment(department));
    setShowDropDown(false);
  };

  const handleFlaggedDepartment = (id: number) => {
    const flagged = departments.filter(
      (department: DepartmentType) => department.id === id,
    )[0];

    flaggedDepartment.current.id = flagged.id;
    flaggedDepartment.current.name = flagged.name;

    setShowDeleteModal(true);
  };

  // TODO duplication API will change. Implement it later.
  const handleDuplicateDepartment = (department: DepartmentType) => {
    dispatch(cloneDepartment({id: department.id}));
  };

  const handleDeleteDepartment = () => {
    dispatch(
      deleteDepartment({
        id: flaggedDepartment.current.id,
      }),
    );
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };

  const handleCreateNewDepartment = (data: {appName: string}) => {
    const newDepartmentData = {
      name: data.appName,
      url: '',
      header: '',
      isPublished: false,
      language: '',
      logo: '',
      mainColor: '',
      accentColor: '',
      backgroundColor: '',
      companyId: departments[0].companyId,
    };

    dispatch(createDepartment({data: newDepartmentData}));
    setValue('appName', '');
  };

  return (
    <div>
      <div
        onClick={handleShowDropDown}
        className="flex justify-around items-center absolute w-full h-10 rounded-lg bg-white bg-opacity-25 cursor-pointer">
        <div>
          <img src={smartphoneSVG} alt="smartphone" className="h-8 p-1" />
        </div>
        {status.loading ? (
          <div className="w-20">
            <Spinner />
          </div>
        ) : (
          <p className="text-sm font-semibold text-center w-20 overflow-hidden whitespace-no-wrap">
            {cutString(activeDepartment.name, 6)}
          </p>
        )}
        <div className="">
          <img src={pathDownSVG} alt="-" className="w-3 h-2" />
        </div>
      </div>

      <Dropdown
        visible={showDropDown}
        onClose={handleShowDropDown}
        offsetY="3rem"
        heightClassName="h-88"
        className="overflow-y-scroll w-100 bg-white shadow-dropdown rounded-2xl pb-3">
        <div className="flex flex-row justify-between items-center border-b-2 px-6 mb-2">
          <p className="text-md font-semibold my-5">ALL YOUR APPS</p>
          <Tooltips isLeft={true} text="All your departments are here" />
        </div>

        {departments?.map((department: DepartmentType) => (
          <DepartmentCard
            key={department.id}
            department={department}
            onSelect={handleSelectDepartment}
            onDuplicate={handleDuplicateDepartment}
            onDelete={handleFlaggedDepartment}
          />
        ))}

        <form className="mx-6 px-3 my-3 border rounded-lg flex justify-between items-center h-16">
          <input
            type="text"
            name="appName"
            placeholder="App name"
            className="text-md"
            ref={register({required: true})}
          />
          <button
            type="submit"
            className="p-1 cursor-pointer text-md"
            onClick={handleSubmit(handleCreateNewDepartment)}>
            CREATE
          </button>
        </form>
      </Dropdown>

      <DeleteModal
        active={showDeleteModal}
        onClose={handleCloseModal}
        onDelete={handleDeleteDepartment}
        departmentName={flaggedDepartment.current.name}
      />
    </div>
  );
};

export default SidebarDropdown;
