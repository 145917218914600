import gate from 'gate';
import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';

import WidgetWrapper from './withWidget';
import FileUploader from './withWidget/file-uploader';

const translate = {
  'PDf Caption':
    'Upload your PDF file here. When this widget is pressed by an user in the web app, your PDF will instantly be viewed',
};

const PdfWidget = () => {
  const [widgetMeta, widgetMetaSet] = useState({url: '', filename: ''});
  const {action, id} = useParams<{action: string; id: string}>();
  const {data: _data, isLoading} = useQuery(
    `widget/${id}`,
    async () => gate.viewWidget(id),
    {enabled: action === 'edit'},
  );
  useEffect(() => {
    if (_data?.data) {
      let filename = '';

      if (
        ![
          'https://headstartatwork.s3.eu-central-1.amazonaws.com/',
          undefined,
          '',
        ].includes(_data?.data.content.filename)
      ) {
        filename = _data?.data.content.filename;
      }

      widgetMetaSet({
        filename,
        url: filename,
      });
    }
  }, [isLoading]);

  const handleOnValueChange = (e: {src?: string; filename?: string}) => {
    // if (e.filename && e.src) {
    widgetMetaSet({url: e.src || '', filename: e.filename || ''});
    // }
  };

  return (
    <WidgetWrapper
      initialDataSet={widgetMetaSet}
      widgetData={widgetMeta}
      widgetType={'Pdf'}>
      <div className="w-full">
        <div>{translate['PDf Caption']}</div>
      </div>
      <div className="w-2/12">
        <div className="mt-2 w-min-content">
          <FileUploader
            editMode
            defaultValue={widgetMeta.filename}
            acceptFilePattern="application/pdf"
            type="file"
            setValue={handleOnValueChange}
            title="Pdf file"
          />
        </div>
      </div>
    </WidgetWrapper>
  );
};
export default PdfWidget;
