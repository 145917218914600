import gate from 'gate';
import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';

import WidgetWrapper from './withWidget';
import FileUploader from './withWidget/file-uploader';

const translate = {
  'Voice Caption':
    'Upload your Voice file here. When theis widget is pressed by an user in the web app, your Voice will instantly be Heared',
};

const VoiceWidget = () => {
  const [widgetMeta, widgetMetaSet] = useState({url: '', filename: ''});
  const {action, id} = useParams<{action: string; id: string}>();
  const {data: _data, isLoading} = useQuery(
    `widget/${id}`,
    async () => gate.viewWidget(id),
    {enabled: action === 'edit'},
  );
  useEffect(() => {
    if (_data?.data) {
      return widgetMetaSet({
        url: _data?.data?.content.filename,
        ..._data?.data?.content,
      });
    }
  }, [isLoading]);
  const handleOnValueChange = (e: {src: string; filename?: string}) => {
    // if (e.filename) {
    widgetMetaSet({filename: e.filename || '', url: e.src || ''});
    // }
  };

  return (
    <WidgetWrapper
      initialDataSet={widgetMetaSet}
      widgetData={widgetMeta}
      widgetType={'Audio'}>
      <div className="w-full">
        <div>{translate['Voice Caption']}</div>
      </div>
      <div className="w-2/12">
        <div className="mt-2 w-min-content">
          <FileUploader
            editMode
            defaultValue={widgetMeta.filename}
            acceptFilePattern="media_type"
            type="file"
            setValue={handleOnValueChange}
            title="Voice file"
          />
        </div>
      </div>
    </WidgetWrapper>
  );
};
export default VoiceWidget;
