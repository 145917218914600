import React, {useState} from 'react';
import {Controller, useForm, FieldError} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {Link} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {Input, Spinner} from 'components';

import gate from 'gate';
import token from 'helpers/token';
import {login} from 'store/reducers/user';
import toasts from 'providers/toast-provider/toasts';

import {loginImagesSVG, logoSVG} from 'assets/svg';

interface LoginProps {}
interface loginForm {
  username: string;
  password: string;
}

const Login: React.FC<LoginProps> = () => {
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    username: yup
      .string()
      .required('* Username is required')
      .min(6, 'Username is 6 or more characters')
      .email('Username must be a email address'),
    password: yup
      .string()
      .required('* Password is required')
      .min(6, 'Password is 6 or more characters'),
  });

  const {handleSubmit, control, errors} = useForm<loginForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const onSubmit = async (data: loginForm) => {
    setLoading(true);
    const loginData = {
      email: data.username, // The server accepts the "email" term. but in this form, we have "username"
      password: data.password,
    };

    try {
      const result = await gate.login(loginData);
      if (result?.status === 201 || result?.status === 200) {
        token.set(result.data?.accessToken);
        dispatch(login(result.data));
        setLoading(false); // Because the route change after successful login, we shouldn't set "setLoading" in the "finally"
        history.push('/general');
      }
    } catch (e) {
      setLoading(false);
      toasts.loginFailure();
    }
  };

  return (
    <div className="flex h-screen bg-primary ">
      <div className="w-1/2 relative">
        <div className="flex-initial">
          <img src={logoSVG} alt="Headstart" className="mt-40 ml-16 w-1/2 2xl:w-1/3" />
        </div>
        <div className="absolute bottom-0 left-0">
          <img
            src={loginImagesSVG}
            alt="headstart"
            className=" w-3/4 object-contain"
          />
        </div>
      </div>

      <div className="flex-1 flex flex-col justify-end items-center">
        <form
          className="w-100 xl:w-136"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="on">
          <Controller
            as={Input}
            title="Username"
            name="username"
            autoComplete="username"
            autoFocus={true}
            control={control}
            error={((errors?.username as unknown) as FieldError)?.message}
            inputClassName="rounded-full h-24 text-lg px-8"
            labelClassName="text-xl mb-1"
          />
          <Controller
            as={Input}
            title="Password"
            name="password"
            type="password"
            autoComplete="current-password"
            control={control}
            error={((errors?.password as unknown) as FieldError)?.message}
            inputClassName="rounded-full h-24 text-xxl px-8"
            labelClassName="text-xl mb-1 mt-6"
          />

          <div className="flex justify-center items-center mt-8">
            <button
              type="submit"
              disabled={loading}
              className={`rounded-full bg-loginButton text-black text-xl px-18 py-3 
                      shadow-loginButton transform focus:translate-y-1 focus:shadow-loginButtonPressed 
                      ${loading ? 'cursor-not-allowed' : 'cursor-pointer'}
                      `}>
              Login
            </button>
          </div>
        </form>

        <div className="mt-6 h-4">
          {loading && <Spinner className="w-8 h-8" />}
        </div>
        <Link
          to={'/login/forgetpass'}
          className="mb-24 mt-6 text-lg xl:text-xl cursor-pointer">
          Forgot your password?
        </Link>
      </div>
    </div>
  );
};

export default Login;
