import {combineReducers} from 'redux';

import userReducer from './user';
import companyReducer from './company';
import quizReducer from './quizWidget';

const applicationReducer = combineReducers({
  user: userReducer,
  company: companyReducer,
  quizWidget: quizReducer,
});

const rootReducers = (state: any, action: any) => {
  return applicationReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducers>;

export default rootReducers;
