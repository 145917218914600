import React, {ReactElement, useRef} from 'react';
interface Props {
  renderItem: (item: any, index: number) => ReactElement;
  data: any[];
  lastItem?: ReactElement;
  className?: string;
}
const HorizontalList = ({data, lastItem, renderItem, className}: Props) => {
  const slider = useRef<any>(null);

  let id: number = 0;
  let isDown: boolean = false;
  let startX: any;
  let scrollLeft: any;

  return (
    <div
      ref={slider}
      onMouseDown={(e) => {
        isDown = true;
        startX = e.pageX - slider.current.offsetLeft;
        scrollLeft = slider.current.scrollLeft;
      }}
      onMouseLeave={() => {
        isDown = false;
      }}
      onMouseUp={() => {
        isDown = false;
      }}
      onMouseMove={(e) => {
        if (!isDown) return;
        e.preventDefault();

        const x = e.pageX - slider.current.offsetLeft;
        const walk = (x - startX) * 3;
        slider.current.scrollLeft = scrollLeft - walk;
      }}
      className={`flex overflow-x-scroll overflow-y-hidden scrolling-touch  scrollbar-none ${className} `}>
      {data?.map?.((item: any, index: number) => {
        id++;
        return <div key={id}>{renderItem(item, index)}</div>;
      })}
      <div>{lastItem}</div>
    </div>
  );
};

export default HorizontalList;
