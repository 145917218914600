import React from 'react';

import bitmap from 'assets/images/Bitmap.jpg';
import eye from 'assets/svg/eye.svg';
import {widgetType} from 'interfaces/api/user';
import {getDownloadAddress} from 'utils';
export interface Props extends widgetType {
  index: number;
}
const Widgets: React.FC<Props> = ({
  index,
  id,
  type,
  title,
  thumbnail,
  text,
}) => {
  return (
    <div
      className="flex flex-col justify-center  p-2"
      style={{height: 150, width: 120}}>
      {thumbnail ? (
        <img
          className="w-40 h-32 rounded-lg shadow-lg"
          src={getDownloadAddress(thumbnail)}
          alt="..."
        />
      ) : (
        <div
          className={` rounded-lg w-40 h-32 overflow-hidden  shadow-lg flex justify-center items-center font-bold ${
            index % 2 === 0 ? 'bg-white text-black' : 'bg-black text-white'
          }`}>
          {text}
        </div>
      )}
      <div className="p-1 font-bold text-xs">{title}</div>
      {/* <div className="flex p-1 items-center text-xs">
        <img className="cursor-pointer mr-2" src={eye} alt="..." />
        {viewCount} views
      </div> */}
    </div>
  );
};

export default Widgets;
