import * as yup from 'yup';
import React, {
  ChangeEvent,
  RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {FieldError, useForm} from 'react-hook-form';

import {Tabs} from 'components';

import {ReactComponent as Thumb} from 'assets/svg/thumb_.svg';
import {QuizWidgetType} from 'pages/widgets/withWidget';
import {useParams} from 'react-router-dom';

export interface QuizItemProps {
  data?: QuizWidgetType;
  onSubmit: (data: any) => any;
  onChangeQuizType: (type: string) => void;
  forwardRef: RefObject<HTMLFormElement>;
}

const schema = yup.object().shape({
  description: yup.string().required('* Text is required.'),
  question: yup.string().required('* Text is required.'),
});
const QuizItem: React.FC<QuizItemProps> = ({
  data,
  onSubmit,
  onChangeQuizType,
  forwardRef,
}) => {
  const {action, id} = useParams<{action: string; id: string}>();
  const [multipleChoice, setMultipleChoice] = useState<string[]>(
    data?.choices ?? [],
  );
  const _id = data?.id;
  const defaultValues: any = {
    question: data?.question ?? '',
    description: data?.description ?? '',
    answer: String(data?.answer) ?? '',
  };
  const [, setText] = useState<string>();
  const {handleSubmit, setValue, register, errors} = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onBlur',
  });

  useEffect(() => {
    if (data) {
      setValue('question', data?.question);
      setValue('description', data?.description);
      setValue('answer', String(data?.answer));
    }
  }, [data]);

  const _handleSubmit = handleSubmit((data) =>
    onSubmit({
      ...data,
      choices: multipleChoice,
      ...(action === 'edit' ? {id: _id} : {}),
    }),
  );
  let key = 0;
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>, index: number) => {
    setText(e.target.value);
    const newMultipleChoice = multipleChoice;
    multipleChoice[index] = e.target.value;
    setMultipleChoice(newMultipleChoice);
  };

  const submit: any = () => ({
    submit() {
      _handleSubmit();
    },
  });
  useImperativeHandle(forwardRef, submit);

  return (
    <form
      ref={forwardRef}
      id="hook-form"
      onSubmit={_handleSubmit}
      className="border rounded-t-xl  overflow-hidden pt-3 grid grid-cols-2">
      <div className="w-full p-3 flex justify-center flex-col">
        <div className="text-21 p-3">QUESTION </div>
        <textarea
          name="question"
          ref={register}
          className="w-full h-48 focus:outline-none  rounded-lg p-3 border  "
        />
        <p
          className={` text-center focus:outline-none  text-red-600 h-4 p-6 w-full }`}>
          {((errors.question as unknown) as FieldError)?.message}
        </p>

        <div className="text-21 p-3">DESCRIPTION</div>
        <textarea
          name="description"
          ref={register}
          className="w-full focus:outline-none h-48  rounded-lg p-3 pt-0 border  "
        />

        <p className={` text-center text-red-600 h-6 p-2 w-full }`}>
          {((errors.description as unknown) as FieldError)?.message}
        </p>
      </div>
      <div className=" ">
        <Tabs
          defaultIndex={multipleChoice.length ? 1 : 0}
          tabsClassName="w-full cursor-pointer text-21"
          tabClassName="mx-2 w-full"
          onChange={(index) => {
            if (index === 0) {
              onChangeQuizType('TrueAndFalse');
            } else {
              onChangeQuizType('MultipleChoice');
            }
          }}
          activeTabClassName="font-bold"
          tabsNames={['True/False', 'Multiple Choice']}>
          <div className="animate-opacity p-4 w-full  ">
            <label className="relative">
              <input
                type="radio"
                name="answer"
                ref={register}
                value="true"
                className="absolute w-16 opacity-0 -mb-10 z-50 h-16 top-0  "
              />
              <button className="p-3  my-2 rounded-full border border-gray-800 w-16 h-16   flex justify-center items-center">
                <Thumb className="w-6 h-6" />
              </button>
            </label>
            <label className="relative">
              <input
                ref={register}
                name="answer"
                type="radio"
                value="false"
                className="absolute opacity-0 w-16 z-10 h-16 -mb-4 top-0  "
              />
              <button className="p-3 transform rotate-180 my-2 rounded-full border border-gray-800 w-16 h-16   flex justify-center items-center">
                <Thumb className="w-6 h-6" />
              </button>
            </label>
          </div>
          <div className="animate-opacity  w-full p-3  h">
            {multipleChoice.map((answer, index) => {
              key++;
              return (
                <div key={key ?? index} className="relative">
                  <label htmlFor="answer" className="relative">
                    <input
                      id="answer"
                      ref={register}
                      name="answer"
                      type="radio"
                      value={answer}
                      className="absolute cursor-pointer  opacity-0 left-0 w-20 h-20  z-10  -mb-4 top-0  "
                    />
                    <div className="p-1 sss rounded-lg ">
                      <textarea
                        value={answer}
                        onChange={(e) => {
                          handleChange(e, index);
                        }}
                        className="w-full focus:outline-none p-3 pl-12 h-20 border rounded-lg"
                      />
                      <span className="absolute left-0 font-bold top-0 m-5">
                        {index + 1}
                      </span>
                    </div>
                  </label>
                </div>
              );
            })}
            <button
              onClick={(e) => {
                e.preventDefault();
                setMultipleChoice((prev) => {
                  return [...prev, ''];
                });
              }}
              className="w-10 m-4 transition-all duration-300 hover:bg-gray-200 h-10 bg-gray-400 rounded-full text-21 ">
              +
            </button>
          </div>
        </Tabs>
      </div>
    </form>
  );
};

export default QuizItem;
