import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {selectPage} from 'store/selectors/quizWidgetSelector';

import QuizItem from './QuizItem';

export interface QuizWidgetProps {
  data: any[];
}

const QuizWidget: React.FC<QuizWidgetProps> = ({data}) => {
  const [question, setQuestion] = useState<number>(0);

  const page = useSelector(selectPage);
  // useEffect(() => {
  //   if (data.length > question) {
  //     setQuestion(data.length - 1);
  //   }
  // }, [data]);

  return (
    <div className="full pb-32 pt-4 relative min-h-screen flex flex-col justify-start items-center ">
      <div className="font-bold">{page + 1 + '/' + data.length}</div>
      {data.map((_question: any, index: number) => {
        if (page === index)
          return (
            <QuizItem
              setQuestion={(data_ = 1) => {
                setQuestion((prev) => {
                  const prev_ = prev + data_;

                  if (prev_ >= data.length || prev_ < 1) {
                    return 0;
                  } else {
                    return prev_;
                  }
                });
              }}
              key={_question.id}
              data={_question}
            />
          );
      })}
    </div>
  );
};

export default QuizWidget;
