import axios from 'axios';
import token from 'helpers/token';
import {history} from 'providers/routes-provider';

import toasts from 'providers/toast-provider/toasts';
import {toast} from 'react-toastify';

const env = process.env;
const baseUrl = env.REACT_APP_BASE_URL;
/*************************************
 *------* Setup Axios Configs *------*
 *************************************/
const client = axios;
client.defaults.timeout = 100000;
client.defaults.baseURL = baseUrl;

/*************************************
 *------* Request Interceptor *------*
 *************************************/
client.interceptors.request.use(
  async (config) => {
    // *------* Set Headers *------*
    config.headers['Accept'] = 'application/json';
    config.headers['Content-Type'] = 'application/json';
    config.headers['Authorization'] = token.get()
      ? `Bearer ${token.get()}`
      : '';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

/**************************************
 *------* Response Interceptor *------*
 **************************************/
client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 400) {
      toast.error(error.response?.data?.detail?.response?.message?.[0]);
    } else if (error.response?.status === 500) {
      toast.error(error.response?.data?.detail?.message);
    } else if (error.response?.status === 401) {
      // if user login fails, we dont want to redirect him to the login page again
      if (!window.location.pathname.includes('login')) {
        toasts.unauthorized();
        token.clear(); // remove previous token if exist
        history.push('/login');
      }
    }
    return Promise.reject(error);
  },
);

export default client;
