import React from 'react';

import SidebarDropdown from './Typography';

import {
  EditorBoldSVG,
  EditorItalicSVG,
  EditorUnderlineSVG,
  EditorListSVG,
  EditorAlignLeftSVG,
  EditorAlignCenterSVG,
  EditorAlignRightSVG,
  EditorImageSVG,
} from 'assets/svg';
import {getDownloadAddress} from 'utils';

var INLINE_STYLES = [
  {
    label: 'Typography',
    type: 'typography',
    data: [
      {label: 'H1', title: 'Header', style: 'header-one', type: 'block'},
      {label: 'H2', title: 'Subheader', style: 'header-two', type: 'block'},
      {label: 'H3', title: 'Title', style: 'header-three', type: 'block'},
      {label: 'DIV', title: 'Default Text', style: 'unstyled', type: 'block'},
    ],
  },
  {
    label: 'Bold',
    image: EditorBoldSVG,
    style: 'BOLD',
    type: 'inline',
  },
  {
    label: 'Italic',
    image: EditorItalicSVG,
    style: 'ITALIC',
    type: 'inline',
  },
  {
    label: 'Underline',
    image: EditorUnderlineSVG,
    style: 'UNDERLINE',
    type: 'inline',
  },
  {
    label: 'List',
    image: EditorListSVG,
    style: 'unordered-list-item',
    type: 'block',
  },
  {
    label: 'Align Left',
    image: EditorAlignLeftSVG,
    type: 'align',
    style: 'align-left',
  },
  {
    label: 'Align Center',
    image: EditorAlignCenterSVG,
    style: 'align-center',
    type: 'align',
  },
  {
    label: 'Align Right',
    image: EditorAlignRightSVG,
    style: 'align-right',
    type: 'align',
  },
  {label: 'Image', image: EditorImageSVG, type: 'image'},
];

const TYPOGRAPHY = INLINE_STYLES.find((i) => i.type === 'typography').data;

const StyleButton = (props) => {
  let className = 'RichEditor-styleButton';
  const onToggle = (e) => {
    e.preventDefault();
    props.onToggle(props.style, props.type);
  };

  if (props.type === 'inline') {
    if (props.active) {
      className += ' RichEditor-activeButton';
    }

    return (
      <span className={className} onMouseDown={onToggle}>
        <img
          alt=""
          src={getDownloadAddress(props.image)}
          className={`h-10 p-1`} // ${props.active ? 'p-1' : 'p-0'}`}
        />
      </span>
    );
  } else if (props.type === 'typography') {
    const onToggleTypo = (style, type) => {
      props.onToggle(style, type);
    };

    return (
      <SidebarDropdown
        types={TYPOGRAPHY}
        onChoose={onToggleTypo}
        typography={props.type}
      />
    );
  } else {
    return (
      <span className={className} onMouseDown={onToggle}>
        <img src={getDownloadAddress(props.image)} alt="" className="h-8 p-1" />
      </span>
    );
  }
};

const InlineStyleControls = ({editorState, onToggle}) => {
  var currentStyle = editorState.getCurrentInlineStyle();

  // const selection = editorState.getSelection();
  // const blockType = editorState
  //   .getCurrentContent()
  //   .getBlockForKey(selection.getStartKey())
  //   .getType();

  return (
    <div className="RichEditor-controls flex items-center">
      {INLINE_STYLES.map((type) => {
        const active = currentStyle.has(type.style); //type.type === 'inline' ?  : ;
        return (
          <StyleButton
            active={active}
            key={type.label}
            type={type.type}
            image={type.image}
            style={type.style}
            onToggle={onToggle}
          />
        );
      })}
    </div>
  );
};

export default React.memo(InlineStyleControls);
