import {Spinner} from 'components';
import gate from 'gate';
import React, {useEffect} from 'react';
import {useQuery} from 'react-query';

import BaseModal from './BaseModal';

interface DeleteWidgetModalPropType {
  onClose: VoidFunction;
  active: boolean;
  id?: string | number | boolean;
  onDelete: Function;
}

const hanldePreventDefault = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e.preventDefault();
  e.stopPropagation();
};

const DeleteWidgetModal: React.FC<DeleteWidgetModalPropType> = ({
  onClose,
  active,
  id,
  onDelete,
}) => {
  const {isLoading, data, refetch} = useQuery(id, () => gate.viewWidget(id), {
    enabled: false,
    cacheTime: 2000,
  });

  useEffect(() => {
    if (active) refetch();
  }, [active]);

  const handleOnSubmit = () => {
    onDelete({itemId: id});
    onClose();
    return false;
  };

  return (
    <BaseModal active={active} onClose={onClose}>
      <div
        onClick={hanldePreventDefault}
        className="flex flex-col justify-center items-center text-center
          font-semibold overflow-hidden w-136 bg-white
          rounded-2xl shadow-modal border-1 pt-6 pb-6">
        <div>
          <div className="flex flex-row">
            <p>
              Are you sure that you want to delete{' '}
              <p className="text-red-600"> {data?.data.title} </p> widget ?
            </p>
          </div>
          <div className="flex justify-around items-center w-full mt-12">
            <button
              type="submit"
              onClick={handleOnSubmit}
              className="text-lg font-semibold p-4 text-red-600 cursor-pointer">
              OK
            </button>
            <button
              onClick={onClose}
              className="text-lg font-semibold p-4  cursor-pointer">
              CANCEL
            </button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteWidgetModal;
