import React from 'react';
import fillStar from 'assets/svg/fillStar.svg';
import star from 'assets/svg/Star.svg';
export interface FeedItemProps {
  score: number;
  title: string;
}

const FeedItem: React.FC<FeedItemProps> = ({
  score = 5,
  title = 'Hoe zou jij het sollicitatieproces beoordelen?',
}) => {
  return (
    <div className="flex p-3 flex-col border border-gray-300 rounded-md">
      <p className="font-medium mb-2">{title}</p>
      <div className="flex items-center">
        <img className="mx-1" alt="..." src={score >= 1 ? fillStar : star} />
        <img className="mx-1" alt="..." src={score >= 2 ? fillStar : star} />
        <img className="mx-1" alt="..." src={score >= 3 ? fillStar : star} />
        <img className="mx-1" alt="..." src={score >= 4 ? fillStar : star} />
        <img className="mx-1" alt="..." src={score >= 5 ? fillStar : star} />
      </div>
    </div>
  );
};

export default FeedItem;
