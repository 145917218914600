const urls = {
  ROUTE_LOGIN: '/login',
  ROUTE_LOGIN_FORGETPASS: '/login/forgetpass',
  ROUTE_LOGIN_NEWPASS: '/login/newpass',
  ROUTE_GENERAL: '/general',
  ROUTE_LAYOUT: '/layout',
  ROUTE_LAYOUT_MODIFY_WIDGETS: '/layout/:action/:widgetName/:id?',
  ROUTE_FAQ: '/faq',
  ROUTE_USERS: '/users',
  ROUTE_USES: '/user/:id',
};

export default urls;
