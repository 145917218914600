import React, {useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';

import {AddQuestionForm, FAQItem, MainLayout} from 'components';

import gate from 'gate';

import {RequestCreateFaq} from 'components/FAQ/form';
import {ReactComponent as DepartmentIcon} from 'assets/svg/smartphones.svg';
import {selectActiveDepartment} from 'store/selectors/companySelector';
export interface FAQPageProps {}

const FAQPage: React.FC<FAQPageProps> = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [faqData_, setFaqData_] = useState<any>([]);
  const [editData, setEditData] = useState<RequestCreateFaq>();
  const {name, id: departmentId} = useSelector(selectActiveDepartment);
  const {data, refetch, isLoading} = useQuery(
    'faq',
    () => gate.getFAQ(departmentId),
    {
      retry: false,
    },
  );
  const {data: faqData} = data ?? {};

  useEffect(() => {
    refetch();
  }, [departmentId]);

  useEffect(() => {
    const temp = faqData;

    if (temp) {
      temp.sort((a, b) => (a.id > b.id ? 1 : -1));

      setFaqData_(temp);
    }
  }, [faqData]);

  return (
    <MainLayout isLoading={isLoading}>
      <div className="w-full my-2 scrollbar-none flex justify-center">
        <div className="w-64 flex  justify-center items-center text-base font-bold rounded-xl text-center bg-gray-350 text-black uppercase py-2">
          <DepartmentIcon className="w-6 h-6 -ml-4 mr-4" />
          {name}
        </div>
      </div>

      <div className="w-full grid md:grid-cols-3 lg:grid-cols-3 ">
        <div className=" col-span-1 flex flex-col">
          <div className="text-lg w-full">
            Add frequent asked questions here including the answer so that your
            employees can easily find them
          </div>
          <div className="">
            <div className="my-5 w-full flex text-lg justify-start items-center">
              QUESTIONS
            </div>
          </div>
          {faqData_?.map(({id, answer, question}: any) => {
            return (
              <div key={id} className="p-2 py-4">
                <FAQItem
                  onShow={() => setVisible(true)}
                  setData={setEditData}
                  id={id}
                  answer={answer}
                  question={question}
                />
              </div>
            );
          })}
          <button
            onClick={() => {
              setVisible(!visible);
              setEditData({});
            }}
            className="w-full py-8 flex justify-start items-center">
            <div className="border text-4xl w-16 h-16 font-semibold justify-center flex items-center border-gray-800 rounded-full">
              +
            </div>
            <div className="text-lg p-2">ADD QUESTION</div>
          </button>
        </div>
        <div className=" md:col-span-2 lg:col-span-2 ">
          {visible && (
            <AddQuestionForm
              answer={editData?.answer}
              question={editData?.question}
              id={editData?.id}
              onClose={() => {
                setEditData({});
                setVisible(false);
              }}
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default FAQPage;
