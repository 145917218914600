import React, {useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import {useForm} from 'react-hook-form';
import {useSelector, useDispatch} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  MainLayout,
  SectionTitle,
  ImageSection,
  InputSection,
  UrlSection,
  DeleteModal,
  LanguageSection,
  Preview,
  Dropdown,
} from 'components';

import gate from 'gate';
import {
  selectCompany,
  selectActiveDepartment,
} from 'store/selectors/companySelector';
import {SketchPicker} from 'react-color';

import {
  resetData,
  getCompany,
  updateCompany,
  updateDepartment,
  deleteDepartment,
} from 'store/reducers/company';
import toasts from 'providers/toast-provider/toasts';
import {removeUnchangedValues} from 'helpers/removeUnchanged';

interface GeneralPageProps {}
interface InputsType {
  companyName: string;
  departmentName: string;
  botName: string;
  language: string;
}
type colorObj = {
  accentColor: string;
  mainColor: string;
  backgroundColor: string;
};
type dropDownObj = {
  accentColorVisible: boolean;
  mainColorVisible: boolean;
  backgroundColorVisible: boolean;
};
const GeneralPage: React.FC<GeneralPageProps> = () => {
  const [
    {accentColor, backgroundColor, mainColor},
    setColor,
  ] = useState<colorObj>({
    accentColor: 'yellow',
    mainColor: 'black',
    backgroundColor: 'yellow',
  });
  const [
    {mainColorVisible, backgroundColorVisible, accentColorVisible},
    setVisible,
  ] = useState<dropDownObj>({
    accentColorVisible: false,
    backgroundColorVisible: false,
    mainColorVisible: false,
  });

  const [editMode, setEditMode] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [botImage, setBotImage] = useState({url: '', filename: ''});
  const [headerImage, setHeaderImage] = useState({url: '', filename: ''});
  const [logoImage, setLogoImage] = useState({url: '', filename: ''});

  const {isLoading: categoriesIsLoading, data: categoriesData} = useQuery(
    'layout',
    gate.getAllCategories,
  );

  const schema = yup.object().shape({
    companyName: yup.string().required('* Company name is required'),
    departmentName: yup.string().required('* Department name is required'),
    botName: yup.string().required('* required'),
    // language: yup.strsng().required('* required'),
  });

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    reset,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const watchFields = watch([
    'companyName',
    'webAppLink',
    'departmentName',
    'botName',
  ]);

  const dispatch = useDispatch();

  const company = useSelector(selectCompany);

  useEffect(() => {
    setValue('companyName', company.name);
    setValue('webAppLink', `/${company.url}/register`);
    setValue('botName', company.botName);
    setLogoImage({filename: '', url: company.logo});
    setBotImage({filename: '', url: company.botAvatar});
  }, [company, setValue, setBotImage]);

  const department = useSelector(selectActiveDepartment);
  useEffect(() => {
    if (department) {
      dispatch(getCompany());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department.id]);
  useEffect(() => {
    setValue('departmentName', department.name);
    setValue('language', department.language);
    setHeaderImage({filename: '', url: department.header});
    setLogoImage({filename: '', url: department.logo});
    setColor({
      mainColor: department.mainColor,
      accentColor: department.accentColor,
      backgroundColor: department.backgroundColor,
    });
  }, [department, setValue, setHeaderImage, setLogoImage, setColor]);

  const handleEditMode = () => {
    // reset values after canceling
    if (!department.id) {
      toasts.emptyDepartment();
      return;
    }
    if (editMode) {
      reset(); // reset form for reset errors
      dispatch(resetData()); // tells selector to send us previous data.
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  };

  const handleDeleteModal = () => {
    setDeleteModal((prevState) => !prevState);
  };

  const handleDeleteDepartment = () => {
    dispatch(deleteDepartment({id: department.id}));
  };

  const handlePublishApp = (data: InputsType) => {
    const updatedCompanyData = {
      name: data.companyName,
      botName: data.botName,
      logo: logoImage.filename || logoImage.url,
      botAvatar: botImage.filename || botImage.url,
    };

    const updateDepartmentData = {
      header: headerImage.filename || headerImage.url,
      name: data.departmentName,
      language: data.language,
      mainColor: mainColor,
      accentColor: accentColor,
      backgroundColor: backgroundColor,
    };

    // remove unchanged values from data
    removeUnchangedValues(company, updatedCompanyData);
    removeUnchangedValues(department, updateDepartmentData);
    // if the data is empty(in case of "nothing change"), skipping from an update
    if (Object.keys(updatedCompanyData).length) {
      dispatch(updateCompany({id: company.id, data: updatedCompanyData}));
    }
    if (Object.keys(updateDepartmentData).length) {
      dispatch(
        updateDepartment({id: department.id, data: updateDepartmentData}),
      );
    }

    setEditMode(false);
  };

  // TODO remove hardcoded mock data
  const _LANGUAGES = [
    {id: 0, name: 'english'},
    {id: 1, name: 'dutch'},
    // {id: 2, name: 'arabic'},
    // {id: 3, name: 'persian'},
  ];

  const categoriesData_ = categoriesData?.data.filter(
    (c: any) => c.departmentId === department.id,
  );

  return (
    <MainLayout isLoading={company.loading}>
      <DeleteModal
        active={deleteModal}
        onClose={handleDeleteModal}
        onDelete={handleDeleteDepartment}
        departmentName={getValues('departmentName') || ''}
      />
      <div className="grid grid-cols-5">
        <div className="col-span-3 relative">
          <div
            className="absolute top-0 right-0 pr-3 text-md cursor-pointer z-10"
            style={{color: '#0070B0'}}
            onClick={handleEditMode}>
            {editMode ? 'CANCEL' : 'EDIT'}
          </div>

          <div className="grid grid-rows-3">
            <div>
              <InputSection
                title="COMPANY"
                tooltips="Your company name"
                name="companyName"
                forwardRef={register}
                editMode={editMode}
                error={errors?.companyName?.message}
                watch={watchFields.companyName}
              />
            </div>
            <div>
              <UrlSection
                title="WEBAPP LINK COMPANY"
                name="webAppLink"
                forwardRef={register}
                tooltips="webapp link"
                getValues={getValues}
                watch={watchFields.webAppLink}
              />
            </div>
            <div>
              <InputSection
                title="COMPANY DEPARTMENT"
                tooltips="current department(APP)"
                name="departmentName"
                forwardRef={register}
                editMode={editMode}
                error={errors?.departmentName?.message}
                watch={watchFields.departmentName}
              />
            </div>
          </div>

          <div className="grid grid-cols-4 gap-y-8 gap-x-8 mt-8">
            <div className="h-32 ">
              <ImageSection
                imageSrc={logoImage.url}
                title="LOGO"
                setImage={setLogoImage}
                editMode={editMode}
              />
            </div>
            <div className="col-span-1">
              <ImageSection
                imageSrc={headerImage.url}
                title="HEADER"
                setImage={setHeaderImage}
                editMode={editMode}
              />
            </div>
            <div className="col-span-2" />

            <div className="col-span-1">
              <ImageSection
                imageSrc={botImage.url}
                title="BOT AVATAR"
                isProfile={true}
                setImage={setBotImage}
                editMode={editMode}
              />
            </div>
            <div className="relative col-span-1">
              <InputSection
                title="BOT NAME"
                name="botName"
                forwardRef={register}
                editMode={editMode}
                error={errors?.botName?.message}
                watch={watchFields.botName}
              />
            </div>
            <div className="col-span-2">
              {/* <LanguageSection
                title="LANGUAGE"
                name="language"
                forwardRef={register}
                editMode={editMode}
                languages={_LANGUAGES}
                error={errors?.language?.message}
              /> */}
            </div>

            <div className="col-span-1">
              <SectionTitle title="MAIN COLOR" className="mb-2" />
              <button
                style={{background: mainColor}}
                disabled={!editMode}
                onClick={(e) => {
                  e.preventDefault();
                  setVisible((prev: dropDownObj) => {
                    return {...prev, mainColorVisible: true};
                  });
                }}
                className="border border-black rounded-full w-12 h-12 "></button>
              <Dropdown
                style={{right: 0}}
                className=" -mt-64"
                visible={mainColorVisible}
                onClose={() =>
                  setVisible((prev: dropDownObj) => {
                    return {...prev, mainColorVisible: false};
                  })
                }>
                <SketchPicker
                  color={mainColor}
                  onChangeComplete={({hex}) =>
                    setColor((prev: colorObj) => {
                      return {
                        ...prev,
                        mainColor: hex,
                      };
                    })
                  }
                />
              </Dropdown>
            </div>
            <div className="col-span-1">
              <SectionTitle title="ACCENT COLOR" className="mb-2" />
              <button
                disabled={!editMode}
                style={{background: accentColor}}
                onClick={(e) => {
                  e.preventDefault();
                  setVisible((prev: dropDownObj) => {
                    return {...prev, accentColorVisible: true};
                  });
                }}
                className="border border-black rounded-full w-12 h-12 "></button>
              <Dropdown
                style={{right: 0}}
                className=" -mt-64"
                visible={accentColorVisible}
                onClose={() =>
                  setVisible((prev: dropDownObj) => {
                    return {...prev, accentColorVisible: false};
                  })
                }>
                <SketchPicker
                  color={accentColor}
                  onChangeComplete={({hex}) =>
                    setColor((prev: colorObj) => {
                      return {
                        ...prev,
                        accentColor: hex,
                      };
                    })
                  }
                />
              </Dropdown>
            </div>
            <div className="col-span-2">
              <SectionTitle title="BACKGROUND COLOR" className="mb-2" />
              <button
                disabled={!editMode}
                style={{background: backgroundColor}}
                onClick={(e) => {
                  e.preventDefault();
                  setVisible((prev: dropDownObj) => {
                    return {...prev, backgroundColorVisible: true};
                  });
                }}
                className="border border-black rounded-full w-12 h-12 "></button>
              <Dropdown
                style={{right: 0}}
                className=" -mt-64"
                visible={backgroundColorVisible}
                onClose={() =>
                  setVisible((prev: dropDownObj) => {
                    return {...prev, backgroundColorVisible: false};
                  })
                }>
                <SketchPicker
                  color={backgroundColor}
                  onChangeComplete={({hex}) =>
                    setColor((prev: colorObj) => {
                      return {
                        ...prev,
                        backgroundColor: hex,
                      };
                    })
                  }
                />
              </Dropdown>
            </div>

            <div className="col-span-4">
              {editMode && (
                <button
                  onClick={handleSubmit(handlePublishApp)}
                  className="w-40 h-12 bg-submitButton rounded-xl text-white mr-3 text-sm font-bold z-40">
                  Publish
                </button>
              )}
              <button
                className="w-40 h-12 bg-white border border-submitButton rounded-xl text-submitButton text-sm font-bold"
                onClick={handleDeleteModal}>
                Delete App
              </button>
            </div>
          </div>
        </div>

        <Preview
          name={company.name}
          header={headerImage.url}
          mainColor={mainColor}
          accentColor={accentColor}
          categories={categoriesData_ || []}
          backgroundColor={backgroundColor}
          categoriesIsLoading={categoriesIsLoading}
        />
      </div>
    </MainLayout>
  );
};

export default GeneralPage;
