import * as React from 'react';

import {BaseModalProps} from 'interfaces/ModalTypes';

const BaseModal: React.FC<BaseModalProps> = ({active, onClose, children}) => {
  const handleOnClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    onClose && onClose();
    return false;
  };

  return (
    <div
      className={`fixed z-50 top-0 left-0 w-full h-full bg-transparent flex justify-center items-center ${
        active ? 'block' : 'hidden'
      }`}
      onClick={handleOnClick}>
      <div className="absolute animate-modal">{children}</div>
    </div>
  );
};

export default BaseModal;
