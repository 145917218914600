import React from 'react';

import {Tooltips} from 'components';

interface SectionTitleProps {
  title: string;
  tooltips?: string;
  className?: string;
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  tooltips,
  className,
}) => {
  return (
    <div className={`flex items-start ${className}`}>
      <p className="inline text-md">{title}</p>
      {tooltips && <Tooltips text={tooltips} />}
    </div>
  );
};

export default SectionTitle;
