import React, {useState} from 'react';
import {ReactComponent as TrashIcon} from 'assets/svg/trash-filled.svg';
import gate from 'gate';
import BaseModal from 'components/Modal/BaseModal';
import {useMutation, useQueryClient} from 'react-query';
import {Spinner} from 'components';
import {toast} from 'react-toastify';

export interface FAQItemProps {
  answer?: string;
  question?: string;
  id?: number;
  setData: Function;
  onShow: VoidFunction;
}

const FAQItem: React.FC<FAQItemProps> = ({
  answer,
  onShow,
  setData,
  question,
  id,
}) => {
  const cache = useQueryClient();

  const [visible, setVisible] = useState(false);
  const {mutate: onDelete, isLoading} = useMutation(
    async () => {
      try {
        await gate.deleteFAQ(id);
      } catch (error) {
        toast.error(error?.message);
      }
    },
    {
      onSuccess: () => {
        cache.invalidateQueries('faq');
      },
    },
  );

  return (
    <>
      <div className="flex justify-between items-center">
        <div
          onClick={() => {
            onShow();
            setData({answer, question, id});
          }}
          className="cursor-pointer text-sm">
          {question}{' '}
        </div>

        <button onClick={() => setVisible(true)} className="ml-5">
          {isLoading ? <Spinner /> : <TrashIcon />}
        </button>
      </div>
      <BaseModal active={visible} onClose={() => setVisible(false)}>
        <div
          className="flex flex-col justify-center items-center text-center
        font-semibold overflow-hidden w-136 bg-white
        rounded-2xl shadow-modal border-1 pt-12 pb-6 px-16">
          <p className="text-xl pt-8 pb-16">Are you sure</p>
          <div className="flex justify-around items-center w-full h-12">
            <button
              type="submit"
              onClick={() => onDelete()}
              className="text-lg font-semibold p-4 cursor-pointer">
              YES
            </button>
            <button
              onClick={() => setVisible(false)}
              className="text-lg font-semibold p-4 text-red-600 cursor-pointer">
              CANCEL
            </button>
          </div>
        </div>
      </BaseModal>
    </>
  );
};

export default FAQItem;
