import {Slider} from 'components';
import React, {useState, useEffect} from 'react';
import {getDownloadAddress} from 'utils';

export interface galleryItem {
  description: string;
  filename: string;
  priority: number;
  url: string;
}

export interface ResponseGalleryWidget {
  id: number;
  gallery: galleryItem[];
  thumbnail: string;
  title: string;
  type: string;
}

export interface GalleryWidgetProps {
  data: ResponseGalleryWidget;
}

const GalleryWidget: React.FC<GalleryWidgetProps> = ({data}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [data]);

  return (
    <div className="w-full flex flex-col pt-32 items-center  min-h-screen">
      {loading ? (
        <div>loading...</div>
      ) : (
        <Slider
          renderItem={(item, index) => (
            <div className=" p-3 relative  w-full flex flex-col justify-center items-center ">
              <img
                className="h-64 w-full object-cover  rounded-2xl "
                src={getDownloadAddress(item.filename)}
                alt={item.filename}
              />
              <div className=" text-center h-64 mt-16 z-20 w-full  ">
                {item.description}
              </div>
            </div>
          )}
          data={data}
        />
      )}
    </div>
  );
};

export default GalleryWidget;
